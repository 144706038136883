import { GET_MENU_REQUEST,GET_MENU_FAILURE,GET_MENU_SUCCESS } from "../types";

export const getMenuRequest = () => {
    return {
        type: GET_MENU_REQUEST
    };
};

export const getMenuFailure = (error: any) => {
    return {
        type: GET_MENU_FAILURE,
        error
    };
};

export const getMenuResponse = (response: any) => {
    return {
        type: GET_MENU_SUCCESS,
        response
    };
};

