import { call, put, takeEvery } from "redux-saga/effects";
import { post } from "../../api";
import { AD_REQUEST, AD_SUCCESS, AD_FAILURE } from "../types";

async function getAdDataApi(data = {}) {
  const { platform_id = 1 } = data;
  try {
    let response = null;
    response = await post(`/advertisement?platform_id=${platform_id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* getAdData(action) {
  try {
    const data = yield call(getAdDataApi, action.data);
    yield put({
      type: AD_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({ type: AD_FAILURE, message: e.message });
  }
}

export function* adSaga() {
  yield takeEvery(AD_REQUEST, getAdData);
}

export default adSaga;
