import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../api";
import { GET_MENU_REQUEST, GET_MENU_FAILURE, GET_MENU_SUCCESS } from "../types";

async function getMenuApi() {
  try {
    const response = await get("/left-sidebar");
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchMenuApi(menu) {
  try {
    const response = yield call(getMenuApi);
    yield put({ type: GET_MENU_SUCCESS, response });
  } catch (e) {
    yield put({ type: GET_MENU_FAILURE, message: e.message });
  }
}

export function* menuApiSaga() {
  yield takeEvery(GET_MENU_REQUEST, fetchMenuApi);
}

export default menuApiSaga;
