import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import { IRootState } from '../../redux/reducer';
import { resetSaveSearchRequestMessage } from '../../redux/actions/searchAction';
import { Link } from 'react-router-dom';
import { CONTENT_DETAIL } from '../../routes/routesConstant';
const TextView = (props: any) => {
    const { data, setSavePopup, count, getPaginationData } = props;
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const dispatch = useDispatch()

    return (
        <div className="searchresultsml_main">
            <section className="searchresultsml-section">
                <div className="container-fluid">
                    <div className="searchresult">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-7">
                                <div className="selectedrslts">
                                    <strong>Search Results</strong>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5">
                                <div className="seavfilter">
                                    <button type="button" className="btn common-btn" onClick={() => {
                                        if (!loginData?.token) {
                                            props.setLoginAlertBool(true);
                                            return;
                                        } dispatch(resetSaveSearchRequestMessage())
                                        setSavePopup(true)
                                    }}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="srchresultdtls_section">
                        <div className="srchresultdtls_grid">
                            <InfiniteScroll
                                style={{ 'height': 'inherit', overflow: 'hidden' }}
                                dataLength={data?.length}
                                next={() => {
                                    if (data?.length < count) {
                                        getPaginationData();
                                    }
                                }}
                                hasMore={true}
                                loader={<></>}
                            >
                                {data && data.length > 0 ? data.map((item: any, index: number) => {
                                    return (
                                        <div className="srchresultdtls_group media" key={item.video_id}>
                                            <div className="media-left">
                                                <Link to={`${CONTENT_DETAIL}?video_id=${item.video_id}&video_type=${item.video_type}`} className="srchresultdtls_play"> <i className="fa fa-play" aria-hidden="true"></i></Link>
                                            </div>
                                            <div className="media-body">
                                                <p>
                                                    <span><Link to={`${CONTENT_DETAIL}?video_id=${item.video_id}&video_type=${item.video_type}`}>  {item.video_title}</Link></span>
                                                    - {item.short_description}
                                                </p>
                                            </div>
                                        </div>)
                                }) : null}
                            </InfiniteScroll>
                        </div>
                    </section>
                </div>
            </section >
        </div >

    )
}

export default TextView;