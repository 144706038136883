import { SEARCH_LIST_REQUEST, SEARCH_LIST_SUCCESS, SEARCH_LIST_FAILURE, SEARCH_QUERY_SAVE_SUCCESS, SEARCH_QUERY_SAVE_FAILURE, FETCH_SEARCH_LIST_SUCCESS, SEARCH_QUERY_SAVE_EMPTY, DELETE_LIST_ITEM_SUCCESS, FETCH_LIST_ITEM_SUCCESS, SEARCH_LIST_EMPTY } from "../types";

interface IinitialState {
  search_result: any,
  error: any,
  count: any
}
const initialState: IinitialState = {
  search_result: [],
  error: null,
  count: 0
}

export default function searchReducer(state = initialState, action: any) {
  switch (action.type) {
    case SEARCH_LIST_REQUEST:
      return {
        ...state
      }
    case SEARCH_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case SEARCH_LIST_SUCCESS:
      return {
        ...state,
        search_result: action.searchData?.showText === "1" ? action.searchData?.data : (state.search_result?.length > 0 ? [...state.search_result, ...action.searchData?.data] : action.searchData?.data),
        count: action.searchData?.count
      }
    case SEARCH_LIST_EMPTY:

      return {
        ...state,
        search_result: [],
        count: 0
      }
    case FETCH_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        search_query_data: action.searchData
      }
    case SEARCH_QUERY_SAVE_SUCCESS:
      return {
        ...state,
        saveSucess: action.message
      }
    case SEARCH_QUERY_SAVE_FAILURE:
      return {
        ...state,
        saveFailure: action.message
      }
    case SEARCH_QUERY_SAVE_EMPTY:
      return {
        ...state,
        saveSucess: '',
        saveFailure: '',
        deleteSuccess: ''
      }
    case DELETE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.message
      }
    case FETCH_LIST_ITEM_SUCCESS:
      return {
        ...state,
        searchItemList: action.searchData
      }
    default:
      return state
  }
}