import React from "react";

import HomeLoader from "./HomeLoader";
import DetailLoader from "./DetailLoader";
import LandscapeLoader from "./LandscapeLoader";
import PortrateLoader from "./PortrateLoader";
const Loader = (props) => {
  const { type } = props;
  const getLoaderType = (type) => {
    switch (type) {
      case "home":
        return <HomeLoader />;
      case "details":
        return <DetailLoader />;
      case "landscape":
        return <LandscapeLoader />;
      case "portrate":
        return <PortrateLoader />;
      default:
        return <HomeLoader />;
    }
  };
  return getLoaderType(type);
};

export default Loader;
