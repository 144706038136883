import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Stripe from "stripe";
import { post } from "../../api";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { SIGN_IN } from "../../routes/routesConstant";

const PlanView = (props) => {
  const navigate = useNavigate();
  const [stripeSK, setStripeSK] = useState("");
  const [stripePK, setStripePK] = useState("");
  const { web_base_url, cypher_token } = useSelector(
    (state) => state.initialApiReducer
  );

  useEffect(() => {
    if (cypher_token) {
      let token = atob(cypher_token);
      token = token.split("-");
      setStripeSK(token[0]);
      setStripePK(token[1]);
    }
  }, [cypher_token]);

  const stripe = new Stripe(stripeSK);
  const { planData } = useSelector((state) => state.profileReducer);
  const { loginData } = useSelector((state) => state.authReducer);
  const initiateTransaction = async () => {
    if (!loginData?.user_id) {
      navigate(`${SIGN_IN}`);
      return;
    }
    const obj = {
      user_id: loginData?.user_id || "",
      plan_id: planData?.[0]?.id || "",
      platform: 1 || "",
      pay_via: "STRIPE",
    };
    const response = await post(
      `/initiate-payment?user_id=${obj.user_id}&plan_id=${obj.plan_id}&platform=${obj.platform}&pay_via=${obj.pay_via}`
    );
    if (response?.data) {
      initPayment(response.data);
    }
  };

  const initPayment = async (data) => {
    const session = await stripe.checkout.sessions.create({
      customer_email: loginData?.email,
      line_items: [{ price: data?.price_id, quantity: 1 }],
      mode: "subscription",
      success_url: `${web_base_url}success?sid={CHECKOUT_SESSION_ID}&pti=${data?.pre_transaction_id}`,
      cancel_url: `${web_base_url}cancel?sid={CHECKOUT_SESSION_ID}&pti=${data?.pre_transaction_id}`,
    });
    const stripeClient = await loadStripe(stripePK);
    stripeClient.redirectToCheckout({
      sessionId: session.id,
    });
  };

  return (
    <>
      <Header />
      <div className="planpackage_page">
        <main className="planpackage_main">
          <section className="planepackage-section">
            <div className="container">
              <h2 className="title"> Subscription Plan</h2>
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-8  col-sm-12">
                  <div className="planepackage-panel">
                    <div className="pricetime-panel">
                      <div className="price-panel">
                        <span className="plan-price">
                          <sup className="price-unite"></sup>
                          <strong className="price_text">
                            ${planData?.[0]?.plan_price}
                          </strong>
                          <sub className="month">/Month</sub>
                        </span>
                      </div>
                    </div>
                    <div className="planepackage-contbody">
                      <ul>
                        <li>Ad-free streaming</li>
                        <li>Early Releases</li>
                        <li>Saved searches</li>
                        <li>Bonus features</li>
                        <li
                          className="plan-text"
                          dangerouslySetInnerHTML={{
                            __html: planData?.[0]?.plan_text || "",
                          }}
                        ></li>
                      </ul>
                      <button
                        type="button"
                        className="getstart-btn"
                        onClick={initiateTransaction}
                      >
                        Subscribe Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default PlanView;
