import {
  call,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { get, post } from "../../api";
import {
  GET_CONTINUE_WATCHING_EMPTY,
  GET_CONTINUE_WATCHING_FAILURE,
  GET_CONTINUE_WATCHING_REQUEST,
  GET_CONTINUE_WATCHING_SUCCESS,
  GET_HOME_EMPTY,
  GET_HOME_EMPTY_DATA,
  GET_HOME_FAILURE,
  GET_HOME_REQUEST,
  GET_HOME_SUCCESS,
  GET_STILL_LOOKING_FAILURE,
  GET_STILL_LOOKING_REQUEST,
  GET_STILL_LOOKING_SUCCESS,
  GET_USER_WATCHING_LIST_EMPTY,
  GET_USER_WATCHING_LIST_FAILURE,
  GET_USER_WATCHING_LIST_REQUEST,
  GET_USER_WATCHING_LIST_SUCCESS,
  GET_CONTINUE_WATCHING_EMPTY_DATA,
  GET_USER_WATCHING_LIST_EMPTY_DATA,
  GET_TRENDING_REQUEST,
  MOST_VIEWED_REQUEST,
  MOST_SHARED_REQUEST,
  FILM_MATTERS_REQUEST,
  REMASTERED_VIDEO_REQUEST,
  GET_TRENDING_SUCCESS,
  GET_TRENDING_FAILURE,
  MOST_VIEWED_SUCCESS,
  MOST_VIEWED_FAILURE,
  MOST_SHARED_SUCCESS,
  MOST_SHARED_FAILURE,
  FILM_MATTERS_SUCCESS,
  FILM_MATTERS_FAILURE,
  REMASTERED_VIDEO_SUCCESS,
  REMASTERED_VIDEO_FAILURE,
  COMING_SOON_SLIDER_REQUEST,
  COMING_SOON_LIST_REQUEST,
  COMING_SOON_LIST_SUCCESS,
  COMING_SOON_SLIDER_SUCCESS,
  COMING_SOON_SLIDER_FAILURE,
  COMING_SOON_LIST_FAILURE,
} from "../types";
// import {
//   comingSoonListRequest,
//   comingSoonSliderRequest,
// } from "../actions/homeAction";

async function getTrendingDataAPI(userId = "", range = 0) {
  try {
    const response = await post(
      `/trending-videos?user_id=${userId}&start_range=${range}`
    );
    if (response?.data) {
      return { data: response?.data, count: response?.count };
    }
    return { data: [], count: 0 };
  } catch (error) {
    throw error;
  }
}

async function mostViewedDataAPI(userId = "", range = 0) {
  try {
    const response = await post(
      `/most-viewed-videos?user_id=${userId}&start_range=${range}`
    );
    if (response?.data) {
      return { data: response?.data, count: response?.count };
    }
    return { data: [], count: 0 };
  } catch (error) {
    throw error;
  }
}

async function mostSharedDataAPI(userId = "", range = 0) {
  try {
    const response = await post(
      `/most-shared-videos?user_id=${userId}&start_range=${range}`
    );
    if (response?.data) {
      return { data: response?.data, count: response?.count };
    }
    return { data: [], count: 0 };
  } catch (error) {
    throw error;
  }
}

async function getFilmMattersDataAPI(userId = "", range = 0) {
  try {
    const response = await post(
      `/film-that-matter-videos?user_id=${userId}&start_range=${range}`
    );
    if (response?.data) {
      return { data: response?.data, count: response?.count };
    }
    return { data: [], count: 0 };
  } catch (error) {
    throw error;
  }
}

async function getcomingSoonSliderDataAPI(userId = "") {
  try {
    const response = await post(`/ott-slider?user_id=${userId}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getComingSoonListDataAPI(userId = "") {
  try {
    const response = await post(
      `/week-wise-upcoming-calender-videos?user_id=${userId}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

// async function getremasteredDataAPI(userId = "") {
//   try {
//     const response = await post(
//       `/remastered-videos?user_id=${userId}&start_range=0`
//     );
//     return response?.data;
//   } catch (error) {
//     throw error;
//   }
// }

async function getApi(userId = "") {
  try {
    const response = await post(`/slider?user_id=${userId}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getHomeDataApi(data) {
  const { count, user_id = "" } = data;
  try {
    const response = await post(
      `/home-videos?start_range=${count}&user_id=${user_id}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getStillLookingDataApi() {
  try {
    const response = await get(`/still-looking`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getContinueWatchingDataApi(user_id = "") {
  try {
    const response = await post(
      `/home-continue-watch-videos?user_id=${user_id}&start_range=0`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getWatchListDataApi(user_id = "") {
  try {
    const response = await post(
      `/home-watchlist-videos?user_id=${user_id}&start_range=0`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchBanner(action) {
  try {
    const bannerData = yield call(getApi, action.userId);
    yield put({ type: "GET_BANNER_SUCCESS", bannerData });
  } catch (e) {
    yield put({ type: "GET_BANNER_FAILED", message: e.message });
  }
}

function* getTrendingData(action) {
  try {
    const stillLookingData = yield call(
      getTrendingDataAPI,
      action.userId,
      action.range
    );
    yield put({ type: GET_TRENDING_SUCCESS, stillLookingData });
  } catch (e) {
    yield put({ type: GET_TRENDING_FAILURE, message: e.message });
  }
}

function* mostViewedData(action) {
  try {
    const stillLookingData = yield call(
      mostViewedDataAPI,
      action.userId,
      action.range
    );
    yield put({ type: MOST_VIEWED_SUCCESS, stillLookingData });
  } catch (e) {
    yield put({ type: MOST_VIEWED_FAILURE, message: e.message });
  }
}

function* mostShareddData(action) {
  try {
    const stillLookingData = yield call(
      mostSharedDataAPI,
      action.userId,
      action.range
    );
    yield put({ type: MOST_SHARED_SUCCESS, stillLookingData });
  } catch (e) {
    yield put({ type: MOST_SHARED_FAILURE, message: e.message });
  }
}

function* filmMattersdData(action) {
  try {
    const stillLookingData = yield call(
      getFilmMattersDataAPI,
      action.userId,
      action.range
    );
    yield put({ type: FILM_MATTERS_SUCCESS, stillLookingData });
  } catch (e) {
    yield put({ type: FILM_MATTERS_FAILURE, message: e.message });
  }
}

function* comingSoonSliderData(action) {
  try {
    const comingSoonSliderData = yield call(
      getcomingSoonSliderDataAPI,
      action.userId
    );
    yield put({ type: COMING_SOON_SLIDER_SUCCESS, comingSoonSliderData });
  } catch (e) {
    yield put({ type: COMING_SOON_SLIDER_FAILURE, message: e.message });
  }
}

function* comingSoonListData(action) {
  try {
    const comingSoonListData = yield call(
      getComingSoonListDataAPI,
      action.userId
    );
    yield put({ type: COMING_SOON_LIST_SUCCESS, comingSoonListData });
  } catch (e) {
    yield put({ type: COMING_SOON_LIST_FAILURE, message: e.message });
  }
}

function* remasteredData(action) {
  try {
    const stillLookingData = yield call(
      getFilmMattersDataAPI,
      action.userId,
      action.count
    );
    yield put({ type: REMASTERED_VIDEO_SUCCESS, stillLookingData });
  } catch (e) {
    yield put({ type: REMASTERED_VIDEO_FAILURE, message: e.message });
  }
}

function* fetchHomeData(action) {
  try {
    const homeData = yield call(getHomeDataApi, action.data);
    yield put({
      type: GET_HOME_SUCCESS,
      homeData,
      isReplace: action.isReplace,
    });
  } catch (e) {
    yield put({ type: GET_HOME_FAILURE, message: e.message });
  }
}

function* fetchStillLooking(action) {
  try {
    const stillLookingData = yield call(getStillLookingDataApi, action);
    yield put({ type: GET_STILL_LOOKING_SUCCESS, stillLookingData });
  } catch (e) {
    yield put({ type: GET_STILL_LOOKING_FAILURE, message: e.message });
  }
}

function* fetchContinueWatching(action) {
  try {
    const continueWatchingData = yield call(
      getContinueWatchingDataApi,
      action.user_id
    );
    const data = {
      ...continueWatchingData?.data?.[0],
      listCount: continueWatchingData?.number_of_rows || 0,
    };
    if (continueWatchingData?.data?.[0]) {
      yield put({
        type: GET_CONTINUE_WATCHING_SUCCESS,
        continueWatchingData: [data],
      });
    } else {
      yield put({
        type: GET_CONTINUE_WATCHING_SUCCESS,
        continueWatchingData: [],
      });
    }
  } catch (e) {
    yield put({ type: GET_CONTINUE_WATCHING_FAILURE, message: e.message });
  }
}

function* fetchWatchList(action) {
  try {
    const watchListData = yield call(getWatchListDataApi, action.user_id);
    yield put({ type: GET_USER_WATCHING_LIST_SUCCESS, watchListData });
  } catch (e) {
    yield put({ type: GET_USER_WATCHING_LIST_FAILURE, message: e.message });
  }
}

export function* homeSaga() {
  yield takeEvery("GET_BANNER_REQUESTED", fetchBanner);
  yield takeLeading(GET_HOME_REQUEST, fetchHomeData);
  yield takeEvery(GET_STILL_LOOKING_REQUEST, fetchStillLooking);
  yield takeEvery(GET_CONTINUE_WATCHING_REQUEST, fetchContinueWatching);
  yield takeEvery(GET_USER_WATCHING_LIST_REQUEST, fetchWatchList);
  yield takeLatest(GET_HOME_EMPTY, function* () {
    yield put({ type: GET_HOME_EMPTY_DATA });
  });
  yield takeLatest(GET_CONTINUE_WATCHING_EMPTY, function* () {
    yield put({ type: GET_CONTINUE_WATCHING_EMPTY_DATA });
  });
  yield takeLatest(GET_USER_WATCHING_LIST_EMPTY, function* () {
    yield put({ type: GET_USER_WATCHING_LIST_EMPTY_DATA });
  });
  yield takeEvery(GET_TRENDING_REQUEST, getTrendingData);
  yield takeLeading(MOST_VIEWED_REQUEST, mostViewedData);
  yield takeEvery(MOST_SHARED_REQUEST, mostShareddData);
  yield takeEvery(FILM_MATTERS_REQUEST, filmMattersdData);
  yield takeEvery(REMASTERED_VIDEO_REQUEST, remasteredData);
  yield takeEvery(COMING_SOON_SLIDER_REQUEST, comingSoonSliderData);
  yield takeEvery(COMING_SOON_LIST_REQUEST, comingSoonListData);
}

export default homeSaga;
