import { PLAYBACK_DATA_REQUEST, PLAYBACK_DATA_EMPTY, REPORT_ERROR, REPORT_HEART_BEAT } from "../types";

export const getPlaybackDataRequest = (data: any) => {
    return {
        type: PLAYBACK_DATA_REQUEST,
        data
    };
};

export const emptyPlaybackData = () => {
    return {
        type: PLAYBACK_DATA_EMPTY
    };
};

export const reportPlaybackError = (data: any) => {
    return {
        type: REPORT_ERROR,
        data
    };
};

export const reportHeartBeat = (data: any) => {
    return {
        type: REPORT_HEART_BEAT,
        data
    };
};

