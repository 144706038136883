import React from "react";

const HomeLoader = () => {
  return (
    <div className="landing_page">
      <div
        className="lolomoRow lolomoRow_title_card lolomoPreview banner_hero"
        data-reactid="138"
      >
        <div className="rowContent" data-reactid="141">
          <div className="slider" data-reactid="142">
            <div className="smallTitleCard loadingTitle" data-reactid="143">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0s",
                  AnimationDelay: "0s",
                }}
                data-reactid="144"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lolomoRow lolomoRow_title_card lolomoPreview portraitlarge_section"
        data-reactid="138"
      >
        <div className="rowHeader" data-reactid="139">
          <span className="rowTitle" data-reactid="140">
            &nbsp;
          </span>
        </div>
        <div className="rowContent" data-reactid="141">
          <div className="slider" data-reactid="142">
            <div className="smallTitleCard loadingTitle" data-reactid="143">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0s",
                  AnimationDelay: "0s",
                }}
                data-reactid="144"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="145">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.2s",
                  AnimationDelay: "0.2s",
                }}
                data-reactid="146"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="147">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.4s",
                  AnimationDelay: "0.4s",
                }}
                data-reactid="148"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="149">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.6000000000000001s",
                  AnimationDelay: "0.6000000000000001s",
                }}
                data-reactid="150"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="151">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.8s",
                  AnimationDelay: "0.8s",
                }}
                data-reactid="152"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lolomoRow lolomoRow_title_card lolomoPreview portraitsmall_section"
        data-reactid="138"
      >
        <div className="rowHeader" data-reactid="139">
          <span className="rowTitle" data-reactid="140">
            &nbsp;
          </span>
        </div>
        <div className="rowContent" data-reactid="141">
          <div className="slider" data-reactid="142">
            <div className="smallTitleCard loadingTitle" data-reactid="143">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0s",
                  AnimationDelay: "0s",
                }}
                data-reactid="144"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="145">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.2s",
                  AnimationDelay: "0.2s",
                }}
                data-reactid="146"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="147">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.4s",
                  AnimationDelay: "0.4s",
                }}
                data-reactid="148"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="149">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.6000000000000001s",
                  AnimationDelay: "0.6000000000000001s",
                }}
                data-reactid="150"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="151">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.8s",
                  AnimationDelay: "0.8s",
                }}
                data-reactid="152"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="153">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "1s",
                  AnimationDelay: "1s",
                }}
                data-reactid="154"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lolomoRow lolomoRow_title_card lolomoPreview landscapesmall_section"
        data-reactid="138"
      >
        <div className="rowHeader" data-reactid="139">
          <span className="rowTitle" data-reactid="140">
            &nbsp;
          </span>
        </div>
        <div className="rowContent" data-reactid="141">
          <div className="slider" data-reactid="142">
            <div className="smallTitleCard loadingTitle" data-reactid="143">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0s",
                  AnimationDelay: "0s",
                }}
                data-reactid="144"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="145">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.2s",
                  AnimationDelay: "0.2s",
                }}
                data-reactid="146"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="147">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.4s",
                  AnimationDelay: "0.4s",
                }}
                data-reactid="148"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="149">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.6000000000000001s",
                  AnimationDelay: "0.6000000000000001s",
                }}
                data-reactid="150"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lolomoRow lolomoRow_title_card lolomoPreview landscapelarge_section"
        data-reactid="138"
      >
        <div className="rowHeader" data-reactid="139">
          <span className="rowTitle" data-reactid="140">
            &nbsp;
          </span>
        </div>
        <div className="rowContent" data-reactid="141">
          <div className="slider" data-reactid="142">
            <div className="smallTitleCard loadingTitle" data-reactid="143">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0s",
                  AnimationDelay: "0s",
                }}
                data-reactid="144"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="145">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.2s",
                  AnimationDelay: "0.2s",
                }}
                data-reactid="146"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLoader;
