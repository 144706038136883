import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/reducer';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { toast } from 'react-toastify';

import { fetchAvatarList, fetchCloneUser, fetchProfile, getPlan, updateAvtarRequest } from '../../redux/actions/profileAction';
import AvatarPopup from '../../Component/Popup/avatarPopup';
import { post } from '../../api';
import AddProfilePopUp from '../../Component/Popup/addProfilePopup';
import { Link, useNavigate } from 'react-router-dom';
import { USERLIST } from '../../routes/routesConstant';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';
import EditProfilePopup from '../../Component/Popup/editProfilePopup';

const Profile = () => {
    const [avatarPopupbool, setAvatarPopupBool] = useState(false);
    const [profilePopupbool, setProfilePopupBool] = useState(false);
    const [editProfilePopupbool, setEditProfilePopupbool] = useState(false);
    const [activeId, setActiveId] = useState('');
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [avtarSelectedImg, setAvtarSelectedImg] = useState('');
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { cloneUserList } = useSelector<IRootState, any>((state) => state.profileReducer);
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    if (!loginData?.user_id) {
        navigate('/signin');
    }
    useEffect(() => {
        dispatch(setRightSideMenuActive(false))
        if (!dev_base_url)
            return;
        dispatch(fetchCloneUser(loginData?.user_id));
        dispatch(fetchAvatarList())
    }, [dev_base_url]);

    useEffect(() => {
        if (avtarSelectedImg) {
            dispatch(updateAvtarRequest(activeId, avtarSelectedImg, loginData?.user_id))
        }
    }, [avtarSelectedImg])

    useEffect(() => {
        setAvtarSelectedImg('')
    }, [cloneUserList])

    const deleteCloneUser = async (id: string) => {
        const response = await post(`/remove-clone-user?user_id=${loginData?.user_id}&clone_user_id=${id}`);
        if (response?.status_code)
            toast(response?.message);
        dispatch(fetchCloneUser(loginData?.user_id));
    }

    const addCloneUser = async (name: string) => {
        setProfilePopupBool(false);
        document.body.classList.remove('modal-open');
        window.$('.modal-backdrop').remove();
        const response = await post(`/create-clone-user?user_id=${loginData?.user_id}&username=${name}`);
        if (response?.status_code)
            toast(response?.message);
        dispatch(fetchCloneUser(loginData?.user_id));
    }

    const editUserProfile = async (data: any) => {
        setEditProfilePopupbool(false);
        setSelectedProfile(null);
        document.body.classList.remove('modal-open');
        window.$('.modal-backdrop').remove();
        if (!data?.email) return
        const response = await post(`/update-profile?user_id=${loginData?.user_id}&name_status=&first_name=${data?.first_name}&last_name=${data?.last_name}&password_status&password=${data?.password}&confirm_password=${data?.confirm_password}&language_status&language=1&email=${data?.email}`);
        if (response?.status_code)
            toast(response?.message);
    }

    return (<>
        {profilePopupbool ? <AddProfilePopUp addCloneUser={addCloneUser} setProfilePopupBool={setProfilePopupBool} /> : <></>}
        {editProfilePopupbool ? <EditProfilePopup editUserProfile={editUserProfile} setEditProfilePopupbool={setEditProfilePopupbool} selectedProfile={selectedProfile} /> : <></>}
        <Header />
        {/* <ToastContainer /> */}
        {avatarPopupbool && <AvatarPopup setAvatarPopupBool={setAvatarPopupBool} setAvtarSelectedImg={setAvtarSelectedImg} />}
        <div className="profile_page">
            <main className="profile_main">
                <div className="container-fluid">
                    <div className="searchresult">
                        <div className="row align-items-center">
                            <div className="col-sm-8 col-6">
                                <div className="title">
                                    <span>Profiles</span>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <form action="">
                                    <div className="adduser_panel text-right">
                                        <span id="addprofile_btn" className="btn common-btn" data-toggle="modal"
                                            data-target="#addprofile-popup" data-whatever="@addprofile-popup" onClick={() => {
                                                navigate(`${USERLIST}`)
                                                // setProfilePopupBool(true)
                                            }}>Change Profile</span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="addedprofile_grid">
                        <div className="row">
                            {cloneUserList?.length ? cloneUserList.map((item: any) => <div key={item.id} className="col-lg-4 col-md-6" style={{ "marginBottom": "20px" }}>
                                <div className="addedprofile_group">
                                    <div className="addedprofile_panel media">
                                        <div className="media-left">
                                            <div className="userprofile-img">
                                                <div className="userprofile-imgthumb">
                                                    <img src={item.profile_image} id="avatar" alt="avatar" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="editname-avatar">
                                                {!item.is_parent ? <h3>
                                                    <span className="editname_text">{item.name}</span> <span className="delete_user" onClick={() => deleteCloneUser(item?.id)}>X</span>
                                                </h3> : <h3>
                                                    <span className="editname_text">{item.name}</span>
                                                </h3>}
                                                <span className="editacatar_text btn common-btn" data-toggle="modal" data-target="#avatarimg-popup"
                                                    data-whatever="@avatarimg-popup" onClick={() => { setAvatarPopupBool(true); setActiveId(item.id); setSelectedProfile(item); }}>Change Avatar</span>

                                                <span className="editacatar_text btn common-btn" data-toggle="modal" data-target="#updateprofile-popup"
                                                    data-whatever="@updateprofile-popup" onClick={() => { setEditProfilePopupbool(true); setActiveId(item.id); setSelectedProfile(item); }}>Edit Profile</span>
                                                {/* <a className="btn common-btn" href="#" data-toggle="modal"
                                                    data-target="#updateprofile-popup" data-whatever="@updateprofile-popup">Edit Profile</a> */}
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="userbio-list">
                                        <li className="d-flex">
                                            <strong>email:</strong>
                                            <span>{item.email}</span>
                                        </li>
                                        <li className="d-flex">
                                            <strong>password ******</strong>
                                        </li>
                                        {/* <li className="d-flex">
                                            <strong>language:</strong><span>English</span>
                                        </li> */}
                                        <li className="d-flex">
                                            <Link to={'/watch-history'}>watch history</Link>
                                        </li>
                                        <li className="d-flex">
                                            <Link to={'/devices'}> device history</Link>
                                        </li>
                                    </ul>

                                </div>
                            </div>) : <></>}
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <Footer />
        <div className="whiteOverlay2" onClick={() => { dispatch(setLeftSideMenuActive(false)); }} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
    </>)
}

export default Profile;