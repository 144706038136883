import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMoodList } from "../../redux/actions/toolAction";
import { IRootState } from "../../redux/reducer";

export const StepTwo = (props: any) => {
    const { moodData, totalcountResp, toolData } = useSelector<IRootState, any>((state) => state.toolReducer);


    return (
        <div className="oracle2_page pt-5">
            <div className="oracle_main">
                <div className="container-fluid">
                    <div className="oracle_bodytext">
                        <h2>{toolData?.title}</h2>
                        <h3>RESULTS:{totalcountResp?.total_number_of_titles}</h3>
                    </div>
                    <section className="editprestep_section">
                        <div className="container-fluid">
                            <div className="editprestep_grid">
                                <div className="editprestep_group">
                                    <div className="editprestep_panel media ">
                                        <p><strong>Step One: Timeline</strong> ({totalcountResp?.from_year} - {totalcountResp?.to_year})</p>
                                        <span className="media-body"><span style={{ "cursor": "pointer" }} className="btn common-btn" onClick={props.firstStepUpdate}>UPDATE</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="choosestep_section">
                        <div className="choosesteptext_grid">
                            <h4>Step Two: What’s you mood?</h4>
                            <p>What you feeling? Something nice? Something evil? Be as specific as possible!</p>
                        </div>
                        <div className="choosestepform_area">
                            <div className="choosestepform_form">
                                <form>
                                    <div className="choosesmood_grid">
                                        {moodData?.length ? moodData.map((item: any) => <div className="choosesmood_group" key={item.genre_id}>
                                            <div className="form-group">
                                                <div className="custom-control common-checkbox custom-checkbox">
                                                    <input type="checkbox" checked={props.moodListId?.includes(item.id)} className="custom-control-input" id={item.id} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            props.setMoodName([...props.moodName, item.mood_name]);
                                                            props.setMoodListId([...props.moodListId, item.id])
                                                        } else {
                                                            const moodListIndex = props.moodListId.indexOf(item.id);
                                                            const moodNameIndex = props.moodName.indexOf(item.mood_name);
                                                            props.moodListId.splice(moodListIndex, 1)
                                                            props.moodName.splice(moodNameIndex, 1)
                                                            props.setMoodListId([...props.moodListId]);
                                                            props.setMoodName([...props.moodName]);
                                                            props.setMoodListId([...props.moodListId])
                                                        }


                                                    }} />
                                                    <label className="custom-control-label" htmlFor={item.id}>
                                                        {item.mood_name}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>) : <></>}
                                    </div>
                                    <div className="choosestepsubmit_panel">
                                        <button type="submit" className="btn common-btn" onClick={() => props.secondStepSubmit()}>SUBMIT</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>)
}

