import { UPDATE_USER_HISTORY, GET_USER_HISTORY_REQUEST, GET_USER_HISTORY_SUCCESS, GET_USER_HISTORY_FAIL, USER_HISTORY_EMPTY } from "../types";

interface IinitialState {
  error: any,
  activityList: any
}
const initialState: IinitialState = {
  error: null,
  activityList: []
}

export default function userActivityReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_USER_HISTORY_REQUEST:
      return {
        ...state
      }
    case GET_USER_HISTORY_FAIL:
      return {
        ...state,
        error: action.error,
        activityList: []
      }
    case GET_USER_HISTORY_SUCCESS:
      return {
        ...state,
        activityList: action.data
      }
    case USER_HISTORY_EMPTY:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}