import { useSelector } from "react-redux";
import { IRootState } from "../../redux/reducer";
import { useEffect } from "react";
import { avatarimg } from "../../Container/Home/theme";

interface IAvatar{
    setAvatarPopupBool:(arg:boolean)=>void,
    setAvtarSelectedImg:(arg:string)=>void
}
const AvatarPopup = ({setAvatarPopupBool,setAvtarSelectedImg}:IAvatar) =>{
    const { avatarList } = useSelector<IRootState, any>((state) => state.profileReducer);

    useEffect(()=>{
     avatarimg();   
    },[avatarList])
    
    return(<div className="share-popup avatarimg_glrypopup">
    <div className="modal fade show" style={{"display": "block"}} id="avatarimg-popup" tabIndex={-1} role="dialog" aria-labelledby="avatarimg-popupLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" onClick={()=>setAvatarPopupBool(false)}>×</span>
                </button>
                <div className="modal-body">
                    <div className="editprofile_text">
                        <strong className="sharemovi-name">Select your Avatar</strong>
                    </div>
                    <div className="avatarimg_gallery">
                        {avatarList?.length? avatarList.map((item:any)=><div className="avatarimg_grid">
                            <h3>{item.category_name}</h3>
                            <div className="avatarimg_slider owl-carousel">
                                {item.avatars?.length? item.avatars.map((avatarObject:any)=><div className="avatarimg_panel" style={{"cursor":"pointer"}} onClick={()=>{setAvtarSelectedImg(avatarObject?.file_path);setAvatarPopupBool(false);document.body.classList.remove('modal-open'); window.$('.modal-backdrop').remove();}}>
                                    <img src={avatarObject?.file_path} id="avatar" alt="avatar" />
                                </div>):<></>}
                            </div>
                        </div>):<></>}
                        {/* <div className="avatarimg_grid">
                            <h3>Minnal Mural</h3>
                            <div className="avatarimg_slider owl-carousel">
                            <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-1.png" id="avatar" alt="avatar" />
                                </div>
                                <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-2.png" id="avatar" alt="avatar" />
                                </div>
                                <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-3.png" id="avatar" alt="avatar" />
                                </div>
                                <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-1.png" id="avatar" alt="avatar" />
                                </div>
                            </div>
                        </div>
                        <div className="avatarimg_grid">
                            <h3>Stranger Things</h3>
                            <div className="avatarimg_slider owl-carousel">
                            <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-1.png" id="avatar" alt="avatar" />
                                </div>
                                <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-2.png" id="avatar" alt="avatar" />
                                </div>
                                <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-3.png" id="avatar" alt="avatar" />
                                </div>
                                <div className="avatarimg_panel">
                                    <img src="img/avatar/avatar-1.png" id="avatar" alt="avatar" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>)
}

export default AvatarPopup;