import { GET_QUESTION_REQUEST, GET_QUESTION_SUCCESS, GET_QUESTION_FAILURE } from "../types";

interface IinitialState {
  errorList: any
}
const initialState: IinitialState = {
  errorList: []
}

export default function reportIssueReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_QUESTION_REQUEST:
      return {
        ...state
      }
    case GET_QUESTION_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        errorList: action.data
      }
    default:
      return state
  }
}