import { call, put, takeEvery } from "redux-saga/effects";
import { post } from "../../api";
import {
  GET_DEVICE_LIST_REQUEST,
  DEVICE_LIST_SUCCESS,
  DEVICE_LIST_EMPTY,
  DEVICE_LIST_FAIL,
} from "../types";

async function getDeviceApi(data) {
  const { user_id = "" } = data;
  try {
    let response = null;
    response = await post(`/user-devices?user_id=${user_id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchDeviceData(action) {
  try {
    const deviceList = yield call(getDeviceApi, action.data);
    yield put({
      type: DEVICE_LIST_SUCCESS,
      deviceList,
    });
  } catch (e) {
    yield put({ type: DEVICE_LIST_FAIL, message: e.message });
  }
}

export function* deviceSaga() {
  yield takeEvery(GET_DEVICE_LIST_REQUEST, fetchDeviceData);
  yield takeEvery(DEVICE_LIST_EMPTY, () =>
    put({
      type: DEVICE_LIST_SUCCESS,
    })
  );
}

export default deviceSaga;
