import React, { useEffect } from 'react';
import { RouterProvider } from "react-router-dom";
import { getInitialApiRequest, stripeConfigRequest } from './redux/actions/initialApiAction';
import { getPlan } from './redux/actions/profileAction';
import { getQuestionDataRequest } from './redux/actions/reportIssueAction';
import { getAdData } from "./redux/actions/adAction";
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './redux/reducer';



function InitialComponent(props: any) {
    const { dev_base_url, live_base_url, status_code, message } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInitialApiRequest());
    }, []);
    useEffect(() => {
        if (dev_base_url || live_base_url) {
            dispatch(stripeConfigRequest());
            dispatch(getPlan());
            dispatch(getQuestionDataRequest());
            dispatch(getAdData({}));
        }
    }, [dev_base_url, live_base_url]);
    return (
        (status_code == 0 ? (
            <div className="subscription_success_page">
                <div className="account_verified">
                    <h3 style={{ color: "#fff" }}>{message}</h3>

                </div>
            </div>) : <RouterProvider router={props.router} />)
    );
}

export default InitialComponent;