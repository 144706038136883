import { GET_DEVICE_LIST_REQUEST, DEVICE_LIST_EMPTY } from "../types";

export const getDeviceListRequest = (data: any) => {
    return {
        type: GET_DEVICE_LIST_REQUEST,
        data
    };
};

export const emptyDeviceListData = () => {
    return {
        type: DEVICE_LIST_EMPTY
    };
};
