import { useDispatch, useSelector } from 'react-redux';
import share from '../../assets/img/share/share-1.jpg';
import { IRootState } from '../../redux/reducer';
import { ICreateListState, IWatchListData } from '../../model/createList';
import { useEffect, useState } from 'react';
import CreateListPopUp from './createListPopup';
import { toggleToastBool } from '../../redux/actions/createListAction';
import { useNavigate } from 'react-router';
import { SIGN_IN } from '../../routes/routesConstant';

const LoginAlertPopup = (props: any) => {
    const { message } = props;
    const navigate = useNavigate();
    useEffect(() => {
        return () => {
            props.setLoginAlertBool('')
        }
    }, [])

    return (<>
        <div className="share-popup wishlist_popup">
            <div className="modal show" id="wishlist-popup" tabIndex={-1} role="dialog" aria-labelledby="wishlist-popupLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ "minHeight": "150px" }}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.setLoginAlertBool('')}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <div className="share-cont" style={{ "textAlign": "center" }}>
                                <div>
                                    <div>
                                        <div style={{ "color": "#fff" }}>
                                            {message}
                                        </div>
                                        <div className="copylink_form">
                                            <form>
                                                <div className="form-group">

                                                    <div className="mt-3">
                                                        <button type="button" className="btn common-btn" onClick={() => { 
                                                            navigate(`${SIGN_IN}`);
                                                            props.setLoginAlertBool(''); window.$('.modal-backdrop').remove(); document.body.classList.remove('modal-open'); 
                                                        }
                                                    }>LOGIN</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default LoginAlertPopup;