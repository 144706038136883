import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/reducer';
import { ICreateListState, IWatchListData } from '../../model/createList';
import { useEffect, useState } from 'react';
import CreateListPopUp from './createListPopup';

const RemoveWishlistPopup = (props: any) => {
    const { video_id, watch_list_id } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    return (<>
        <div className="share-popup wishlist_popup">
            <div className="modal show" id="wishlist-popup" tabIndex={-1} role="dialog" aria-labelledby="wishlist-popupLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content small">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.toggleRemoveWishlist(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <div className="share-cont">
                                <div className="shareimgtitle_group">
                                    <div className="savedsearch_form small">
                                        <div className="share_text slctwishlist_text">
                                            <strong className="sharemovi-name">Do you want to remove from wishlist?</strong>
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <div className="mt-3" style={{ 'display': 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <button type="button" className="btn common-btn" style={{ marginRight: "20px" }} onClick={() => { props.removeVideoList(video_id, watch_list_id); props.toggleRemoveWishlist(false); window.$('.modal-backdrop').remove(); document.body.classList.remove('modal-open'); }}>OK</button>
                                                    <button type="button" className="btn common-btn" onClick={() => { props.toggleRemoveWishlist(false); window.$('.modal-backdrop').remove(); document.body.classList.remove('modal-open'); }}>Cancel</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default RemoveWishlistPopup;