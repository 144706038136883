import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { post } from "../../api";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/reducer";
import PlanView from "../Plan";

const AcceptSharedWatchlist = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const token: string | null = searchParams.get("token");
    const { dev_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const [message, setMessage] = React.useState('');
    useEffect(() => {
        const fetchData = async () => {
            if (dev_base_url && token) {
                const response = await post(`/accept-share-watchlist`, { token });
                if (response?.message) {
                    setMessage(response?.message);
                }
            }
        }
        fetchData();
    }, [token, dev_base_url]);
    return (
        <div className="account_verified">
            <h3 style={{ "color": "#fff" }}>{message}</h3>
            <p>Go back to <Link to="/">Home</Link> page and check the Righ side menu</p>
        </div>
    )
}
export default AcceptSharedWatchlist;