import { call, put, takeEvery } from "redux-saga/effects";
import { post, get } from "../../api";
import {
  GET_QUESTION_REQUEST,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  REPORT_ERROR_REQUEST,
  REPORT_ERROR_SUCCESS,
  REPORT_ERROR_FAILURE,
} from "../types";

async function getReportIssueDataApi(payload) {
  try {
    let response = await get(`/error-master`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function reportErrorDataApi(payload) {
  try {
    let url = `/report-error?error_id=${payload?.error_id}&video_id=${payload?.video_id}&user_id=${payload?.user_id}&error_message=${payload?.error_message}`;
    let response = await post(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchReportIssueData(action) {
  try {
    const data = yield call(getReportIssueDataApi, action.data);
    yield put({
      type: GET_QUESTION_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({ type: GET_QUESTION_FAILURE, message: e.message });
  }
}

function* reportErrorData(action) {
  try {
    const data = yield call(reportErrorDataApi, action.data);
  } catch (e) {}
}

export function* playbackSaga() {
  yield takeEvery(GET_QUESTION_REQUEST, fetchReportIssueData);
  yield takeEvery(REPORT_ERROR_REQUEST, reportErrorData);
}

export default playbackSaga;
