import {
  call,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { get, post } from "../../api";
import {
  CREATE_LIST_FAILURE,
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  FETCH_FRND_WATCHLIST_REQUEST,
  FETCH_FRND_WATCHLIST_SUCCESS,
  FETCH_FRND_WATCHLIST_FAILURE,
  CREATE_VIDEO_LIST_FAILURE,
  CREATE_VIDEO_LIST_REQUEST,
  CREATE_VIDEO_LIST_SUCCESS,
  CREATE_VIDEO_LIST_TOGGLE,
  CREATE_VIDEO_LIST_TOGGLE_FALSE,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  OPEN_WISHLIST_REQUEST,
  OPEN_REMOVE_WISHLIST_REQUEST,
  OPEN_WISHLIST_SUCCESS,
  OPEN_REMOVE_WISHLIST_SUCCESS,
  UPDATE_LIST_REQUEST,
  REMOVE_VIDEO_LIST_REQUEST,
  FETCH_VIDEO_LIST_SUCCESS,
  FETCH_VIDEO_LIST_FAILURE,
  FETCH_VIDEO_LIST_REQUEST,
  GENRE_LIST_REQUEST,
  GENRE_LIST_SUCCESS,
  GENRE_LIST_FAILURE,
  MAIN_MENU_LIST_REQUEST,
  MAIN_MENU_LIST_SUCCESS,
  MAIN_MENU_LIST_FAILURE,
  GENRE_LIST_EMPTY,
  GENRE_LIST_EMPTY_DATA
} from "../types";
import { fetchUserDetail } from "../../utils/utils";

interface IVideoList {
  message: string;
  status_code: string;
};
async function fetchList() {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/my-watch-list?user_id=${fetchUserDetail()?.user_id}`, '', header);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function fetchFriendWatchListApi() {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/friend-share-watchlist?user_id=${fetchUserDetail()?.user_id}`, '', header);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function fetchVideoList(userId: string, watchlistId: string, list_type: string) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/videos-in-watch-list-id?user_id=${userId}&watchlist_id=${watchlistId}&list_type=${list_type || ''}`, '', header);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function fetchGenreList(userId: string, watchlistId: string, genre_type: string, start_range: number, isReplace: boolean) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/video-by-genre?user_id=${userId}&genre_id=${watchlistId}&genre_type=${genre_type}&start_range=${start_range}`, '', header);
    return response;
  } catch (error) {
    throw error;
  }
}

async function fetchMainMenuList(userId: string, categoryId: string, categoryName: string, start_range: number, isReplace: boolean) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/content-by-menu?user_id=${userId}&menu_id=${categoryId}&start_range=${start_range}`, '', header);
    return response;
  } catch (error) {
    throw error;
  }
}

async function createListApi(name: any) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    return await post(`/add-watch-list?user_id=${fetchUserDetail()?.user_id}&watch_list_name=${name}`, '', header);
    // return response?.data;
  } catch (error) {
    throw error;
  }
}

async function createVideoListApi(video_id: number, watchListId: string) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/add-remove-video-watch-list?user_id=${fetchUserDetail()?.user_id}&watch_list_id=${watchListId}&video_id=${video_id}`, '', header);
    return response;
  } catch (error) {
    throw error;
  }
}

async function removeVideoListApi(video_id: number) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/delete-videos-from-watch-list?user_id=${fetchUserDetail()?.user_id}&video_id=${video_id}`, '', header);
    return response;
  } catch (error) {
    throw error;
  }
}

async function deleteListApi(id: number) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/delete-watch-list?user_id=${fetchUserDetail()?.user_id}&watch_list_id=${id}`, '', header);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function updateListApi(id: number, name: string) {
  try {
    const header = {
      "User-Token": fetchUserDetail()?.userToken
    }
    const response = await post(`/update-watch-list?user_id=${fetchUserDetail()?.user_id}&list_id=${id}&watch_list_name=${name}`, '', header);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* createList(reqData: any): Generator {
  try {
    const response: any = yield call(createListApi, reqData.payload);
    if (!response?.data) {
      yield put({ type: CREATE_LIST_FAILURE, message: response.message });
    } else {
      yield put({ type: CREATE_LIST_FAILURE, message: "" });
    }
    // yield put({ type: CREATE_LIST_SUCCESS, createListResp });
    yield call(fetchListData)
  } catch (e: any) {
    yield put({ type: CREATE_LIST_FAILURE, message: e.message });
  }
}

function* createVideoList(data: any): Generator {
  try {
    const createResp: unknown = yield call(createVideoListApi, data.id, data.watchListId);
    const val: IVideoList = createResp as IVideoList;
    if (val.status_code) {
      yield put({ type: CREATE_VIDEO_LIST_SUCCESS, createResp });
    } else {
      yield put({ type: CREATE_VIDEO_LIST_FAILURE, message: 'Unable to add to watchlist.' });
    }
    yield call(fetchListData)
  } catch (e: any) {
    yield put({ type: CREATE_VIDEO_LIST_FAILURE, message: 'Unable to add to watchlist.' });
  }
}
function* removeVideoFromList(data: any): Generator {
  try {
    const createResp: unknown = yield call(removeVideoListApi, data.id);
    const val: IVideoList = createResp as IVideoList;
    if (val.status_code) {
      yield put({ type: CREATE_VIDEO_LIST_SUCCESS, createResp });
    } else {
      yield put({ type: CREATE_VIDEO_LIST_FAILURE, message: 'Unable to remove from watchlist.' });
    }
    yield call(fetchListData)
  } catch (e: any) {
    yield put({ type: CREATE_VIDEO_LIST_FAILURE, message: 'Unable to remove from watchlist.' });
  }
}


function* fetchListData(): Generator {
  try {
    const watchListData = yield call(fetchList);
    yield put({ type: FETCH_LIST_SUCCESS, watchListData });
  } catch (e: any) {
    yield put({ type: FETCH_LIST_FAILURE, message: e.message });
  }
}

function* fetchFriendWatchList(): Generator {
  try {
    const friendWatchlistData = yield call(fetchFriendWatchListApi);
    yield put({ type: FETCH_FRND_WATCHLIST_SUCCESS, friendWatchlistData });
  } catch (e: any) {
    yield put({ type: FETCH_FRND_WATCHLIST_FAILURE, message: e.message });
  }
}


function* fetchVideoListData(data: any): Generator {
  try {
    const watchListVideos = yield call(fetchVideoList, data.user_id, data.watchlist_id, data.list_type);
    yield put({ type: FETCH_VIDEO_LIST_SUCCESS, watchListVideos });
  } catch (e: any) {
    yield put({ type: FETCH_VIDEO_LIST_FAILURE, message: e.message });
  }
}

function* fetchGenreListData(data: any): Generator {
  try {
    const genreListData: any = yield call(fetchGenreList, data.user_id, data.genre_id, data.genre_type, data.start_range, data.isReplace);
    genreListData.isReplace = data.isReplace;
    // genreListData.end = genreListData.videos?.length ? false : true;
    yield put({ type: GENRE_LIST_SUCCESS, genreListData });
  } catch (e: any) {
    yield put({ type: GENRE_LIST_FAILURE, message: e.message });
  }
}

function* fetchMainMenuListData(data: any): Generator {
  try {
    const listData: any = yield call(fetchMainMenuList, data.user_id, data.category_id, data.category_name, data.start_range, data.isReplace);
    let genreListData = { data: listData?.data, count: listData?.count, end: false, isReplace: data.isReplace }
    genreListData.end = genreListData.data?.length ? false : true;
    yield put({ type: MAIN_MENU_LIST_SUCCESS, genreListData });
  } catch (e: any) {
    yield put({ type: MAIN_MENU_LIST_FAILURE, message: e.message });
  }
}

function* deleteList(data: any): Generator {
  try {
    yield call(deleteListApi, data.id);
    // yield put({ type: DELETE_LIST_SUCCESS, createListResp });
    yield call(fetchListData)
  } catch (e: any) {
    yield put({ type: CREATE_LIST_FAILURE, message: e.message });
  }
}

function* updateList(data: any): Generator {
  try {
    const response = yield call(updateListApi, data.id, data.name);
    // yield put({ type: DELETE_LIST_SUCCESS, createListResp });
    yield call(fetchListData)
  } catch (e: any) {
    yield put({ type: CREATE_LIST_FAILURE, message: e.message });
  }
}


function* toggleOpenWishlist(data: any): Generator {
  try {
    yield put({ type: OPEN_WISHLIST_SUCCESS, isAddWishlistOpen: data.isAddWishlistOpen, image: data.image, video_id: data.video_id });
  } catch (e: any) {
  }
}

function* toggleRemoveFromWishlist(data: any): Generator {
  try {
    yield put({ type: OPEN_REMOVE_WISHLIST_SUCCESS, isRemoveWishlistOpen: data.isRemoveWishlistOpen, video_id: data.video_id, watch_list_id: data.watch_list_id });
  } catch (e: any) {
  }
}

function* createVideoListToggle(): Generator {
  try {
    yield put({ type: CREATE_VIDEO_LIST_TOGGLE_FALSE });
  } catch (e: any) {
  }
}

function* emptyGenreListData(): Generator {
  try {
    yield put({ type: GENRE_LIST_EMPTY_DATA });
  } catch (e: any) {
  }
}


export function* createListSaga() {
  yield takeEvery(CREATE_LIST_REQUEST, createList);
  yield takeEvery(FETCH_LIST_REQUEST, fetchListData);
  yield takeEvery(DELETE_LIST_REQUEST, deleteList);
  yield takeEvery(UPDATE_LIST_REQUEST, updateList);
  yield takeEvery(CREATE_VIDEO_LIST_REQUEST, createVideoList);
  yield takeEvery(REMOVE_VIDEO_LIST_REQUEST, removeVideoFromList);
  yield takeEvery(OPEN_WISHLIST_REQUEST, toggleOpenWishlist);
  yield takeEvery(OPEN_REMOVE_WISHLIST_REQUEST, toggleRemoveFromWishlist);
  yield takeEvery(CREATE_VIDEO_LIST_TOGGLE, createVideoListToggle);
  yield takeEvery(FETCH_VIDEO_LIST_REQUEST, fetchVideoListData);
  yield takeEvery(GENRE_LIST_REQUEST, fetchGenreListData);
  yield takeEvery(GENRE_LIST_EMPTY, emptyGenreListData);
  yield takeEvery(MAIN_MENU_LIST_REQUEST, fetchMainMenuListData);
  yield takeEvery(FETCH_FRND_WATCHLIST_REQUEST, fetchFriendWatchList);
}

export default createListSaga;
