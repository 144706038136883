import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestedVideoRequest } from '../../redux/actions/suggestedVideoAction';
import { IRootState } from '../../redux/reducer';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';
import Loader from '../../Component/Loader';
import { CONTENT_DETAIL, PLAYER, SIGN_IN } from '../../routes/routesConstant';

const SuggestedView = () => {
    const [count, setCount] = useState(0)
    const { suggestedVideos, listCount } = useSelector<IRootState, any>((state) => state.suggestedVideoReducer);
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { loginData: { user_id, token } } = useSelector<IRootState, any>((state) => state.authReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    if (!user_id) {
        navigate('/signin');
    }
    useEffect(() => {
        dispatch(setRightSideMenuActive(false));
        if (dev_base_url && user_id) {
            dispatch(getSuggestedVideoRequest({ user_id }));
        }
    }, [dev_base_url, live_base_url, user_id]);

    const getPaginationData = () => {
        setCount(count + 1);
        dispatch(getSuggestedVideoRequest({ count: count + 1, user_id }));
    };
    return (<>
        <Header />
        <div className="category_page">
            <div className="category_main">
                <section className="categorysearch_section">
                    <div className="container-fluid">
                        <div className="categorysearch_main">
                            <div className="col-md-6 col-lg-7">
                                <div className="selectedrslts">
                                    <strong>Suggested Videos</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="category_section">
                    <div className="container-fluid">
                        <div className="category_area">
                            <div className="category_grid">
                                <InfiniteScroll
                                    style={{ 'height': 'inherit', overflow: 'hidden' }}
                                    dataLength={suggestedVideos.length}
                                    next={() => {
                                        if (suggestedVideos?.length < listCount) {
                                            getPaginationData();
                                        }
                                    }}
                                    hasMore={true}
                                    loader={suggestedVideos?.length < listCount ? <Loader type="landscape" /> : <></>}
                                >
                                    <div className="row">
                                        {suggestedVideos?.map((item: any) => <div className="col-6 col-sm-3 col-lg-2" key={item.id}>
                                            <div className="movie-panel category_imgpanel">
                                                <div className="movi-poster">
                                                    <Link to={`${CONTENT_DETAIL}?video_id=${item.video_id}&video_type=${item.video_type}`} className="movieBox">
                                                        <img src={item['portrait_art_2000x3000']} alt="" />
                                                    </Link>
                                                    <div className="movishortinfo_panel">
                                                        <Link to={token ? `${PLAYER}?video_id=${item.video_id}&video_type=${item.video_type}` : `${SIGN_IN}`}>
                                                            <i className="fa fa fa-play" data-toggle="tooltip" data-placement="bottom" title="Play"></i>
                                                        </Link>
                                                        {item.is_my_list ? (<span>
                                                            <i className="fa fa-check" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Remove from watchlist"></i>
                                                        </span>) : (<span>
                                                            <i className="fa fa-plus" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Add to watchlist"></i>
                                                        </span>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </div >
        <div className="whiteOverlay2" onClick={() => dispatch(setLeftSideMenuActive(false))} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
        <Footer />
    </>)
}

export default SuggestedView;