import { useEffect, useState } from "react";
import playIcon from "../../assets/img/coming-soon/episode-icon.png"
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from 'react-router-dom';
import { IRootState } from "../../redux/reducer";
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';
import { comingSoonListRequest, comingSoonSliderRequest } from "../../redux/actions/homeAction";
import { minutessToHm } from "../../utils/utils";
import ComingSoonList from "../../Component/ComingSoonList";
import { setLeftSideMenuActive, setRightSideMenuActive } from "../../redux/actions/headerAction";
import { CONTENT_DETAIL, PLAYER } from '../../routes/routesConstant';

const ComingSoon = () => {
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { comingSoonSliderData, comingSoonListData } = useSelector<IRootState, any>((state) => state.homeReducer);
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const [comingSoonData, setComingSoonData] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLeftSideMenuActive(false));
        dispatch(setRightSideMenuActive(false));
    }, []);
    useEffect(() => {
        if (dev_base_url) {
            dispatch(comingSoonSliderRequest(loginData?.user_id))
            dispatch(comingSoonListRequest(loginData?.user_id))
        }
    }, [dev_base_url])

    useEffect(() => {

        const commonListObj = comingSoonListData && Object.keys(comingSoonListData);
        if (commonListObj?.length) {
            const finalArr: any = [];
            for (let i = 0; i < commonListObj.length; i++) {
                finalArr.push({ name: commonListObj[i], videos: comingSoonListData[commonListObj[i]] });
            }
            setComingSoonData(finalArr)
        }
    }, [comingSoonListData])
    return (<>
        <Header />
        {comingSoonSliderData?.length &&
            <div className="comingsoon_page">
                <section className="current_banner">
                    <div className="current_poster">
                        <img src={comingSoonSliderData?.[0]?.banner_image} alt={comingSoonSliderData?.[0]?.title} />
                        <div className="current_overlay">
                            <div className="current_contet">
                                <div className="crntheading_top">
                                    <img src={comingSoonSliderData?.[0]?.title_image} alt="minkymomo" />
                                </div>
                                <div className="posted_timetype">
                                    <span className="posted_timedate">{minutessToHm(comingSoonSliderData?.[0]?.video_duration)}</span>
                                </div>
                                <p style={{ "width": "50%" }}>{comingSoonSliderData?.[0]?.short_description}...</p>
                                <div className="homebanner-text">
                                    <div className="homebanner_btngroup">
                                        <div className="homebanner_btngroup">
                                            <span className="common-btn premiers_btn">{comingSoonSliderData?.[0]?.release_date}</span>
                                            <Link to={`${PLAYER}?video_id=${comingSoonSliderData?.[0]?.video_id}&video_type=${comingSoonSliderData?.[0]?.video_type}`} className="common-btn play_btn" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Play" data-bs-original-title="Play"
                                                aria-label="Play"><img src={playIcon} /></Link>
                                            <Link to={`${CONTENT_DETAIL}?video_id=${comingSoonSliderData?.[0]?.video_id}&video_type=${comingSoonSliderData?.[0]?.video_type}`} className="common-btn watchtrailer_btn">More info</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        }
        {comingSoonData.length && <ComingSoonList comingSoonListData={comingSoonData} />}
        <Footer />
        <div className="whiteOverlay2" onClick={() => { dispatch(setLeftSideMenuActive(false)); }} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>

    </>)
}

export default ComingSoon;