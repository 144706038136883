import { MEMBERSHIP_LIST_REQUEST, MEMBERSHIP_LIST_SUCCESS, MEMBERSHIP_LIST_FAIL, MEMBERSHIP_CANCEL_FAIL, MEMBERSHIP_CANCEL_SUCCESS } from "../types";

interface IinitialState {
  error: any,
  membershipList: any,
  membershipStatus: any
}
const initialState: IinitialState = {
  error: null,
  membershipList: [],
  membershipStatus: null
}

export default function membershipReducer(state = initialState, action: any) {
  switch (action.type) {
    case MEMBERSHIP_LIST_REQUEST:
      return {
        ...state
      }
    case MEMBERSHIP_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        membershipList: []
      }
    case MEMBERSHIP_LIST_SUCCESS:
      return {
        ...state,
        membershipList: action.data
      }
    case MEMBERSHIP_CANCEL_FAIL:
      return {
        ...state,
        error: action.error,
        membershipStatus: null
      }
    case MEMBERSHIP_CANCEL_SUCCESS:
      return {
        ...state,
        membershipStatus: action.data
      }
    default:
      return state
  }
}