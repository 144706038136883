import { IBanner } from '../../model/bannerModel';
import { ICreateListState, IWatchListAction } from '../../model/createList';
import * as type from '../types';

const initialState: ICreateListState = {
  watchListData: [],
  watchListVideos: [],
  friendWatchlistData: [],
  isAddWishlistOpen: false,
  isRemoveWishlistOpen: false,
  image: '',
  video_id: 0,
  genreList: [],
  end: false,
  listCount: 0
}
export default function createListReducer(state = initialState, action: IWatchListAction) {
  switch (action.type) {
    case type.FETCH_LIST_SUCCESS:
      return {
        ...state,
        watchListData: action.watchListData
      }
    case type.OPEN_WISHLIST_SUCCESS:
      return {
        ...state,
        isAddWishlistOpen: action.isAddWishlistOpen,
        image: action.image,
        video_id: action.video_id
      }
    case type.OPEN_REMOVE_WISHLIST_SUCCESS:
      return {
        ...state,
        isRemoveWishlistOpen: action.isRemoveWishlistOpen,
        video_id: action.video_id,
        watch_list_id: action.watch_list_id,
      }
    case type.CREATE_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        createVideoListBool: action.createResp?.status_code,
        message: action.createResp?.message
      }
    case type.CREATE_LIST_FAILURE:
      return {
        ...state,
        message: action.message
      }
    case type.CREATE_VIDEO_LIST_FAILURE:
      return {
        ...state,
        createVideoListBool: 0,
        message: action.message
      }
    case type.CREATE_VIDEO_LIST_TOGGLE_FALSE:
      return {
        ...state,
        createVideoListBool: 0,
        message: ''
      }
    case type.GENRE_LIST_SUCCESS:
      return {
        ...state,
        genreList: action.genreListData?.isReplace || !action.genreListData?.count ? action.genreListData?.data?.videos : !action.genreListData?.end ? [...state.genreList, ...action.genreListData?.data?.videos] : state.genreList,
        listCount: action.genreListData?.count
      }
    case type.MAIN_MENU_LIST_SUCCESS:
      return {
        ...state,
        genreList: action.genreListData?.isReplace || !action.genreListData?.count ? action.genreListData?.data : !action.genreListData?.end ? [...state.genreList, ...action.genreListData?.data] : state.genreList,
        listCount: action.genreListData?.count
      }
    case type.MAIN_MENU_LIST_FAILURE:
      return {
        ...state,
        genreList: []
      }
    case type.FETCH_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        watchListVideos: action.watchListVideos?.videos
      }
    case type.FETCH_VIDEO_LIST_FAILURE:
      return {
        ...state,
        watchListData: [],
        listCount: 0
      }
    case type.GENRE_LIST_EMPTY_DATA:
      return {
        ...state,
        genreList: [],
        listCount: 0
      }
    case type.FETCH_FRND_WATCHLIST_SUCCESS:
      return {
        ...state,
        friendWatchlistData: action.friendWatchlistData
      }
    case type.FETCH_FRND_WATCHLIST_FAILURE:
      return {
        ...state,
        friendWatchlistData: []
      }
    default:
      return state
  }
}