import { call, put, takeEvery } from "redux-saga/effects";
import { post } from "../../api";
import {
  PLAYBACK_DATA_REQUEST,
  PLAYBACK_DATA_SUCCESS,
  PLAYBACK_DATA_EMPTY,
  PLAYBACK_DATA_FAILURE,
  REPORT_ERROR,
  REPORT_HEART_BEAT,
} from "../types";

async function getPlaybackDataApi(data) {
  const {
    user_id = "",
    video_type,
    video_id,
    season_id = "",
    episode_id = "",
  } = data;
  try {
    let response = null;
    response = await post(
      `/deeplinking-play-url?user_id=${user_id}&video_type=${video_type}&video_id=${video_id}&season_id=${season_id}&episode_id=${episode_id}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function reportPlaybackErrorApi(data) {
  const {
    user_id = "",
    video_type,
    video_id,
    season_id = "",
    episode_id = "",
    message = "",
  } = data;
  try {
    let response = null;
    response = await post(
      `/error-report?user_id=${user_id}&video_type=${video_type}&video_id=${video_id}&season_id=${season_id}&episode_id=${episode_id}&error_message=${message}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function reportHeartBeatApi(data) {
  const {
    user_id = "",
    video_type,
    video_id,
    season_id = "",
    episode_id = "",
    play_time = "",
  } = data;
  try {
    let response = null;
    let url = "";
    if (video_type == 1) {
      url = `/update-play-time?user_id=${user_id}&&video_id=${video_id}&play_time=${parseInt(
        play_time
      )}`;
    } else {
      url = `/update-episode-play-time?user_id=${user_id}&video_id=${video_id}&seasion_id=${season_id}&episode_id=${episode_id}&play_time=${parseInt(
        play_time
      )}`;
    }
    response = await post(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchPlaybackData(action) {
  try {
    const playbackData = yield call(getPlaybackDataApi, action.data);
    yield put({
      type: PLAYBACK_DATA_SUCCESS,
      playbackData,
    });
  } catch (e) {
    yield put({ type: PLAYBACK_DATA_FAILURE, message: e.message });
  }
}

function* reportPlaybackError(action) {
  try {
    yield call(reportPlaybackErrorApi, action.data);
  } catch (e) {}
}

function* reportHeartBeat(action) {
  try {
    yield call(reportHeartBeatApi, action.data);
  } catch (e) {}
}

export function* playbackSaga() {
  yield takeEvery(PLAYBACK_DATA_REQUEST, fetchPlaybackData);
  yield takeEvery(PLAYBACK_DATA_EMPTY, () =>
    put({
      type: PLAYBACK_DATA_SUCCESS,
    })
  );
  yield takeEvery(REPORT_ERROR, reportPlaybackError);
  yield takeEvery(REPORT_HEART_BEAT, reportHeartBeat);
}

export default playbackSaga;
