
import { GET_AUTH_REQUEST, GET_AUTH_FAILURE, GET_AUTH_SUCCESS, GET_LOGIN_REQUEST, GET_SIGNUP_REQUEST, LOGOUT, LOGOUT_SUCCESS, GET_FORGET_REQUEST, RESET_PASSWORD_REQUEST, LOGIN_EMPTY } from "../types";

export const getAuthRequest = (data: any) => {
  return {
    type: GET_AUTH_REQUEST,
    payload: data,
  };
};

export const getAuthSuccess = (data: any) => {
  return {
    type: GET_AUTH_SUCCESS,
    payload: data,
  };
};

export const getAuthFail = (error: any) => {
  return {
    type: GET_AUTH_FAILURE,
    payload: error,
  };
};

export const loginRequest = (data: any, accessType: string) => {
  return {
    type: GET_LOGIN_REQUEST,
    payload: data,
    accessType
  };
};

export const forgetRequest = (data: any, accessType: string) => {
  return {
    type: GET_FORGET_REQUEST,
    payload: data,
    accessType
  };
};

export const signUpRequest = (data: any, accessType: string) => {
  return {
    type: GET_SIGNUP_REQUEST,
    payload: data,
    accessType
  };
};

export const resetPasswordRequest = (data: any, accessType: string) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: data,
    accessType
  };
};

export const logoutAction = (status: boolean) => {
  return {
    type: LOGOUT,
    status
  };
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT,
  };
};

export const loginDataEmptyAction = () => {
  return {
    type: LOGIN_EMPTY,
  };
};
