import { GET_QUESTION_REQUEST, REPORT_ERROR_REQUEST } from "../types";

export const getQuestionDataRequest = () => {
    return {
        type: GET_QUESTION_REQUEST,
        data: null
    };
};

export const reportErrorRequest = (data: any) => {
    return {
        type: REPORT_ERROR_REQUEST,
        data
    };
};


