import React from "react";

const LandscapeLoader = () => {
  return (
    <div className="landing_page">
      <div
        className="lolomoRow lolomoRow_title_card lolomoPreview landscapesmall_section"
        data-reactid="138"
      >
        <div className="rowContent" data-reactid="141">
          <div className="slider" data-reactid="142">
            <div className="smallTitleCard loadingTitle" data-reactid="143">
              <div
                className="ratio-16x9 pulsate"
                style={{ WebkitAnimationDelay: "0s", AnimationDelay: "0s" }}
                data-reactid="144"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="145">
              <div
                className="ratio-16x9 pulsate"
                style={{ WebkitAnimationDelay: "0.2s", AnimationDelay: "0.2s" }}
                data-reactid="146"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="147">
              <div
                className="ratio-16x9 pulsate"
                style={{ WebkitAnimationDelay: "0.4s", AnimationDelay: "0.4s" }}
                data-reactid="148"
              ></div>
            </div>
            <div className="smallTitleCard loadingTitle" data-reactid="149">
              <div
                className="ratio-16x9 pulsate"
                style={{
                  WebkitAnimationDelay: "0.6000000000000001s",
                  AnimationDelay: "0.6000000000000001s",
                }}
                data-reactid="150"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandscapeLoader;
