import { call, put, takeEvery } from "redux-saga/effects";
import { post } from "../../api";
import {
  GET_SUGGESTED_VIDEOS_REQUEST,
  SUGGESTED_VIDEOS_SUCCESS,
  SUGGESTED_VIDEOS_FAIL,
  SUGGESTED_VIDEOS_EMPTY,
} from "../types";

async function getSuggestedVideoApi(data) {
  const { user_id = "", count = 0 } = data;
  try {
    let response = null;
    response = await post(
      `/suggested-videos?user_id=${user_id}&start_range=${count}`
    );
    if (response?.data) {
      return { data: response?.data, count: response?.count };
    }
    return { data: [], count: 0 };
  } catch (error) {
    throw error;
  }
}

function* fetchSuggestedVideoData(action) {
  try {
    const suggestedVideos = yield call(getSuggestedVideoApi, action.data);
    yield put({
      type: SUGGESTED_VIDEOS_SUCCESS,
      suggestedVideos,
    });
  } catch (e) {
    yield put({ type: SUGGESTED_VIDEOS_FAIL, message: e.message });
  }
}

export function* suggestedVideoSaga() {
  yield takeEvery(GET_SUGGESTED_VIDEOS_REQUEST, fetchSuggestedVideoData);
  yield takeEvery(SUGGESTED_VIDEOS_EMPTY, () =>
    put({
      type: SUGGESTED_VIDEOS_SUCCESS,
    })
  );
}

export default suggestedVideoSaga;
