import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import Stripe from "stripe";
import { post } from "../../api";
// import Header from "../../Component/Header";
// import Footer from "../../Component/Footer";

const CancelView = (props) => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [stripeSK, setStripeSK] = useState("");

  const { cypher_token } = useSelector((state) => state.initialApiReducer);
  let stripe;
  const session_id = searchParams.get("sid");
  const pre_transaction_id = searchParams.get("pti");
  useEffect(() => {
    if (session_id && cypher_token) {
      let token = atob(cypher_token);
      token = token.split("-");
      stripe = new Stripe(token[0]);
      setStripeSK(token[0]);
      fetchSessionDetails();
    }
  }, [cypher_token]);

  const fetchSessionDetails = async () => {
    const session = await stripe.checkout.sessions.retrieve(session_id);
    let subscription = null;
    if (session.subscription) {
      subscription = await stripe.subscriptions.retrieve(session.subscription);
    }
    let response = await post(
      `/complete-subscription?pre_transaction_id=${pre_transaction_id}&post_transaction_id=${
        subscription ? subscription.id : session_id
      }&payment_response=${
        subscription ? btoa(JSON.stringify(subscription)) : pre_transaction_id
      }&subscription_status=0`
    );
    if (response) {
      setMessage(response?.message);
    }
  };

  return (
    <div className="subscription_success_page">
      <div className="account_verified">
        <h3 style={{ color: "#fff" }}>{message}</h3>
        <p>
          Go back to <Link to="/"> Home Page</Link>
        </p>
      </div>
    </div>
  );
};

export default CancelView;
