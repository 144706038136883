import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../../Component/Header';
import { IRootState } from '../../redux/reducer';
import { IHomeData } from '../../model/bannerModel';
import Footer from '../../Component/Footer';
import { getMovieDetailEmpty, getSeriesDetailEmpty, getMovieDetailRequest, getSeriesDataRequest } from '../../redux/actions/movieDetailaction';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { updateUserHistory } from '../../redux/actions/userHistoryAction';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';
import { minutessToHm } from '../../utils/utils';
import MovieDetailMore from './movieDetailMore';
import { morelikethislider } from '../Home/theme';
import { SeriesDetail } from './seriesDetail';
import Loader from "../../Component/Loader";
import { createVideoListRequest, openRemoveWishlistRequest, openWishlistRequest, removeVideoListRequest } from '../../redux/actions/createListAction';
import WishListPopup from '../../Component/Popup/wishlistPopup';
import { ICreateListState } from '../../model/createList';
import RemoveWishlistPopup from '../../Component/Popup/removeWishlistPopup';
import { PLAN_PAGE, PLAYER, SIGN_IN } from '../../routes/routesConstant';
import LoginAlertPopup from '../../Component/Popup/loginAlertPopup';
import { MESSAGE } from '../../utils/constant';
import SocialSharePopup from '../../Component/Popup/socialSharePopup';
import premiumIcon from "../../assets/img/premium.png";

declare global {
    interface Window {
        $: any;
        end: any;
        player: any;
        adPlayer: any;
    }
}
const MovieDetail = () => {
    const location = useLocation();
    const { subscriptionData } = useSelector<IRootState, any>((state) => state.profileReducer);
    const { movieDetailData, episodeDetailData } = useSelector<IRootState, any>((state) => state.movieDetailReducer);
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const [searchParams, setSearchParams] = useSearchParams();
    const videoId: string | null = searchParams.get("video_id");
    const video_type: string | null = searchParams.get("video_type");
    const { watchListData, image, createVideoListBool, message } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAddWishlistOpen, video_id, isRemoveWishlistOpen } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const { loginData, logout } = useSelector<IRootState, any>((state) => state.authReducer);
    const [seriesActive, setSeriesActive] = useState(false);
    const [loginAlertBool, setLoginAlertBool] = React.useState('');
    const [sharePopup, setSharePopup] = React.useState(false);
    useEffect(() => {
        dispatch(getMovieDetailEmpty());
        dispatch(getSeriesDetailEmpty());
    }, []);
    const toggleWishlist = (val: boolean, image: string | undefined, video_id: number) => {
        dispatch(openWishlistRequest(val, image, video_id))
    }
    const toggleRemoveWishlist = (val: boolean, video_id: number) => {
        dispatch(openRemoveWishlistRequest(val, video_id));
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        const user_id = loginData?.user_id;
        if (!dev_base_url)
            return
        if (videoId) {
            dispatch(getMovieDetailEmpty());
            dispatch(getSeriesDetailEmpty());
        }
        dispatch(getMovieDetailRequest({ video_id: videoId, video_type: video_type, user_id }));
        if (video_type == '2') {
            dispatch(getSeriesDataRequest({ video_id: videoId, start_range: 0, user_id }));
        }
    }, [videoId, dev_base_url, logout]);

    useEffect(() => {
        if (!dev_base_url)
            return
        if (movieDetailData?.more_like_this?.length) {
            morelikethislider();
        }
        if (movieDetailData && loginData?.user_id) {
            dispatch(updateUserHistory({ user_id: loginData?.user_id, video_type, video_id: videoId, video_title: movieDetailData?.video_title }));
        }
    }, [movieDetailData, dev_base_url]);

    const saveVideoList = (watchListId: string) => {
        dispatch(createVideoListRequest(video_id, watchListId))
    }

    useEffect(() => {
        if (!movieDetailData) {
            return;
        }
        if (createVideoListBool) {
            toast(message);
            const user_id = loginData?.user_id;
            dispatch(getMovieDetailRequest({ video_id: videoId, video_type: video_type, user_id }));
            if (video_type == '2') {
                dispatch(getSeriesDataRequest({ video_id: videoId, start_range: 0, user_id }));
            }
            setSeriesActive(!seriesActive);
        } else if (message) {
            toast(message);
        }
    }, [createVideoListBool, message])
    const removeVideoList = (video_id: number) => {
        dispatch(removeVideoListRequest(video_id));

    }

    const topBannerSection = () => {
        const [episode] = episodeDetailData;
        const { seasion_id, episode_id } = episode || {};
        const { last_played } = movieDetailData;
        return (
            <section className="current_banner">
                {isAddWishlistOpen ? <WishListPopup toggleWishlist={toggleWishlist} saveVideoList={saveVideoList} /> : <></>}
                {isRemoveWishlistOpen ? <RemoveWishlistPopup toggleRemoveWishlist={toggleRemoveWishlist} removeVideoList={removeVideoList} /> : <></>}
                {loginAlertBool ? <LoginAlertPopup setLoginAlertBool={setLoginAlertBool} loginAlertBool={loginAlertBool} message={loginAlertBool} /> : <></>}
                <div className="current_poster">
                    <img src={movieDetailData?.['still_images_1920x1080']} alt="" />
                    <div className="current_overlay">
                        <div className="current_contet">
                            <div className="crntheading_top">
                                <img src={movieDetailData?.banner_title_image} alt="" />
                            </div>
                            {movieDetailData?.is_premium ? (
                                <div className="posted_timetype">
                                    <img src={movieDetailData?.premium_content_icon} alt='premiumIcon' style={{ width: '250px' }} />
                                </div>
                            ) : null}
                            <div className="posted_timetype">
                                <span className="posted_timedate">{minutessToHm(movieDetailData?.video_duration && parseInt(movieDetailData?.video_duration))}{movieDetailData?.video_type == 1 ? `, ${movieDetailData?.published_year}` : `${movieDetailData?.published_year}, ${movieDetailData?.season_episode_count}`}</span>
                            </div>
                            <p>{movieDetailData?.short_description}</p>
                            <div className="homebanner-text">
                                <div className="homebanner_btngroup">
                                    {movieDetailData?.is_coming_soon_status != 1 ? <Fragment>
                                        {movieDetailData.play_time ? <span onClick={() => {
                                            if (!loginData?.token) {
                                                navigate(`${SIGN_IN}`);
                                                return;
                                            }
                                            if (subscriptionData?.subscription_status != "Active" && subscriptionData?.subscription_status != "Cancelled" && movieDetailData.is_premium == '1') {
                                                navigate(`${PLAN_PAGE}`);
                                                return;
                                            }
                                            navigate(`${PLAYER}?video_id=${videoId}&video_type=${video_type}${seasion_id ? ("&season_id=" + seasion_id) : ''}${episode_id ? ("&episode_id=" + episode_id) : ''}`);
                                        }}
                                            className="common-btn watchtrailer_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Play" data-bs-original-title="Play" aria-label="Play">
                                            Resume Video
                                        </span> : <span onClick={() => {
                                            if (!loginData?.token) {
                                                navigate(`${SIGN_IN}`);
                                                return;
                                            }
                                            if (subscriptionData?.subscription_status != "Active" && subscriptionData?.subscription_status != "Cancelled" && movieDetailData.is_premium == '1') {
                                                navigate(`${PLAN_PAGE}`);
                                                return;
                                            }
                                            navigate(`${PLAYER}?video_id=${videoId}&video_type=${video_type}${seasion_id ? ("&season_id=" + seasion_id) : ''}${episode_id ? ("&episode_id=" + episode_id) : ''}`);
                                        }} className="common-btn play_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Play" data-bs-original-title="Play" aria-label="Play">
                                            <i className="fa fa-play"></i>
                                        </span>}
                                    </Fragment> : null}
                                    {movieDetailData?.is_coming_soon_status ? <span className="common-btn premiers_btn">{movieDetailData?.release_date}</span> : null}
                                    {movieDetailData.is_my_list ? (<span onClick={() => toggleRemoveWishlist(true, movieDetailData.video_id)} className="common-btn addwishlist_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove from watchlist" data-bs-original-title="Remove from watchlist" aria-label="Add to watchlist"><i className="fa fa-check"></i></span>) : <span onClick={() => {
                                        if (!loginData?.token) {
                                            setLoginAlertBool(MESSAGE.LOGIN_TO_ADD_TO_WISHLIST)
                                            return;
                                        }
                                        toggleWishlist(true, movieDetailData.portrait_art_2000x3000, movieDetailData.video_id)
                                    }} className="common-btn addwishlist_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to watchlist" data-bs-original-title="Add to watchlist" aria-label="Add to watchlist"><i className="fa fa-plus"></i></span>}
                                    <span className="common-btn watchtrailer_btn" onClick={() => { closeSharePopup(true) }}>Share</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {video_type === '1' && movieDetailData?.play_time ? <div className="seekbar_panel">
                    <div className="seekbar_fill" style={{ "width": `${movieDetailData ? (movieDetailData?.play_time / movieDetailData?.total_time) * 100 : 0}%` }}></div>
                </div> : null}
            </section>
        )
    }



    const shortInfoSection = () => {
        return (<section className="movishortinfo-section">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-8 movishortinfo-colmn">
                        <div className="movishortinfo-text">
                            <div className="media align-items-center" style={{ marginBottom: "15px" }}>
                                <h3>{movieDetailData?.video_title}</h3>
                                <div className="movishortstatus_panel media-body pl-2">
                                    {movieDetailData?.is_premium_icon ? (<a href="#">
                                        <i><img src={movieDetailData?.is_premium_icon} className="most-shared" /></i>
                                    </a>) : null}
                                    {movieDetailData?.is_remastered_icon ? (<a href="#">
                                        <i><img src={movieDetailData?.is_remastered_icon} alt="most-shared" className="most-shared" /></i>
                                    </a>) : null}
                                    {movieDetailData?.is_most_minute_viewed_icon ? (<a href="#">
                                        <i><img src={movieDetailData?.is_most_minute_viewed_icon} alt="most-shared" className="most-shared" /></i>
                                    </a>) : null}
                                    {movieDetailData?.is_most_shared_icon ? (<a href="#">
                                        <i><img src={movieDetailData?.is_most_shared_icon} alt="most-shared" className="most-shared" /></i>
                                    </a>) : null}
                                    {movieDetailData?.film_that_matter_icon ? (<a href="#">
                                        <i><img src={movieDetailData?.film_that_matter_icon} alt="most-shared" className="most-shared" /></i>
                                    </a>) : null}
                                </div>
                            </div>
                            {/* <button type="button" className="btn add-btn" style={{ marginBottom: "15px" }}>
                                <span>Play Trailer</span>
                            </button> */}
                            <Link className="common-btn watchtrailer_btn" to={`${PLAYER}?video_id=${movieDetailData.video_id}&video_type=${movieDetailData.video_type}${movieDetailData.season_id ? ("&season_id=" + movieDetailData.season_id) : ''}${movieDetailData.episode_id ? ("&episode_id=" + movieDetailData.episode_id) : ''}&content_type=trailer`}>
                                Play Trailer
                            </Link>

                            <ul className="movishortinfo_list">
                                {movieDetailData?.video_directors?.length ? <li>
                                    Directed by {movieDetailData?.video_directors?.map((user: {
                                        director_description: string,
                                        director_id: number,
                                        director_image: null,
                                        director_name: string,
                                        director_slug: string,
                                        video_id: number
                                    }) => user.director_name).join(',')}
                                </li> : <></>}
                                {movieDetailData?.video_actors?.length ? <li>
                                    Cast:  {movieDetailData?.video_actors?.map((user: {
                                        actor_description: string,
                                        actor_id: number,
                                        director_image: null,
                                        actor_name: string,
                                        actor_slug: string,
                                        video_id: number
                                    }) => user.actor_name).join(',')}
                                </li> : <></>}
                                {movieDetailData?.video_genres?.length ? <li>
                                    Genres: {movieDetailData?.video_genres?.map((genre: {
                                        genre_id: number,
                                        genre_name: string,
                                        genre_slug: string,
                                        video_id: number
                                    }) => genre.genre_name).join(', ')}
                                </li> : <></>}
                                {movieDetailData?.video_languages?.length ? <li>
                                    Languages: {movieDetailData?.video_languages?.map((language: {
                                        id: number,
                                        language_name: string,
                                        language_short_name: string
                                    }) => language.language_name).join(', ')}
                                </li> : <></>}
                                {movieDetailData?.video_countries?.length ? <li>
                                    Countries: {movieDetailData?.video_countries?.map((genre: {
                                        country_id: number,
                                        name: string,
                                        continent_code: string,
                                        genre_slug: string,
                                        number: number,
                                        video_id: number
                                    }) => genre.name).join(', ')}
                                </li> : <></>}
                                {movieDetailData?.video_subtitles?.length ? <li>
                                    Subtitles: {movieDetailData?.video_subtitles?.map((genre: {
                                        subtitle_language: number,
                                        language_name: string,
                                        genre_slug: string,
                                        subtitle_type: number,
                                        video_id: number
                                    }) => genre.language_name).join(', ')}
                                </li> : <></>}
                                {movieDetailData?.long_description ? <li>
                                    Description: {movieDetailData?.long_description}
                                </li> : <></>}

                            </ul>
                        </div>
                    </div>
                    {video_type === '1' ? <div className="col-md-4">
                        <div className="movishortinfo-panel">
                            <div className="movishortinfo-media">
                                <img className="img-fluid" src={movieDetailData?.['portrait_art_2000x3000']} alt="movi-4" />
                            </div>
                        </div>
                    </div> : <></>}
                </div>

            </div>
        </section>);
    }
    const moreLikeThisSection = () => {
        return (<section className="movie-section moviedetail_section">
            <div className="container-fluid">
                <div className="movie_main">
                    <div className="title">
                        <span className="movititle-text"> More Like This </span>
                    </div>
                    <div className="morelikethis-slider owl-carousel">
                        {movieDetailData?.more_like_this?.length && movieDetailData?.more_like_this?.map((item: IHomeData, index: number) => <MovieDetailMore key={item.id} toggleRemoveWishlist={toggleRemoveWishlist} item={item} index={index} toggleWishlist={toggleWishlist} />)}
                    </div>
                </div>
            </div>
        </section>);
    }


    const closeSharePopup = (show: boolean) => {
        setSharePopup(show);
    }
    return (<>
        <Header />
        {sharePopup ? <SocialSharePopup setSharePopup={closeSharePopup} /> : null}
        {/* <ToastContainer /> */}
        {movieDetailData ? (<div className="movidetails-page">
            {topBannerSection()}
            <div className="movidetails_main">
                {video_type === '2' && !movieDetailData?.is_coming_soon_status ? <SeriesDetail setLoginAlertBool={setLoginAlertBool} seriesActive={seriesActive} video_id={videoId} loginData={loginData} toggleWishlist={toggleWishlist} toggleRemoveWishlist={toggleRemoveWishlist} /> : null}
                {shortInfoSection()}
                {moreLikeThisSection()}
            </div>
        </div>) : <Loader type="details" />}
        <Footer />

        <div className="whiteOverlay2" onClick={() => dispatch(setLeftSideMenuActive(false))} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
    </>)
}

export default MovieDetail;