export const KEYS = {
  UUID: "uuid",
  USER_DETAILS: "user_details",
};

export const MESSAGE = {
  LOGIN_TO_PLAY_CONTENT: "Please login if you want to play this video",
  CREATE_ACCOUNT_TO_PLAY_CONTENT: "To watch please create a free account",
  LOGIN_TO_SAVE_SEARCH: "Login to save search",
  LOGIN_TO_ADD_TO_WISHLIST:
    "Please login before adding this video to your custom list.",
};
