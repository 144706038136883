import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { post } from "../../api";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/reducer";
import PlanView from "../Plan";

const AccountVerification = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const token: string | null = searchParams.get("token");
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const [message, setMessage] = React.useState('');
    const [responseData, setResponseData] = React.useState(null);
    useEffect(() => {
        const fetchData = async () => {
            if (dev_base_url && token) {
                const response = await post(`/account-verification`, { token });
                if (response?.data?.signup_type === "NEW-SUBSCRIPTION") {
                    setResponseData(response?.data);
                }
                setMessage(response?.message)
            }
        }
        fetchData();
    }, [token, dev_base_url]);
    return (<>
        {!responseData ? (<div className="account_verified">
            <h3 style={{ "color": "#fff" }}>{message}</h3>
            <p>Go back to <Link to="/">Home</Link></p>
        </div>) : <PlanView responseData={responseData} />}
    </>)
}
export default AccountVerification;