import { call, put, takeEvery } from "redux-saga/effects";
import { get, post } from "../../api";
import { loginApi } from "./authSaga";
import { store } from "../../redux/configure-store";

import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  AVATAR_LIST_REQUEST,
  AVATAR_LIST_SUCCESS,
  AVATAR_LIST_FAILURE,
  FETCH_CLONES_REQUEST,
  FETCH_CLONES_SUCCESS,
  FETCH_CLONES_FAILURE,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_FAILURE,
  LOGIN_CLONE_REQUEST,
  GET_PLAN_REQUEST,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  FETCH_CLONES_EMPTY,
  FETCH_CLONES_EMPTY_DATA,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  GET_SUBSCRIPTION_INFO_REQUEST,
  GET_SUBSCRIPTION_INFO_FAILURE,
  GET_SUBSCRIPTION_INFO_SUCCESS,
} from "../types";
async function getCloneUSerApi(userId = "") {
  try {
    let response = null;
    response = await post(`/my-clone-user?user_id=${userId}`);

    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function updateAvtarApi(userId = "", url) {
  try {
    let response = null;
    response = await post(`/update-avatar?user_id=${userId}&avatar_url=${url}`);

    return response;
  } catch (error) {
    throw error;
  }
}

async function CloneUserApi(userId = "", cloneId) {
  try {
    let response = null;
    response = await post(
      `/login-as-clone-user?user_id=${userId}&clone_user_id=${cloneId}`,
      null,
      {
        isLogin: true,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function fetchProfileDataApi(userId = "") {
  try {
    let response = null;
    response = await post(`/view-profile?user_id=${userId}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function fetchPlanDataApi() {
  try {
    const response = await get(`/get-plans`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function fetchSubscriptionDataApi(payload) {
  try {
    const response = await post(
      `/user-subscription?user_id=${payload?.user_id}`
    );
    return response?.data || {};
  } catch (error) {
    throw error;
  }
}

async function getAvatarApi() {
  try {
    let response = await get(`/avatar-gallery`);
    return response;
  } catch (error) {
    throw error;
  }
}

function* updateAvtarRequest(action) {
  try {
    yield call(updateAvtarApi, action.userId, action.url);
    yield call(fetchCloneUserData, { userId: action.id });
  } catch (e) {
    yield put({ type: UPDATE_AVATAR_FAILURE, message: e.message });
  }
}

function* fetchCloneUserData(action) {
  try {
    const closeUserData = yield call(getCloneUSerApi, action.userId);
    yield put({
      type: FETCH_CLONES_SUCCESS,
      closeUserData,
    });
  } catch (e) {
    yield put({ type: FETCH_CLONES_FAILURE, message: e.message });
  }
}

function* getAvatarList() {
  try {
    const avatarList = yield call(getAvatarApi);

    yield put({
      type: AVATAR_LIST_SUCCESS,
      avatarList: avatarList?.data,
    });
  } catch (e) {
    yield put({
      type: AVATAR_LIST_FAILURE,
      message: "Error while saving to searches",
    });
  }
}

function* fetchProfileData(action) {
  try {
    const profileData = yield call(fetchProfileDataApi, action.userId);
    yield put({
      type: PROFILE_SUCCESS,
      profileData,
    });
  } catch (e) {
    yield put({ type: PROFILE_FAILURE, message: e.message });
  }
}

function* fetchPlanData() {
  try {
    const planData = yield call(fetchPlanDataApi);
    yield put({
      type: GET_PLAN_SUCCESS,
      planData,
    });
  } catch (e) {
    yield put({ type: GET_PLAN_FAILURE, message: e.message });
  }
}

function* loginAsCloneRequest(action) {
  try {
    if (action.isParent) {
      const formData = { email: action.email, password: action.password };
      const data = yield call(loginApi, formData, "login");
      yield put({ type: GET_LOGIN_SUCCESS, data });
    } else {
      const response = yield call(CloneUserApi, action.userId, action.cloneId);
      if (response.error) {
        yield put({ type: GET_LOGIN_FAILURE });
      } else {
        const data = {
          rootUserDetail:
            store.getState()["authReducer"]?.loginData?.rootUserDetail,
          token: response?.bearer_token || response?.token,
          ...response?.data,
          userToken: response?.user_token,
          message: response?.message,
          status_code: response.status_code,
        };
        yield put({ type: GET_LOGIN_SUCCESS, data });
      }
    }
  } catch (e) {
    yield put({ type: GET_LOGIN_FAILURE, message: e.message });
  }
}

function* fetchSubscriptionData(action) {
  try {
    const data = yield call(fetchSubscriptionDataApi, action.data);
    yield put({
      type: GET_SUBSCRIPTION_INFO_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({ type: GET_SUBSCRIPTION_INFO_FAILURE, message: e.message });
  }
}

function* emptyCloneData() {
  yield put({
    type: FETCH_CLONES_EMPTY_DATA,
  });
}

export function* profileSaga() {
  yield takeEvery(PROFILE_REQUEST, fetchProfileData);
  yield takeEvery(AVATAR_LIST_REQUEST, getAvatarList);
  yield takeEvery(FETCH_CLONES_REQUEST, fetchCloneUserData);
  yield takeEvery(UPDATE_AVATAR_REQUEST, updateAvtarRequest);
  yield takeEvery(LOGIN_CLONE_REQUEST, loginAsCloneRequest);
  yield takeEvery(GET_PLAN_REQUEST, fetchPlanData);
  yield takeEvery(GET_SUBSCRIPTION_INFO_REQUEST, fetchSubscriptionData);
  yield takeEvery(FETCH_CLONES_EMPTY, emptyCloneData);
}

export default profileSaga;
