export const CONTENT_DETAIL = "/content";
export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const PLAYER = "/player";
export const CATEGORY = "/category";
export const GENRE = "/genre";
export const SEARCH = "/search";
export const PRIVACYPOLICY = "/privacy-policy";
export const TERMS = "/terms";
export const HELP = "/help";
export const FAQ = "/faq";
export const ABOUT = "/about";
export const ACCOUNT_VERIFICATION = "/account-verification";
export const RESET = "/reset-password";
export const ACTIVATETV = "/activate-tv";
export const PROFILE = "/profile";
export const ORACLE = "/oracle";
export const USERLIST = "/user-list";
export const ADDPROFILE = "/add-profile";
export const STILLLOOKINGCONTENT = "/still-looking";
export const COMINGSOON = "/coming-soon";
export const WATCH_HISTORY = "/watch-history";
export const DEVICES = "/devices";
export const MEMBERSHIP = "/membership";
export const SUGGESTED_VIEW = "/suggested-view";
export const PLAN_PAGE = "/subscribe";
export const TRANSACTION_SUCCESS = "/success";
export const TRANSACTION_CANCEL = "/cancel";
export const ACCEPT_SHARE_WATCHLIST = "accept-share-watchlist"
