import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgetRequest, loginRequest } from "../../redux/actions/authAction";
import { IRootState } from '../../redux/reducer';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { USERLIST } from "../../routes/routesConstant";

const SignIn = () => {
    const navigate = useNavigate();
    const [type, setType] = useState(false);
    const [forgetPass, setForgetPass] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        rememberme: true
    });
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { forgetData } = useSelector<IRootState, any>((state) => state.authReducer);

    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const isValidEmail = (email: any) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const submit = () => {
        let message = '';
        if (!formData.email) {
            message = 'Please input username';
        }
        if (!isValidEmail(formData.email)) {
            message = 'Please enter valid email!';
        }
        if (forgetPass) {
            if (message) {
                setError(message);
                return;
            } else {
                setError('');
            }
            dispatch(forgetRequest(formData, 'forget'))
            return;
        }

        if (!formData.password) {
            message = 'Please input password';
        }

        if (message) {
            setError(message);
            return;
        } else {
            setError('');
        }
        dispatch(loginRequest(formData, 'login'))
    };

    useEffect(() => {
        if (loginData?.error?.toLowerCase() === "unauthenticated") {
            setError('Invalid Email or Password')
        } else if (loginData?.token && loginData?.accessType !== "signup") {
            toast(loginData?.message);
            navigate(`${USERLIST}`);
        }
    }, [loginData])

    useEffect(() => {
        if (forgetData?.status_code) {
            toast(forgetData?.message);
        }
    }, [forgetData])

    return (<>
        <div className="loginrgstr_col">
            <div className="loginrgstr_colalign">
                <div className="loginsignup-form">
                    <div className="loginrgstr_cont">
                        <h3 className="loginrgstr_toptitle">ALREADY HAVE AN ACCOUNT?</h3>
                        <h2>{forgetPass ? 'Forget Password' : 'Log In'}</h2>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input name="username"
                                className="form-control floatLabel floatLabel"
                                id="username"
                                placeholder="Email/Phone"
                                value={formData?.email}
                                onChange={(e) => {
                                    setFormData({ ...formData, email: e.target.value })
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        submit()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {!forgetPass ? <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel floatLabel"
                                id="password"
                                type={!type ? "password" : "text"}
                                placeholder="password"
                                value={formData?.password}
                                onChange={(e) => {
                                    setFormData({ ...formData, password: e.target.value })
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        submit()
                                    }
                                }}
                            />
                            <div className="input-group-prepend">
                                <div className="input-group-text" onClick={() => setType(!type)}>{!type ? 'Show' : 'Hide'}</div>
                            </div>
                        </div>
                    </div> : <></>}
                    {!forgetPass ? <div className="form-group">
                        <div className="custom-control common-checkbox checkbox custom-checkbox">
                            <input type="checkbox" className="custom-control-input"
                                id="rememberme"
                                tabIndex={0}
                                name="rememberme"
                                checked={formData?.rememberme}
                                onChange={(e) => {
                                    setFormData({ ...formData, rememberme: e.target.checked })
                                }}
                            />
                            <label className="custom-control-label" htmlFor="rememberme">
                                <span className="remember_me">Remember me</span></label>
                            <span tabIndex={1} onClick={() => { setForgetPass(true) }} className="forgot_pass">Forgot password</span>
                        </div>
                    </div> : <div className="form-group forget_parent">
                        <div className="custom-control common-checkbox checkbox custom-checkbox">
                            <label className="custom-control-label" htmlFor="rememberme">
                                <span className="remember_me">Already have an account?</span></label>
                            <span onClick={() => { setForgetPass(false) }} className="forgot_pass">Log In</span>
                        </div>
                    </div>}
                    <div className="form-group">
                        <span className="forgot_pass" style={{ "lineHeight": "1", "fontSize": "12px" }}>{error}</span>
                        <button tabIndex={2} type="button" className="btn btn-block" onClick={submit}>{forgetPass ? 'Submit' : 'Log in'}</button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default SignIn;