import { GET_SUGGESTED_VIDEOS_REQUEST, SUGGESTED_VIDEOS_EMPTY } from "../types";

export const getSuggestedVideoRequest = (data: any) => {
    return {
        type: GET_SUGGESTED_VIDEOS_REQUEST,
        data
    };
};

export const emptySuggestedVideoData = () => {
    return {
        type: SUGGESTED_VIDEOS_EMPTY
    };
};
