import { useState } from 'react';

import avtarImg from '../../assets/img/add-avatar.png';
import { useNavigate } from 'react-router';
import { USERLIST } from '../../routes/routesConstant';
import { useDispatch, useSelector } from 'react-redux';
import { post } from '../../api';
import { IRootState } from '../../redux/reducer';

const AddProfile = () => {
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);

    return (<div className="addprofile_page">
        <main className="addprofile_main">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-9">
                        <div className="title flex-wrap">
                            <h3>Add Profile</h3>
                            <p className="w-100">Add Profile for another person watching Darkroom.</p>
                        </div>
                        <div className="addprofile_grid">
                            <div className="addprofile_group media">
                                <div className="addprofile_imgpanel">
                                    <div className="commonicon-uploadbtn">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="adduserCustomFile" required={false} />
                                            <label className="custom-file-label border-2" htmlFor="adduserCustomFile">
                                                <span><img src={avtarImg} id="addnew_user" alt="add-avatar" /></span>
                                            </label>
                                            <div className="invalid-feedback">Example invalid custom file feedback
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="addeduser_form media-body">
                                    <div className="addeduser_form ">
                                        <form className="media align-items-center" action="">
                                            <input type="text" className="form-control" id="name" placeholder="Name" name="username" onChange={(e: any) => setName(e.target?.value)} />
                                            <div className="form-check media-body">
                                                <div className="custom-control common-checkbox custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="child" />
                                                    <label className="custom-control-label" htmlFor="child">
                                                        child?
                                                    </label>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="continuecancel_btn btn_parent pt-4">
                                <button type="button" className="btn add-btn" onClick={async () => {
                                    const response = await post(`/create-clone-user?user_id=${loginData?.user_id}&username=${name}`);
                                    if (response?.status_code === 1) {
                                        navigate(`${USERLIST}`);
                                    };
                                    //  toast(response?.message);
                                }}>
                                    <span>Continue</span>
                                </button>
                                <button type="button" className="btn add-btn" onClick={() => navigate(`${USERLIST}`)}>
                                    <span>Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>)
}

export default AddProfile;