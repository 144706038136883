import axios from "axios";
import { store } from "../redux/configure-store";
import { KEYS } from "../utils/constant";
import { LOGOUT } from "../redux/types";
import { removeItem } from "../utils/storage";
import { getDeviceId } from "../utils/utils";
const axiosApiInstance = axios.create();
axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
  "X-Device": "web",
  "X-Platform": "browser",
};

axiosApiInstance.interceptors.response.use(
  (response) => {
    const {
      config: { headers },
      data,
    } = response;
    if (headers["User-Token"] && data["status"] === "401") {
      store.dispatch({
        type: LOGOUT,
        status: true,
      });
      removeItem(KEYS.USER_DETAILS);
    }
    return response;
  },
  (error) => {
    const {
      config: { headers },
    } = error;
    if (
      (error?.response?.status == 401 &&
        error?.config?.url?.includes("logout")) ||
      (error?.response?.status == 401 &&
        error?.response?.data?.message == "Invalid user token" &&
        headers["User-Token"])
    ) {
      store.dispatch({
        type: LOGOUT,
        status: true,
      });
      removeItem(KEYS.USER_DETAILS);
    }
    return Promise.reject(error);
  }
);

export const initialApiUrl = "https://www.darkroom.film/backend/api/v3";
// export const initialApiUrl = "https://www.darkroompremiere.com/dmDev/api/v3";
// export const initialApiUrl = "http://54.197.152.85/dmDev/api/v3";
// export const initialApiUrl = "https://www.darkroompremiere.com/dmDev/api/v3";

export const get = async (url, headers = {}) => {
  if (!url) {
    return;
  }
  const baseUrl = store.getState().initialApiReducer?.dev_base_url;
  const configData = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const loginData = store.getState()["authReducer"]?.loginData;
  let header = {
    "X-Device-Id": deviceId,
    "X-Api-Key": configData?.api_token || "",
    "X-Ip-Address": configData?.ip_address || "",
    "X-Device": "web",
    "X-Platform": "web",
  };
  if (loginData?.token) {
    header = { ...header, Authorization: `Bearer ${loginData.token}` };
  }
  if (loginData?.userToken) {
    header = { ...header, "User-Token": `${loginData.userToken}` };
  }
  let config = {
    headers: { ...headers, ...header },
  };
  let response = await axiosApiInstance.get(`${baseUrl}${url}`, config);
  return response.data;
};

export const post = async (url, data, headers = {}) => {
  if (!url) {
    return;
  }
  const baseUrl = store.getState().initialApiReducer?.dev_base_url;
  const configData = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const loginData = store.getState()["authReducer"]?.loginData;
  let header = {
    "X-Device-Id": deviceId,
    "X-Api-Key": configData?.api_token || "",
    "X-Ip-Address": configData?.ip_address || "",
    "X-Device": "web",
    "X-Platform": "web",
  };

  if (loginData?.token) {
    header = {
      ...header,
      Authorization: headers?.isLogin
        ? `Bearer ${loginData.rootUserDetail?.token}`
        : `Bearer ${loginData.token}`,
    };
  }
  if (loginData?.userToken) {
    header = {
      ...header,
      "User-Token": headers?.isLogin
        ? `${loginData.rootUserDetail?.userToken}`
        : `${loginData.userToken}`,
    };
  }
  let config = {
    headers: { ...headers, ...header },
  };
  const response = await axiosApiInstance.post(
    `${baseUrl}${url}`,
    data,
    config
  );
  return response.data;
};

export const put = async (url, data, headers = {}) => {
  if (!url) {
    return;
  }
  const baseUrl = store.getState().initialApiReducer?.dev_base_url;
  const configData = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const loginData = store.getState()["authReducer"]?.loginData;
  let header = {
    "X-Device-Id": deviceId,
    "X-Api-Key": configData?.api_token || "",
    "X-Ip-Address": configData?.ip_address || "",
    "X-Device": "web",
    "X-Platform": "web",
  };
  if (loginData?.token) {
    header = { ...header, Authorization: `Bearer ${loginData.token}` };
  }
  if (loginData?.userToken) {
    header = { ...header, "User-Token": `${loginData.userToken}` };
  }
  let config = {
    method: "put",
    url: `${baseUrl}${url}`,
    headers: { ...headers, ...header },
    data,
  };

  const response = await axios(config);
  return response.data;
};

export const sendApiStatus = async (url, data, headers = {}) => {
  if (!url) {
    return;
  }
  const baseUrl = store.getState().initialApiReducer?.dev_base_url;
  const configData = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const loginData = store.getState()["authReducer"]?.loginData;
  let header = {
    "X-Device-Id": deviceId,
    "X-Api-Key": configData?.api_token || "",
    "X-Ip-Address": configData?.ip_address || "",
    "X-Device": "web",
    "X-Platform": "web",
  };
  if (loginData?.token) {
    header = { ...header, Authorization: `Bearer ${loginData.token}` };
  }
  if (loginData?.userToken) {
    header = { ...header, "User-Token": `${loginData.userToken}` };
  }
  let config = {
    method: "put",
    url: `${baseUrl}${url}`,
    headers: { ...headers, ...header },
    data,
  };

  const response = await axios(config);
  return response.data;
};
