import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { getUserHistoryRequest } from '../../redux/actions/userHistoryAction';
import Loader from "../../Component/Loader";
import { IRootState } from '../../redux/reducer';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';


declare global {
    interface Window {
        $: any;
        end: any;
    }
}

const WatchHistory = () => {
    const dispatch = useDispatch();
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const { loginData: { user_id, username } } = useSelector<IRootState, any>((state) => state.authReducer);
    const { dev_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { activityList } = useSelector<IRootState, any>((state) => state.userActivityReducer);
    const navigate = useNavigate();
    if (!user_id) {
        navigate('/signin');
    }
    useEffect(() => {
        dispatch(setRightSideMenuActive(false));
    }, []);
    useEffect(() => {
        if (left_active)
            dispatch(setLeftSideMenuActive(false))
        if (!dev_base_url)
            return
        dispatch(getUserHistoryRequest({ user_id }));
    }, [user_id, dev_base_url]);


    return (
        <>
            <Header />

            <div className="profile_page history_page">
                <main className="userhistory_main">
                    <div className="container-fluid">
                        <div className="searchresult">
                            <div className="row align-items-center">
                                <div className="col-sm-7 col-md-8 col-6">
                                    <div className="title">
                                        {username ? <span>Active for {username}</span> : null}
                                    </div>
                                </div>
                                {/* <div className="col-sm-5 col-md-4 col-6">
                                    <form action="">
                                        <div className="adduser_panel text-right">
                                            <span className="btn common-btn"><a href="profile.html">Back to Profile</a></span>
                                        </div>
                                    </form>
                                </div> */}
                            </div>
                        </div>

                        <div className="userhistory_grid">
                            {activityList?.map((item: any) => {
                                return (<div className="userhistory_group" key={item.id}>
                                    <div className="media">
                                        <div className="media-left">
                                            <span className="userhistory_date">{item.created_at}</span>
                                        </div>
                                        <div className="media-body">
                                            <p>
                                                <span>{item.activity_name}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </main>
            </div>

            <Footer />
            <div className="whiteOverlay2" onClick={() => dispatch(setLeftSideMenuActive(false))} style={{ display: left_active ? 'block' : '' }}></div>
            <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
        </>)
}

export default WatchHistory;