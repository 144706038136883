export default class AdWrapper {
  constructor(obj) {
    this.adTagUrl = obj.adUrl;
    this.videoId = obj.videoId;
    this.playerContainerId = obj.playerContainer;
    this.adContainerId = obj.adContainer;
    this.adsManager = null;
    this.adsLoader = null;
    this.adDisplayContainer = null;
    this.isAdPlaying = false;
    this.isContentFinished = false;
    this.intervalTimer = null;
    this.callback = obj.adCallback;
    this.videoContent = document.getElementById(`${this.videoId}_html5_api`);
    this.playerContainer = document.getElementById(`${this.playerContainerId}`);
    this.adContainer = document.getElementById(`${this.adContainerId}`);
    this.setUpIMA();
  }

  playAds = () => {
    this.adDisplayContainer.initialize();
    try {
      this.adsManager.init(
        this.playerContainer.clientWidth,
        this.playerContainer.clientHeight,
        window.google.ima.ViewMode.NORMAL
      );
      this.adsManager.start();
    } catch (adError) {
      this.videoContent?.play();
    }
  };

  setUpIMA = () => {
    // Create the ad display container.
    this.createAdDisplayContainer();
    // Create ads loader.
    // window.google.ima.settings.setPlayerType(
    //   "google/codepen-demo-manual-ad-breaks"
    // );
    // window.google.ima.settings.setPlayerVersion("1.0.0");
    this.adsLoader = new window.google.ima.AdsLoader(this.adDisplayContainer);
    // Listen and respond to ads loaded and error events.
    this.adsLoader.addEventListener(
      window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.onAdsManagerLoaded,
      false
    );
    this.adsLoader.addEventListener(
      window.google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
      false
    );

    const contentEndedListener = () => {
      if (this.isAdPlaying) return;
      this.isContentFinished = true;
      this.adsLoader.contentComplete();
    };
    this.videoContent.onended = contentEndedListener;

    // Request video ads.
    const adsRequest = new window.google.ima.AdsRequest();
    adsRequest.adTagUrl = this.adTagUrl;
    adsRequest.linearAdSlotWidth = this.playerContainer.clientWidth;
    adsRequest.linearAdSlotHeight = this.playerContainer.clientHeight;
    adsRequest.nonLinearAdSlotWidth = this.playerContainer.clientWidth;
    adsRequest.nonLinearAdSlotHeight = this.playerContainer.clientHeight / 3;
    this.adsLoader.requestAds(adsRequest);
  };

  createAdDisplayContainer = () => {
    this.adDisplayContainer = new window.google.ima.AdDisplayContainer(
      this.adContainer,
      this.videoContent
    );
  };

  onAdsManagerLoaded = (adsManagerLoadedEvent) => {
    // Get the ads manager.
    const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
    // videoContent should be set to the content video element.
    this.adsManager = adsManagerLoadedEvent.getAdsManager(
      this.videoContent,
      adsRenderingSettings
    );

    // Add listeners to the required events.
    this.adsManager.addEventListener(
      window.google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError
    );
    this.adsManager.addEventListener("vastEmptyResponse", this.onAdError);
    this.adsManager.addEventListener("vastMediaLoadTimeOut", this.onAdError);
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
      this.onAdEvent
    );
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      this.onAdEvent
    );
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      this.onAdEvent
    );

    // Listen to any additional events, if necessary.
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.LOADED,
      this.onAdEvent
    );
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.STARTED,
      this.onAdEvent
    );
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.COMPLETE,
      this.onAdEvent
    );
    this.adsManager.addEventListener("adBreakReady", this.onAdEvent);
    this.adsManager.addEventListener("pause", this.onAdEvent);
    this.adsManager.addEventListener("adProgress", this.onAdEvent);
    this.adsManager.addEventListener("adBuffering", this.onAdEvent);
    this.adsManager.addEventListener("resume", this.onAdEvent);
    this.adsManager.addEventListener("skip", this.onAdEvent);
    this.adsManager.addEventListener("volumeChange", this.onAdEvent);
    this.adsManager.addEventListener("mute", this.onAdEvent);
    this.adsManager.addEventListener("impression", this.onAdEvent);
    this.adsManager.addEventListener("firstquartile", this.onAdEvent);
    this.adsManager.addEventListener("midpoint", this.onAdEvent);
    this.adsManager.addEventListener("thirdquartile", this.onAdEvent);
    this.adsManager.addEventListener("click", this.onAdClick);
    this.adsManager.addEventListener("adMetadata", this.onAdEvent);
    this.adsManager.addEventListener("adBreakFetchError", this.onAdEvent);
    this.adsManager.addEventListener("log", this.onAdEvent);
    this.playAds();
  };

  onAdClick = (adEvent) => {
    adEvent.preventDefault();
    this.callback(adEvent);
  };
  onAdEvent = (adEvent) => {
    // Retrieve the ad from the event. Some events (for example,
    // ALL_ADS_COMPLETED) don't have ad object associated.
    const ad = adEvent.getAd();
    // console.log(adEvent.type);
    switch (adEvent.type) {
      case window.google.ima.AdEvent.Type.LOADED:
        // This is the first event sent for an ad - it is possible to
        // determine whether the ad is a video ad or an overlay.
        if (!ad.isLinear()) {
          // Position AdDisplayContainer correctly for overlay.
          // Use ad.width and ad.height.
          this.videoContent.play();
        } else {
          this.videoContent.pause();
        }
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.adContainer?.classList?.remove("add-overlay");
        }, 2000);
        this.callback(adEvent);
        this.adContainer?.classList?.add("add-overlay");
        break;
      case window.google.ima.AdEvent.Type.STARTED:
        this.timeout && clearTimeout(this.timeout);
        // This event indicates the ad has started - the video player
        // can adjust the UI, for example display a pause button and
        // remaining time.
        // if (ad.isLinear()) {
        // For a linear ad, a timer can be started to poll for
        // the remaining time.
        // this.intervalTimer = setInterval(function () {
        // Example: const remainingTime = this.adsManager.getRemainingTime();
        // }, 300); // every 300ms
        // }
        this.adContainer?.classList?.add("add-overlay");
        this.callback(adEvent);
        break;
      case window.google.ima.AdEvent.Type.COMPLETE:
        // This event indicates the ad has finished - the video player
        // can perform appropriate UI actions, such as removing the timer for
        // remaining time detection.
        if (ad.isLinear()) {
          clearInterval(this.intervalTimer);
        }
        this.adContainer?.classList?.remove("add-overlay");
        this.callback(adEvent);
        break;
      case window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
        this.callback(adEvent);
        break;
      case window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED:
        this.onContentPauseRequested();
        this.callback(adEvent);
        break;
      case window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED:
        this.onContentResumeRequested();
        this.callback(adEvent);
        break;
      default:
        this.callback(adEvent);
        break;
    }
  };

  play = () => {
    this.adsManager.resume();
  };

  pause = () => {
    this.adsManager.pause();
  };

  destroy = () => {
    this.adsManager?.destroy();
    this.adsManager = null;
    this.videoContent?.remove();
  };

  onAdError = (adErrorEvent) => {
    if (this.adsManager) {
      this.adsManager.destroy();
    }
    this.adContainer?.classList?.remove("add-overlay");
    this.callback({ type: "error" });
    this.videoContent?.play();
  };

  onContentPauseRequested = () => {
    this.isAdPlaying = true;
    this.videoContent.pause();
  };

  onContentResumeRequested = () => {
    this.isAdPlaying = false;
    if (!this.isContentFinished) {
      this.videoContent.play();
    }
  };
}
