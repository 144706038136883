import { call, put, takeEvery } from "redux-saga/effects";
import { post } from "../../api";
import {
  UPDATE_USER_HISTORY,
  GET_USER_HISTORY_REQUEST,
  GET_USER_HISTORY_SUCCESS,
  GET_USER_HISTORY_FAIL,
  USER_HISTORY_EMPTY,
} from "../types";

async function getUserHistoryDataApi(data) {
  const { user_id = "", start_range = 0 } = data;
  try {
    let url = `/my-activity-log?user_id=${user_id}&start_range=${start_range}`;
    let response = await post(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function updateUserHistoryApi(data) {
  const {
    user_id = "",
    video_title = "",
    video_id = "",
    season_id = "",
    episode_id = "",
    video_type = "",
  } = data;
  try {
    let activity_name = `${video_title}`;
    // let activity_id = `video_id="${video_id}"`;
    if (season_id) {
      activity_name = `${activity_name}:Season ${season_id}`;
      // activity_id = `${activity_id},session_id="${season_id}"`;
    }
    if (episode_id) {
      activity_name = `${activity_name}:Episode ${episode_id}`;
      // activity_id = `${activity_id},episode_id="${episode_id}"`;
    }
    let url = `/save-activity-log?user_id=${user_id}&activity_name=${activity_name}&video_id=${video_id}&video_type=${video_type}&season_id=${season_id}&episode_id=${episode_id}`;
    let response = await post(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchUserHistory(action) {
  try {
    const data = yield call(getUserHistoryDataApi, action.data);
    yield put({
      type: GET_USER_HISTORY_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({ type: GET_USER_HISTORY_FAIL, message: e.message });
  }
}

function* updateUserHistory(action) {
  try {
    yield call(updateUserHistoryApi, action.data);
  } catch (e) {}
}

export function* userHistorySaga() {
  yield takeEvery(GET_USER_HISTORY_REQUEST, fetchUserHistory);
  yield takeEvery(UPDATE_USER_HISTORY, updateUserHistory);
  yield takeEvery(USER_HISTORY_EMPTY, () =>
    put({
      type: GET_USER_HISTORY_SUCCESS,
    })
  );
}

export default userHistorySaga;
