
import { CREATE_LIST_FAILURE, CREATE_LIST_REQUEST, CREATE_LIST_SUCCESS, CREATE_VIDEO_LIST_REQUEST, CREATE_VIDEO_LIST_TOGGLE, DELETE_LIST_REQUEST, FETCH_LIST_FAILURE, FETCH_LIST_REQUEST, FETCH_LIST_SUCCESS, OPEN_WISHLIST_REQUEST, UPDATE_LIST_REQUEST, REMOVE_VIDEO_LIST_REQUEST, OPEN_REMOVE_WISHLIST_REQUEST, FETCH_VIDEO_LIST_REQUEST, GENRE_LIST_REQUEST, MAIN_MENU_LIST_REQUEST, GENRE_LIST_EMPTY, FETCH_FRND_WATCHLIST_REQUEST } from "../types";

export const createListRequest = (data: any) => {
  return {
    type: CREATE_LIST_REQUEST,
    payload: data,
  };
};

export const createListFail = (error: any) => {
  return {
    type: CREATE_LIST_FAILURE,
    payload: error,
  };
};

export const fetchListFail = (error: any) => {
  return {
    type: FETCH_LIST_FAILURE,
    payload: error,
  };
};

export const fetchListRequest = () => {
  return {
    type: FETCH_LIST_REQUEST
  };
};

export const fetchFriendsWatchlistRequest = () => {
  return {
    type: FETCH_FRND_WATCHLIST_REQUEST
  };
};

export const deleteListRequest = (id: number) => {
  return {
    type: DELETE_LIST_REQUEST,
    id
  };
};

export const updateListRequest = (id: number, name: string) => {
  return {
    type: UPDATE_LIST_REQUEST,
    id,
    name
  };
};

export const createVideoListRequest = (id: number, watchListId: string) => {
  return {
    type: CREATE_VIDEO_LIST_REQUEST,
    id,
    watchListId
  };
};

export const removeVideoListRequest = (id: number) => {
  return {
    type: REMOVE_VIDEO_LIST_REQUEST,
    id
  };
};

export const toggleToastBool = () => {
  return {
    type: CREATE_VIDEO_LIST_TOGGLE
  };
};



export const openWishlistRequest = (val: boolean, image: string | undefined, video_id: number) => {
  return {
    type: OPEN_WISHLIST_REQUEST,
    isAddWishlistOpen: val,
    image,
    video_id
  };
};

// export const openLoginPopup = (val: boolean, image: string | undefined, video_id: number) => {
//   return {
//     type: OPEN_WISHLIST_REQUEST,
//     isAddWishlistOpen: val
//   };
// };

export const fetchVideoListRequest = (user_id: string | undefined, watchlist_id: string | null, list_type: string | null) => {
  return {
    type: FETCH_VIDEO_LIST_REQUEST,
    user_id,
    watchlist_id,
    list_type
  };
};

export const fetchGenreListRequest = (user_id: string | undefined, genre_id: string | null, genre_type: string | null, start_range: number, isReplace: boolean) => {
  return {
    type: GENRE_LIST_REQUEST,
    user_id,
    genre_id,
    genre_type,
    start_range,
    isReplace
  };
};

export const emptyGenreData = () => {
  return {
    type: GENRE_LIST_EMPTY
  }
}

export const fetchMainmenuListRequest = (user_id: string | undefined, category_id: string | null, category_name: string | null, start_range: number, isReplace: boolean) => {
  return {
    type: MAIN_MENU_LIST_REQUEST,
    user_id,
    category_id,
    category_name,
    start_range,
    isReplace
  };
};


export const openRemoveWishlistRequest = (val: boolean, video_id: number) => {
  return {
    type: OPEN_REMOVE_WISHLIST_REQUEST,
    isRemoveWishlistOpen: val,
    video_id
  };
};