import { GET_DEVICE_LIST_REQUEST, DEVICE_LIST_SUCCESS, DEVICE_LIST_EMPTY, DEVICE_LIST_FAIL } from "../types";

interface IinitialState {
  error: any,
  deviceList: any
}
const initialState: IinitialState = {
  error: null,
  deviceList: []
}

export default function deviceReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_DEVICE_LIST_REQUEST:
      return {
        ...state
      }
    case DEVICE_LIST_FAIL:
      return {
        ...state,
        error: action.error
      }
    case DEVICE_LIST_SUCCESS:
      return {
        ...state,
        deviceList: action.deviceList
      }
    case DEVICE_LIST_EMPTY:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}