import { AxiosError } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { post } from '../../api';
import { IMovieDetailRequestData, ResponseGenerator, IType } from '../../model/movieDetail';
import { GET_EPISODE_DETAIL_FAILURE, GET_EPISODE_DETAIL_REQUEST, GET_EPISODE_DETAIL_SUCCESS, GET_EPISODE_EMPTY_DATA, GET_MOVIE_DETAIL_FAILURE, GET_MOVIE_DETAIL_REQUEST, GET_MOVIE_DETAIL_SUCCESS, GET_SERIES_DETAIL_FAILURE, GET_SERIES_DETAIL_REQUEST, GET_SERIES_DETAIL_SUCCESS, GET_MOVIE_DETAIL_EMPTY, GET_SERIES_DETAIL_EMPTY } from '../types';

async function getMovieDetailDataApi(data: IMovieDetailRequestData) {
    try {
        const response = await post(`/video-detail?video_id=${data?.video_id}&video_type=${data?.video_type}&user_id=${data?.user_id || ""}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

async function getSeriesDetailDataApi(data: IMovieDetailRequestData) {
    try {
        const response = await post(`/seasion-list?video_id=${data.video_id}&start_range=0&user_id=${data?.user_id || ""}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

async function getEpisodeDetailDataApi(data: IMovieDetailRequestData) {
    try {
        const response = await post(`/episode-list?video_id=${data.video_id}&seasion_id=${data.seasion_id}&start_range=${data.start_range}&user_id=${data?.user_id || ""}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

function* fetchMovieDetailData({ detail }: IType) {
    try {
        const movieDetailData: ResponseGenerator = yield call(getMovieDetailDataApi, detail);
        yield put({ type: GET_MOVIE_DETAIL_SUCCESS, movieDetailData });
    } catch (e: unknown) {
        if (e instanceof AxiosError) {
            yield put({ type: GET_MOVIE_DETAIL_FAILURE, message: e.message });
        }
    }
}

function* fetchSeriesDetailData({ detail }: IType) {
    try {
        const seriesDetailData: ResponseGenerator = yield call(getSeriesDetailDataApi, detail);
        yield put({ type: GET_SERIES_DETAIL_SUCCESS, seriesDetailData });
    } catch (e: unknown) {
        if (e instanceof AxiosError) {
            yield put({ type: GET_SERIES_DETAIL_FAILURE, message: e.message });
        }
    }
}

function* fetchEpisodeDetailData({ detail }: IType) {
    try {
        const episodeDetailData: ResponseGenerator = yield call(getEpisodeDetailDataApi, detail);
        yield put({ type: GET_EPISODE_DETAIL_SUCCESS, episodeDetailData });
    } catch (e: unknown) {
        if (e instanceof AxiosError) {
            yield put({ type: GET_EPISODE_DETAIL_FAILURE, message: e.message });
        }
    }
}

export function* movieDetailSaga() {
    yield takeLatest(GET_MOVIE_DETAIL_REQUEST, fetchMovieDetailData);
    yield takeLatest(GET_MOVIE_DETAIL_EMPTY, function* () {
        yield put({ type: GET_MOVIE_DETAIL_SUCCESS, movieDetailData: null });
    });
    yield takeLatest(GET_SERIES_DETAIL_EMPTY, function* () {
        yield put({ type: GET_SERIES_DETAIL_SUCCESS, seriesDetailData: null });
    });
    yield takeLatest(GET_EPISODE_EMPTY_DATA, function* () {
        yield put({ type: GET_EPISODE_DETAIL_SUCCESS, episodeEmptyData: true });
    });
    yield takeEvery(GET_SERIES_DETAIL_REQUEST, fetchSeriesDetailData);
    yield takeEvery(GET_EPISODE_DETAIL_REQUEST, fetchEpisodeDetailData);
}

export default movieDetailSaga;