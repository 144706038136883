import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import { IRootState } from '../../redux/reducer';
import { resetSaveSearchRequestMessage } from '../../redux/actions/searchAction';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CONTENT_DETAIL } from '../../routes/routesConstant';
import { createVideoListRequest, openRemoveWishlistRequest, openWishlistRequest, removeVideoListRequest, toggleToastBool } from '../../redux/actions/createListAction';
import RemoveWishlistPopup from '../../Component/Popup/removeWishlistPopup';
import WishListPopup from '../../Component/Popup/wishlistPopup';
import { ICreateListState } from '../../model/createList';
import Loader from '../../Component/Loader';

const GridView = (props: any) => {
    const { showText, data, onQueryChange, query, setSavePopup, searchName, count, getPaginationData } = props;
    const style: any = showText === '1' ? { minHeight: "300px" } : { minHeight: "0px" };
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const dispatch = useDispatch()

    const onChangeHandler = (e: any) => {
        const text = e.target.value;
        onQueryChange(text);
    };
    const toggleRemoveWishlist = (val: boolean, video_id: number) => {
        dispatch(openRemoveWishlistRequest(val, video_id));
    }
    const toggleWishlist = (val: boolean, image: string | undefined, video_id: number) => {
        dispatch(openWishlistRequest(val, image, video_id));
        dispatch(toggleToastBool());
    }
    const { isAddWishlistOpen, isRemoveWishlistOpen, video_id } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const saveVideoList = (watchListId: string) => {
        dispatch(createVideoListRequest(video_id, watchListId))
    }
    const removeVideoList = (video_id: number) => {
        dispatch(removeVideoListRequest(video_id));
    }
    const { createVideoListBool, message } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);

    useEffect(() => {
        if (createVideoListBool) {
            toast(message);
        } else if (message) {
            toast(message);
        }
    }, [createVideoListBool, message])
    return (
        <>
            {isAddWishlistOpen ? <WishListPopup toggleWishlist={toggleWishlist} saveVideoList={saveVideoList} /> : <></>}
            {isRemoveWishlistOpen ? <RemoveWishlistPopup toggleRemoveWishlist={toggleRemoveWishlist} removeVideoList={removeVideoList} /> : <></>}
            <div className="searchresultlrg_main">
                <section className="searchresultcommonhead_section">
                    <div className="container-fluid">
                        <div className="searchresult">
                            {showText === '1' ? (<div className="top-search searchtitle_form">
                                <div className="input-group">
                                    <span className="input-group-addon search_icon"><i className="fa fa-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Find movies, shows, and more" value={query} onChange={onChangeHandler} />
                                    <span className="input-group-addon close-search" onClick={(e) => { onQueryChange(''); }}>X</span>
                                </div>
                            </div>) : null}
                            <div className="row align-items-center">
                                <div className="col-md-6 col-lg-7">
                                    <div className="selectedrslts">
                                        <strong>{searchName ? `Search result for ${searchName}` : 'Search Results'}</strong>
                                    </div>
                                </div>
                                {!searchName && <div className="col-md-6 col-lg-5">
                                    <div className="seavfilter">
                                        <button type="submit" className="btn common-btn" onClick={() => {
                                            if (!loginData?.token) {
                                                props.setLoginAlertBool(true);
                                                return;
                                            } dispatch(resetSaveSearchRequestMessage());
                                            setSavePopup(true)
                                        }}>Save</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="searchresultlrg-section" style={style}>
                    <div className="container-fluid">
                        <div className="searchresultlarge_grid">

                            <InfiniteScroll
                                style={{ 'height': 'inherit', overflow: 'hidden' }}
                                dataLength={data.length}
                                next={() => {
                                    if (data?.length < count) {
                                        getPaginationData();
                                    }
                                }}
                                hasMore={true}
                                loader={data.length < count ? <Loader type="landscape" /> : <></>}
                            >
                                <div className="row">
                                    {data && data.length > 0 ? data.map((item: any, index: number) => {
                                        return (<div className="col-6 col-sm-3 col-lg-2" key={item.video_id}>
                                            <div className="movie-panel">
                                                <div className="movi-poster">
                                                    <Link to={`${CONTENT_DETAIL}?video_id=${item.video_id}&video_type=${item.video_type}`} className="movieBox">
                                                        <img src={item['portrait_art_2000x3000']} alt="top-picks-1" />
                                                    </Link>
                                                    <div className="movishortinfo_panel">
                                                        <span>
                                                            <Link to={`${CONTENT_DETAIL}?video_id=${item.video_id}&video_type=${item.video_type}`} className="movieBox">
                                                                <i className="fa fa fa-play" data-toggle="tooltip" data-placement="bottom" title="Play"></i></Link>
                                                        </span>
                                                        {/* <span> */}
                                                        {item.is_my_list ? (<span onClick={() => { toggleRemoveWishlist(true, item.video_id) }}>
                                                            <i className="fa fa-check" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Remove from watchlist"></i>
                                                        </span>) : (<span onClick={() => {
                                                            if (!loginData?.token) {
                                                                props.setLoginAlertBool(true);
                                                                return;
                                                            } toggleWishlist(true, item.portrait_art_2000x3000, item.video_id)
                                                        }}>
                                                            <i className="fa fa-plus" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Add to watchlist"></i>
                                                        </span>)}
                                                        {/* <i className="fa fa-plus" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Add to watchlist"></i> */}
                                                        {/* </span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }) : null}
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}

export default GridView;

