import { IMovieDetailRequestData } from "../../model/movieDetail";
import { GET_MOVIE_DETAIL_EMPTY, GET_SERIES_DETAIL_EMPTY, GET_MOVIE_DETAIL_REQUEST, GET_MOVIE_DETAIL_SUCCESS, GET_MOVIE_DETAIL_FAILURE, GET_SERIES_DETAIL_REQUEST, GET_SERIES_EMPTY_DATA, GET_EPISODE_DETAIL_REQUEST, GET_EPISODE_EMPTY_DATA } from "../types";

export const getMovieDetailRequest = (detail: IMovieDetailRequestData) => {
    return {
        type: GET_MOVIE_DETAIL_REQUEST,
        detail
    };
};

export const getMovieDetailSuccess = (movieDetailData: any) => {
    return {
        type: GET_MOVIE_DETAIL_SUCCESS,
        payload: movieDetailData,
    };
};

export const getMovieDetailFail = (error: { message: string }) => {
    return {
        type: GET_MOVIE_DETAIL_FAILURE,
        payload: error,
    };
};

export const getMovieDetailEmpty = () => {
    return {
        type: GET_MOVIE_DETAIL_EMPTY
    };
};

export const getSeriesDetailEmpty = () => {
    return {
        type: GET_SERIES_DETAIL_EMPTY
    };
};

export const getSeriesDataRequest = (data: any) => {
    return {
        type: GET_SERIES_DETAIL_REQUEST,
        detail: data
    };
}

export const getSeriesDataEmpty = () => {
    return {
        type: GET_SERIES_EMPTY_DATA
    };
};

export const getEpisodeDataRequest = (detail: any) => {
    return {
        type: GET_EPISODE_DETAIL_REQUEST,
        detail
    };
}

export const getEpisodeDataEmpty = () => {
    return {
        type: GET_EPISODE_EMPTY_DATA
    };
};
