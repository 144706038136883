import { AVATAR_LIST_REQUEST, FETCH_CLONES_EMPTY, FETCH_CLONES_REQUEST, GET_PLAN_REQUEST, LOGIN_CLONE_REQUEST, PROFILE_REQUEST, UPDATE_AVATAR_EMPTY, UPDATE_AVATAR_REQUEST, GET_SUBSCRIPTION_INFO_REQUEST } from "../types";

export const fetchProfile = (userId: string) => {
    return {
        type: PROFILE_REQUEST,
        userId
    };
};

export const fetchCloneUser = (userId: string) => {
    return {
        type: FETCH_CLONES_REQUEST,
        userId
    };
};

export const emptyCloneData = () => {
    return {
        type: FETCH_CLONES_EMPTY,
    };
};

export const fetchAvatarList = () => {
    return {
        type: AVATAR_LIST_REQUEST,
    };
};

export const updateAvtarRequest = (userId: string, url: string, id: string) => {
    return {
        type: UPDATE_AVATAR_REQUEST,
        userId,
        url,
        id
    };
};

export const loginCloneUser = (data: any) => {
    const { parent_id: userId, is_parent: isParent, id: cloneId, email, password } = data;
    return {
        type: LOGIN_CLONE_REQUEST,
        userId,
        cloneId,
        isParent,
        email,
        password
    };
};

export const getPlan = () => {
    return {
        type: GET_PLAN_REQUEST
    }
}

export const getUserSubscription = (data: any) => {
    return {
        type: GET_SUBSCRIPTION_INFO_REQUEST,
        data
    }
}
