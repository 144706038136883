import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CONTENT_DETAIL, PLAN_PAGE, PLAYER, SIGN_IN } from '../../routes/routesConstant';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/reducer';

const MovieDetailMore = (props: any) => {
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { subscriptionData } = useSelector<IRootState, any>((state) => state.profileReducer);
    const navigate = useNavigate();
    return (
        <div className="movie-panel" key={`itemDetail-${props.index}`}>
            <div className="movi-poster">
                <Link to={`${CONTENT_DETAIL}?video_id=${props.item.video_id}&video_type=${props.item.video_type}`} className="movieBox">
                    <img src={props.item.portrait_art_2000x3000} alt="top-picks-1" />
                </Link>
                <div className="movishortinfo_panel">
                    <span>
                        {props.item.video_type == 1 ? <span onClick={() => {
                            if (!loginData?.token) {
                                navigate(`${SIGN_IN}`);
                                return;
                            }
                            if (subscriptionData?.subscription_status != "Active" && subscriptionData?.subscription_status != "Cancelled" && props.item.is_premium == '1') {
                                navigate(`${PLAN_PAGE}`);
                                return;
                            }
                            navigate(`${PLAYER}?video_id=${props.item?.video_id}&video_type=${props.item?.video_type}`);
                        }}>
                            <i className="fa fa fa-play" data-toggle="tooltip" data-placement="bottom" title="Play"></i>
                        </span> : null}
                    </span>
                    {props.item.is_my_list ? (<span onClick={() => { props.toggleRemoveWishlist(true, props.item.video_id) }}>
                        <i className="fa fa-check" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Remove from watchlist"></i>
                    </span>) : (<span onClick={() => {
                        if (!loginData?.token) {
                            navigate('/signin')
                            return;
                        } props.toggleWishlist(true, props.item.portrait_art_2000x3000, props.item.video_id)
                    }}>
                        <i className="fa fa-plus" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Add to watchlist"></i>
                    </span>)}
                </div>
            </div>

        </div>
    )
}

export default MovieDetailMore;