import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { IRootState } from '../../redux/reducer';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { getMembershipList, cancelMembership } from '../../redux/actions/membershipAction';

import { IHeaderinitialState } from '../../redux/reducer/headerReducer';

const MembershipView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const { planData } = useSelector<IRootState, any>((state) => state.profileReducer);

    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { membershipList } = useSelector<IRootState, any>((state) => state.membershipReducer);

    useEffect(() => {
        dispatch(setRightSideMenuActive(false));
    }, []);

    useEffect(() => {
        if (dev_base_url && loginData?.user_id && !membershipList?.length) {
            dispatch(getMembershipList({ user_id: loginData?.user_id }));
        }
    }, [loginData?.user_id, dev_base_url]);

    const cancelSubscription = () => {
        if (loginData?.user_id) {
            dispatch(cancelMembership({ user_id: loginData?.user_id }));
        }
    };

    const subscribeNow = () => {
        navigate("/subscribe");
    };

    return (<>
        <Header />
        <div className="membership_page">
            <main className="membership_main">
                <div className="container-fluid">
                    <div className="searchresult">
                        <div className="row align-items-center">
                            <div className="col-sm-8 col-6">
                                <div className="title">
                                    <span>Membership</span>
                                </div>
                            </div>

                            {membershipList?.[0]?.subscription_status == 'Cancelled' ? (<div className="col-sm-4 col-6">
                                <form action="">
                                    <div className="adduser_panel text-right">
                                        <span id="add-user" data-toggle="modal" data-target="#share-popup"
                                            data-whatever="@share-popup" className="btn common-btn disabled" >Membership Cancelled</span>
                                    </div>
                                </form>
                            </div>) : null}

                            {membershipList?.[0]?.subscription_status == 'Active' ? (<div className="col-sm-4 col-6">
                                <form action="">
                                    <div className="adduser_panel text-right">
                                        <span id="add-user" data-toggle="modal" data-target="#share-popup"
                                            data-whatever="@share-popup" className="btn common-btn" onClick={cancelSubscription}>Cancel Subscription</span>
                                    </div>
                                </form>
                            </div>) : null}

                            {membershipList?.[0]?.subscription_status == 'Inactive' || !membershipList?.length ? (<div className="col-sm-4 col-6">
                                <form action="">
                                    <div className="adduser_panel text-right">
                                        <span id="add-user" data-toggle="modal" data-target="#share-popup"
                                            data-whatever="@share-popup" className="btn common-btn" onClick={subscribeNow}>Subscribe</span>
                                    </div>
                                </form>
                            </div>) : null}
                        </div>
                    </div>

                    <div className="membership_grid">

                        {loginData && membershipList.length > 0 ? membershipList?.map((item: any) => {
                            return (<div className="membership_group" key={item?.id}>
                                <div className="membership_infopanel media">
                                    <div className="media-body">
                                        <ul className="memberinfo-list">
                                            <li className="d-flex">
                                                <span> <strong>Current Plan: {item?.plan_name}</strong></span>
                                            </li>
                                            <li className="d-flex">
                                                <span> <strong>Price: ${item?.plan_price}/{item?.plan_type}</strong></span>
                                            </li>
                                            <li className="d-flex">
                                                <span> <strong>Subscription Status: {item?.subscription_status}</strong></span>
                                            </li>
                                            <li className="d-flex">
                                                <span> <strong>Next Billing Date: {new Date(item?.to_date).toDateString()}</strong></span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }) : (<div className="membership_group">
                            <div className="membership_infopanel media">
                                <div className="media-body">
                                    <ul className="memberinfo-list">
                                        <li className="d-flex">
                                            <span> <strong>Current Plan: Free With Ads</strong></span>
                                        </li>
                                        <li className="d-flex">
                                            <span> <strong>Price: NA</strong></span>
                                        </li>
                                        <li className="d-flex">
                                            <span> <strong>Subscription Status: NA</strong></span>
                                        </li>
                                        <li className="d-flex">
                                            <span> <strong>Next Billing Date: NA</strong></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>)}
                    </div>
                    <div className="plandetail_grid">
                        <h3>Plan Details</h3>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="plandetail_text" dangerouslySetInnerHTML={{ __html: (planData?.[0]?.plan_text || '') }}>
                                    {/* <p><strong>Premium</strong> <del>${planData?.[0]?.plan_show_price}/month</del> <strong>${planData?.[0]?.plan_price}/month</strong></p> */}
                                </div>
                            </div>
                            {/* <div className="col-sm-6">
                                <div className="changeplan_panel text-right">
                                    <button type="submit" className="btn common-btn">Change</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <div className="whiteOverlay2" onClick={() => dispatch(setLeftSideMenuActive(false))} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
        <Footer />
    </>)
}

export default MembershipView;