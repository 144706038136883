import { IBanner, IHeader } from '../../model/bannerModel';
import * as type from '../types';

export interface IHeaderinitialState {
    left_active: boolean,
    right_active: boolean
}
const initialState: IHeaderinitialState = {
    left_active: false,
    right_active: false
}

export default function headerReducer(state = initialState, action: IHeader) {
    switch (action.type) {
        case type.LEFT_SIDE_MENU_ACTIVE:
            return {
                ...state,
                left_active: action.active
            }
        case type.RIGHT_SIDE_MENU_ACTIVE:
            return {
                ...state,
                right_active: action.active
            }
        default:
            return state
    }
}