import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import {
  createListRequest,
  createListFail,
  deleteListRequest,
  fetchListRequest,
} from "../../redux/actions/createListAction";
import { IRootState } from "../../redux/reducer";
import { ICreateListState } from "../../model/createList";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import { Link } from "react-router-dom";

const SocialSharePopup = (props) => {
  const { setSharePopup } = props;
  const dispatch = useDispatch();

  const {
    movieDetailData: { video_title, short_description, landscape_art_1280x720 },
  } = useSelector((state) => state.movieDetailReducer);
  let shareUrl = "";
  useEffect(() => {
    if (video_title) {
      changeMeta();
    }
  }, [video_title]);

  const changeMeta = () => {};
  const content_url = "https://www.scarenetwork.tv/browse";
  console.log(window.location);
  const obj = {
    u: "https://www.scarenetwork.tv/browse", //window.location.href,
    title: video_title,
    description: short_description,
    Thumbnail: landscape_art_1280x720,
  };
  //const shareUrl = encodeQuery(obj);
  shareUrl = `s=100&p[title]=${video_title}&p[summary]=${short_description}&p[url]=${content_url}&p[images][0]=${landscape_art_1280x720}`;
  console.log(shareUrl);
  const shareData = (e) => {
    e.stopPropagation();
    window.$(".modal-backdrop").remove();
  };
  function encodeQuery(data) {
    let query = "";
    for (let d in data)
      query += encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
    return query.slice(0, -1);
  }

  return (
    <Fragment>
      <div className="share-popup createlist_popup">
        <div
          className="modal show"
          id="createlist-popup"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="createlist-popupLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setSharePopup(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="modal-body">
                <div className="share-cont">
                  <div className="shareimgtitle_group">
                    <div className="shareimg_panel">
                      <div className="share-media">
                        <img
                          src={landscape_art_1280x720}
                          alt="landscape_art_1280x720"
                        />
                      </div>
                    </div>
                    <div className="sharetitleform_panel">
                      <div className="share_text">
                        <strong className="sharemovi-name">
                          {video_title}
                        </strong>
                      </div>
                      <div className="copylink_form">
                        <form>
                          {/* <div className="form-group">
                            <div className="input-group">
                              <input
                                type="url"
                                className="form-control text-center"
                                placeholder=""
                              />
                            </div>
                          </div> */}
                          {/* <div className="form-group m-0">
                            <button type="button" className="btn common-btn">
                              Copy Link
                            </button>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="social_panel">
                    <span>Share a link</span>
                    <ul className="social_list">
                      <li>
                        {/* <a
                          onClick={() => {
                            window.open(
                              `https://www.facebook.com/sharer.php?${shareUrl}`,
                              "_blank",
                              "width=300,height=250"
                            );
                          }}
                        >
                          <i className="fa fa-envelope"></i>
                        </a> */}
                        <EmailShareButton url={shareUrl} title={video_title}>
                          <EmailIcon round={true} />
                        </EmailShareButton>
                      </li>
                      <li>
                        {/* <a href="#">
                          <i className="fa fa-comment"></i>
                        </a> */}
                        <TwitterShareButton url={shareUrl} title={video_title}>
                          <TwitterIcon round={true} />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <FacebookShareButton url={shareUrl} title={video_title}>
                          <FacebookIcon round={true} />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <RedditShareButton url={shareUrl} title={video_title}>
                          <RedditIcon round={true} />
                        </RedditShareButton>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="fa fa-comments"></i>
                        </a>
                      </li> */}
                      <li>
                        <WhatsappShareButton>
                          <WhatsappIcon round={false} />
                        </WhatsappShareButton>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li> */}
                      <li>
                        {/* <a href="#">
                          <i className="fa fa-skype"></i>
                        </a> */}
                        <LinkedinShareButton>
                          <LinkedinIcon />
                        </LinkedinShareButton>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li> */}
                      <li>
                        {/* <a href="#">
                          <i className="fa fa-instagram"></i>
                        </a> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <title>{video_title}</title>
        <meta name="description" content={short_description} />
        <meta name="keywords" content={video_title} />
      </div>
    </Fragment>
  );
};

export default SocialSharePopup;
