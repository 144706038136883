import { useEffect } from 'react';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceListRequest } from '../../redux/actions/deviceAction';
import { IRootState } from '../../redux/reducer';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';
import Loader from '../../Component/Loader';
import { useNavigate } from 'react-router';

const DeviceView = () => {
    const { deviceList } = useSelector<IRootState, any>((state) => state.deviceReducer);
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    if (!loginData?.user_id) {
        navigate("/signin");
    }
    useEffect(() => {
        dispatch(setRightSideMenuActive(false));
        if (dev_base_url) {
            dispatch(getDeviceListRequest({ user_id: loginData?.user_id }));
        }
    }, [dev_base_url, live_base_url]);

    return (<>
        <Header />
        <div className="devices_page">
            <main className="devices_main">
                <div className="container-fluid">
                    <div className="otherdevices_grid">
                        <div className="addmembership_heading">
                            <div className="selectedrslts">
                                <strong>Devices</strong>
                            </div>
                        </div>
                        <div className="devices_grid" >
                            {deviceList?.map((item: any) => {
                                return (
                                    <div className="device_group" key={item.id}>
                                        <div className="device_infopanel media">
                                            <div className="media-left">
                                                <div className="device-img">
                                                    <img src={item.device_image} alt={item.device} />
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div className="devices_text">
                                                    <strong>{item.device}</strong>
                                                    <p><span className="deviceuse_time">{item.active_status}</span> <span className="delete_device">X</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </main>
        </div>
        <div className="whiteOverlay2" onClick={() => dispatch(setLeftSideMenuActive(false))} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
        <Footer />
    </>)
}

export default DeviceView;