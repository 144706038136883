const $ = window.$;

/* banner Slider Function Start*/
function bannerslide() {
  if ($(".bannerslide-list.owl-carousel").length) {
    $(".bannerslide-list.owl-carousel").owlCarousel({
      loop: true,
      autoplay: true,
      items: 1,
      nav: false,
      dots: true,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
      autoplayTimeout: 5500,
      autoplayHoverPause: true,
      autoplaySpeed: 1500,
    });
  }
}
/* banner Slider Function End*/
//fixed header on scroll js Start

//fixed header on scroll js End

function serviceslider() {
  if ($(".portraitlarge_slider.owl-carousel").length) {
    $(".portraitlarge_slider.owl-carousel").owlCarousel({
      loop: false,

      margin: 0,

      nav: true,

      dots: false,

      autoplay: false,

      navText: [
        '<i class="fa fa-angle-left"></i>',

        '<i class="fa fa-angle-right"></i>',
      ],

      smartSpeed: 1000,

      autoplayHoverPause: true,

      responsive: {
        0: {
          nav: false,
          items: 3,
        },

        576: {
          items: 3,
        },

        600: {
          items: 3,
          nav: false,
        },
        768: {
          items: 3,
        },

        1000: {
          items: 3,
        },

        1200: {
          items: 5,
        },

        1920: {
          items: 6,
        },

        2560: {
          items: 8,
        },
      },
    });
  }
}

function newarrivalslider() {
  if ($(".portraitsmall_slider.owl-carousel").length) {
    $(".portraitsmall_slider.owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 3,
          nav: false,
        },

        480: {
          nav: false,
          items: 3,
        },

        600: {
          nav: false,
          items: 3,
        },
        768: {
          items: 4,
        },

        1000: {
          items: 4,
        },

        1200: {
          items: 6,
        },

        1920: {
          items: 8,
        },
        2560: {
          items: 10,
        },
      },
    });
  }
}

function browseallsection() {
  if ($(".landscapesmall_slider.owl-carousel").length) {
    $(".landscapesmall_slider.owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
          autoplay: false,
        },

        480: {
          nav: false,
          items: 2,
          autoplay: false,
        },

        600: {
          nav: false,
          autoplay: false,
          items: 2,
          autoplay: false,
        },
        768: {
          items: 2,
        },

        1000: {
          items: 3,
        },

        1200: {
          items: 4,
        },

        1920: {
          items: 4,
        },
        2560: {
          items: 5,
        },
      },
    });
  }
}

function commingslider() {
  if ($(".comming-slider.owl-carousel").length) {
    $(".comming-slider.owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },

        480: {
          items: 2,
          nav: false,
        },

        600: {
          items: 2,
          nav: false,
        },
        768: {
          items: 3,
        },

        1000: {
          items: 4,
        },

        1200: {
          items: 4,
        },
        2000: {
          items: 5,
        },
      },
    });
  }
}

function trendingslider() {
  if ($(".landscapelarge_slider.owl-carousel").length) {
    $(".landscapelarge_slider.owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },

        480: {
          items: 2,
          nav: false,
        },

        600: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          margin: 15,
        },

        1000: {
          items: 4,
        },

        1200: {
          items: 2,
          margin: 15,
        },
      },
    });
  }
}

function categoryslider() {
  if ($(".category_slider.owl-carousel").length) {
    $(".category_slider.owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      stagePadding: 60,

      autoplay: false,

      navText: [
        '<i class="fa fa-angle-left"></i>',

        '<i class="fa fa-angle-right"></i>',
      ],

      smartSpeed: 1000,

      autoplayHoverPause: true,

      responsive: {
        0: {
          items: 2,
        },

        480: {
          items: 3,
        },

        600: {
          items: 4,
        },
        768: {
          items: 7,
        },

        1000: {
          touchDrag: false,
          mouseDrag: false,
          items: 8,
        },

        1200: {
          items: 10,
        },

        1920: {
          items: 12,
        },
      },
    });
  }
}

function seasons() {
  if ($(".seasonsname_slider.owl-carousel").length) {
    $(".seasonsname_slider.owl-carousel").owlCarousel({
      loop: false,

      margin: 0,

      nav: true,

      dots: false,

      autoplay: false,

      navText: [
        '<i class="fa fa-angle-left"></i>',

        '<i class="fa fa-angle-right"></i>',
      ],

      smartSpeed: 1000,

      autoplayHoverPause: true,

      responsive: {
        0: {
          items: 5,
        },

        480: {
          items: 3,
        },

        600: {
          items: 4,
        },
        768: {
          items: 7,
        },

        1000: {
          touchDrag: false,
          mouseDrag: false,
          items: 4,
        },

        1200: {
          items: 12,
        },

        1920: {
          items: 5,
        },
      },
    });
  }
}

function morelikethislider() {
  if ($(".morelikethis-slider.owl-carousel").length) {
    $(".morelikethis-slider.owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
        },

        480: {
          items: 2,
        },

        600: {
          items: 2,
        },
        768: {
          items: 2,
        },

        1000: {
          items: 3,
        },

        1200: {
          items: 6,
        },

        1920: {
          items: 6,
        },
      },
    });
  }
}

function avatarimg() {
  if ($(".avatarimg_slider.owl-carousel").length) {
    $(".avatarimg_slider.owl-carousel").owlCarousel({
      loop: false,

      margin: 15,

      nav: true,

      dots: false,

      autoplay: false,

      navText: [
        '<i class="fa fa-angle-left"></i>',

        '<i class="fa fa-angle-right"></i>',
      ],

      smartSpeed: 1000,

      autoplayHoverPause: true,

      responsive: {
        0: {
          items: 3,
        },

        480: {
          items: 3,
        },

        600: {
          items: 4,
        },
        768: {
          items: 7,
        },

        1000: {
          touchDrag: false,
          mouseDrag: false,
          items: 4,
        },

        1200: {
          items: 9,
        },

        1920: {
          items: 9,
        },
      },
    });
  }
}

function filmmatterslider() {
  if ($(".heroimage_slider.owl-carousel").length) {
    $(".heroimage_slider.owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          autoplay: false,
        },

        480: {
          items: 1,
          nav: false,
        },

        600: {
          items: 1,
          nav: false,
        },
        768: {
          items: 1,
          margin: 15,
        },

        1000: {
          items: 1,
        },

        1200: {
          items: 1,
          margin: 15,
        },
      },
    });
  }
}

function importantitemslider() {
  if ($(".importantitem_slider.owl-carousel").length) {
    $(".importantitem_slider.owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          autoplay: false,
        },

        480: {
          items: 1,
          nav: false,
        },

        600: {
          items: 1,
          nav: false,
        },
        768: {
          items: 1,
          margin: 15,
        },

        1000: {
          items: 1,
        },

        1200: {
          items: 1,
          margin: 15,
        },
      },
    });
  }
}

function lastcallslider() {
  if ($(".lastcall-slider.owl-carousel").length) {
    $(".lastcall-slider.owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      autoplay: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
        },

        480: {
          items: 1,
          nav: false,
          dots: true,
        },

        600: {
          items: 2,
          nav: false,
          dots: true,
        },
        768: {
          items: 2,
          margin: 15,
        },

        1000: {
          items: 4,
        },

        1200: {
          items: 2,
          margin: 15,
        },
      },
    });
  }
}

function categorysearch() {
  if ($(".categorysearch-slider.owl-carousel").length) {
    $(".categorysearch-slider.owl-carousel").owlCarousel({
      loop: false,
      margin: 15,
      nav: true,
      dots: false,

      autoplay: false,

      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],

      smartSpeed: 1000,

      autoplayHoverPause: true,

      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },

        576: {
          items: 2,
        },

        600: {
          items: 2,
        },
        768: {
          items: 3,
        },

        1000: {
          items: 3,
        },

        1200: {
          items: 5,
        },

        1920: {
          items: 6,
        },

        2560: {
          items: 8,
        },
      },
    });
  }
}

function savewishlist() {
  var wishlistdata = document.getElementById("addwishlist-name").value;
  var x = document.getElementById("slctadded-wishlistvalue");
  var option = document.createElement("option");
  option.text = wishlistdata;
  x.add(option);
  document.getElementById("addwishlist-name").style.display = "none";
  document.getElementById("slctadded-wishlistvalue").style.display = "block";
}

$(document).ready(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 150) {
      $("#scroll").fadeIn();
    } else {
      $("#scroll").fadeOut();
    }
  });
});

export {
  bannerslide,
  newarrivalslider,
  serviceslider,
  savewishlist,
  lastcallslider,
  importantitemslider,
  filmmatterslider,
  avatarimg,
  morelikethislider,
  seasons,
  trendingslider,
  categoryslider,
  browseallsection,
  commingslider,
  categorysearch,
};
