import { useDispatch, useSelector } from 'react-redux';
import share from '../../assets/img/share/share-1.jpg';
import { IRootState } from '../../redux/reducer';
import { ICreateListState, IWatchListData } from '../../model/createList';
import { useEffect, useState } from 'react';
import CreateListPopUp from './createListPopup';
import { toggleToastBool } from '../../redux/actions/createListAction';

const WishListPopup = (props: any) => {
    const { watchListData, image, createVideoListBool, message } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const [watchListId, setWatchListId] = useState('');
    const [createList, setCreateList] = useState(false);
    const dispatch = useDispatch();
    useEffect(()=>{
        return () =>{
            props.toggleWishlist(false)
        }
    },[])

    return (<>
        {createList ? <CreateListPopUp setCreateList={setCreateList} /> :
            <div className="share-popup wishlist_popup">
                <div className="modal show" id="wishlist-popup" tabIndex={-1} role="dialog" aria-labelledby="wishlist-popupLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.toggleWishlist(false)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <div className="modal-body">
                                <div className="share-cont">
                                    <div className="shareimgtitle_group">
                                        <div className="shareimg_panel">
                                            <div className="share-media">
                                                <img src={image} />
                                            </div>
                                        </div>
                                        <div className="savedsearch_form">
                                            <div className="share_text slctwishlist_text">
                                                <strong className="sharemovi-name">Select your wishlist</strong>
                                                <a href="javascript:void(0)" onClick={() => setCreateList(true)} className="createlst_text"><i className="fa fa-plus"></i><span>Create
                                                    List</span></a>
                                            </div>
                                            <div className="copylink_form">
                                                <form>
                                                    <div className="form-group">
                                                        <select name="cars" className="custom-select" onChange={(e) => { setWatchListId(e.target.value) }}>
                                                            <option selected={true}>Select</option>
                                                            {watchListData?.length ? watchListData.map((item: IWatchListData) => <option key={item.list_name} value={item.id}>{item.list_name}</option>) : <></>}
                                                        </select>
                                                        <div className="mt-3">
                                                            <button type="button" className="btn common-btn" onClick={() => { props.saveVideoList(watchListId); props.toggleWishlist(false); window.$('.modal-backdrop').remove(); document.body.classList.remove('modal-open'); }}>Save</button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
}

export default WishListPopup;