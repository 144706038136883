import { useEffect, useState } from "react";
import { get } from "../../api";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header"

const Faq = () => {
    const [pageData, setPageData] = useState<any>(null);
    useEffect(() => {
        getPagedata();
    }, []);

    const getPagedata = async () => {
        const response = await get('/faq');
        setPageData(response?.data);
    };
    return (<>
        <Header />
        <main className="termservice_page">
            <div className="termservice_main">
                <section className="termservice-cont">
                    <div className="container-fluid">
                        <div className="innerpage_title">
                            <span className="movititle-text">
                                <span>Faq</span>
                            </span>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: (pageData?.page_content || '') }}></p>
                    </div>
                </section>
            </div>
        </main>
        <Footer />
    </>)
}

export default Faq;