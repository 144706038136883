import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordRequest } from "../../redux/actions/authAction";
import { IRootState } from '../../redux/reducer';
import { useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import logo from '../../assets/img/logo.png';

const Reset = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [passwordType, setPasswordType] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: ""
    });
    const { message } = useSelector<IRootState, any>((state) => state.authReducer);
    useEffect(() => {
        if (message) {
            toast(message);
            setFormData({
                password: "",
                confirmPassword: ""
            });
        }
    }, [message])
    const token = searchParams.get('token');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const submit = () => {
        let error;
        if (!formData.password) {
            error = "Password is empty";
        } else if (!formData.confirmPassword) {
            error = "Confirm Password is required";
        } else if (formData.password !== formData.confirmPassword) {
            error = "Password and Confirm Password are not same";
        }
        if (error) {
            setError(error);
            return;
        } else {
            setError('')
        }
        dispatch(resetPasswordRequest({ token, ...formData }, 'reset'))
    };

    return (<>

        <div className="loginrgstr-page">
            <div className="login_main">
                <div className="container-fluid h-100">
                    {/* <div className="logo text-center pb-3">
                        <a href="/"> <img src={logo} alt="" /></a>
                    </div> */}
                    <form>
                        <div className="loginrgstr_row">
                            <div className="loginrgstr_col reset-container">
                                <div className="loginrgstr_colalign">
                                    <div className="loginsignup-form">
                                        <div className="loginrgstr_cont">
                                            <h3 className="loginrgstr_toptitle">RESET PASSWORD</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input className="form-control floatLabel floatLabel"
                                                    id="password"
                                                    type={!passwordType ? "password" : "text"}
                                                    placeholder="password"
                                                    value={formData?.password}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, password: e.target.value })
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            submit()
                                                        }
                                                    }}
                                                />
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text" onClick={() => setPasswordType(!passwordType)}>{!passwordType ? 'Show' : 'Hide'}</div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input className="form-control floatLabel floatLabel"
                                                    id="confirmpassword"
                                                    type={!confirmPasswordType ? "password" : "text"}
                                                    placeholder="confirm password"
                                                    value={formData?.confirmPassword}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, confirmPassword: e.target.value })
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            submit()
                                                        }
                                                    }}
                                                />
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text" onClick={() => setConfirmPasswordType(!confirmPasswordType)}>{!confirmPasswordType ? 'Show' : 'Hide'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <span className="forgot_pass" style={{ "lineHeight": "1", "fontSize": "12px" }}>{error}</span>
                                            <button type="button" className="btn btn-block" onClick={submit}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </>)
}
export default Reset;