import { call, put, takeEvery } from "redux-saga/effects";
import { get, post } from "../../api";
import {
  GET_TOOL_REQUEST,
  GET_TOOL_SUCCESS,
  GET_TOOL_FAILURE,
  GET_MOOD_LIST_REQUEST,
  GET_TOOL_COUNT_REQUEST,
  GET_TOOL_RESULT_REQUEST,
  GET_TOOL_COUNT_SUCCESS,
  GET_TOOL_COUNT_FAILURE,
  GET_TOOL_RESULT_SUCCESS,
  GET_TOOL_RESULT_FAILURE,
  GET_MOOD_LIST_SUCCESS,
  GET_MOOD_LIST_FAILURE,
} from "../types";

async function getToolRequestApi() {
  try {
    const response = await get(`/view-the-oracle-step-one`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getMoodListRequestApi() {
  try {
    const response = await get(`/mood-list`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getToolCountRequestApi(fromYear, toYear) {
  try {
    let response = null;
    response = await post(
      `/the-oracle-step-one?from_year=${fromYear}&to_year=${toYear}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getToolResultRequestApi(fromYear, toYear, mood, moodList) {
  try {
    let response = null;
    response = await post(
      `/the-oracle-step-two?from_year=${fromYear}&to_year=${toYear}&mood=${mood}&mood_name=${moodList}`
    );
    response?.data?.genre_videos?.sort((a, b) => {
      if (a.genre_name < b.genre_name) {
        return -1;
      }
      if (a.genre_name > b.genre_name) {
        return 1;
      }
      return 0;
    });
    const data = response?.data?.genre_videos
      .map((item) => {
        return item.videos?.map((video) => {
          return {
            ...video,
            genre_class: item.genre_class,
            genre_icon: item.genre_icon,
            genre_id: item.genre_id,
            genre_name: item.genre_name,
            genre_oracle_image: item.genre_oracle_image,
            genre_shape: item.genre_shape,
          };
        });
      })
      .reduce(function (pre, cur) {
        return pre.concat(cur);
      });
    return { ...response?.data, formatedData: data };
  } catch (error) {
    throw error;
  }
}

function* getToolRequest() {
  try {
    const toolData = yield call(getToolRequestApi);
    yield put({ type: GET_TOOL_SUCCESS, toolData });
  } catch (e) {
    yield put({ type: GET_TOOL_FAILURE, message: e.message });
  }
}

function* getMoodListRequest() {
  try {
    const moodData = yield call(getMoodListRequestApi);
    yield put({ type: GET_MOOD_LIST_SUCCESS, moodData });
  } catch (e) {
    yield put({ type: GET_MOOD_LIST_FAILURE, message: e.message });
  }
}

function* getTotalCountRequest(action) {
  try {
    const totalcountResp = yield call(
      getToolCountRequestApi,
      action.fromYear,
      action.toYear
    );
    yield put({ type: GET_TOOL_COUNT_SUCCESS, totalcountResp });
  } catch (e) {
    yield put({ type: GET_TOOL_COUNT_FAILURE, message: e.message });
  }
}

function* getToolResultRequest(action) {
  try {
    const totalResultResp = yield call(
      getToolResultRequestApi,
      action.fromYear,
      action.toYear,
      action.mood,
      action.moodList
    );
    yield put({ type: GET_TOOL_RESULT_SUCCESS, totalResultResp });
  } catch (e) {
    yield put({ type: GET_TOOL_RESULT_FAILURE, message: e.message });
  }
}

export function* toolDetailSaga() {
  yield takeEvery(GET_TOOL_REQUEST, getToolRequest);
  yield takeEvery(GET_MOOD_LIST_REQUEST, getMoodListRequest);
  yield takeEvery(GET_TOOL_COUNT_REQUEST, getTotalCountRequest);
  yield takeEvery(GET_TOOL_RESULT_REQUEST, getToolResultRequest);
}

export default toolDetailSaga;
