import { IBanner, IHeader } from '../../model/bannerModel';
import * as type from '../types';

const initialState: any = {
}

export default function toolReducer(state = initialState, action: any) {
    switch (action.type) {
        case type.GET_TOOL_SUCCESS:
            return {
                ...state,
                toolData: action.toolData
            }
        case type.GET_TOOL_COUNT_SUCCESS:
            return {
                ...state,
                totalcountResp: action.totalcountResp
            }
        case type.GET_TOOL_RESULT_SUCCESS:
            return {
                ...state,
                totalResultResp: action.totalResultResp
            }  
        case type.GET_MOOD_LIST_SUCCESS:
            return {
                ...state,
                moodData: action.moodData
            }           
        default:
            return state
    }
}