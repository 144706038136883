import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { loginDataEmptyAction, signUpRequest } from "../../redux/actions/authAction";
import { IRootState } from "../../redux/reducer";
import { Link } from "react-router-dom";
import { PRIVACYPOLICY } from "../../routes/routesConstant";

const Premium = () => {
    const dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        agree: false,
        signup_type: 2
    });
    const [error, setError] = useState('');
    const { planData } = useSelector<IRootState, any>((state) => state.profileReducer);
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    useEffect(() => {
        if (!loginData?.status_code && loginData?.accessType === "signup") {
            setError(loginData?.error || 'Unable to sign up')
        } else if (loginData?.status_code && loginData?.accessType === "signup") {
            setTimeout(() => { dispatch(loginDataEmptyAction()); }, 2000);
            setFormData({
                username: "",
                email: "",
                password: "",
                confirm_password: "",
                agree: false,
                signup_type: 2
            });
        }
    }, [loginData])
    const submit = () => {
        let error;
        setError('');
        if (!formData.email) {
            error = "Email is required";
            setError(error);
            return;
        }
        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email))) {
            error = 'Please enter valid email';
            setError(error);
            return;
        }

        if (!formData.password) {
            error = "Password is empty";
            setError(error);
            return;
        }
        if (!formData.confirm_password) {
            error = "Confirm Password is required";
            setError(error);
            return;
        }
        if (formData.password !== formData.confirm_password) {
            error = "Password and Confirm Password are not same";
            setError(error);
            return;
        }
        if (!formData.agree) {
            error = "Please accept the privacy policy";
            setError(error);
            return;
        }
        dispatch(signUpRequest(formData, 'signup'))
    };
    return (
        <div className="loginrgstr_col loginrgstr_coljoin">
            <div className="loginrgstr_colalign">
                <div className="loginsignup-form">
                    <div className="loginrgstr_cont">
                        <h3 className="loginrgstr_toptitle">PREMIUM ACCOUNT</h3>
                        <h2>Subscribe</h2>
                        <ul>
                            <li>Ad-free streaming</li>
                            <li>Early Releases</li>
                            <li>Saved searches</li>
                            <li>Bonus features</li>
                        </ul>
                        <span className="pricemonth_only" dangerouslySetInnerHTML={{ __html: (planData?.[0]?.plan_text || '') }}></span>

                    </div>

                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="joinusername"
                                type="text"
                                placeholder="Full Name"
                                value={formData?.username}
                                onChange={(e) => {
                                    setFormData({ ...formData, username: e.target.value })
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="joinemail"
                                type="email"
                                placeholder="Email"
                                value={formData?.email}
                                onChange={(e) => {
                                    setFormData({ ...formData, email: e.target.value })
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-group" id="show_hide_password">
                            <input className="form-control floatLabel"
                                id="joinpassword"
                                type={!passwordType ? "password" : "text"}
                                placeholder="Password"
                                value={formData?.password}
                                onChange={(e) => {
                                    setFormData({ ...formData, password: e.target.value })
                                }}
                            />
                            <div className="input-group-prepend">
                                <div className="input-group-text" onClick={() => setPasswordType(!passwordType)}>{!passwordType ? 'Show' : 'Hide'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="confirmpassword"
                                type={!confirmPasswordType ? "password" : "text"}
                                placeholder="Confirm Password"
                                value={formData?.confirm_password}
                                onChange={(e) => {
                                    setFormData({ ...formData, confirm_password: e.target.value })
                                }}
                            />
                            <div className="input-group-prepend">
                                <div className="input-group-text" onClick={() => setConfirmPasswordType(!confirmPasswordType)}>{!confirmPasswordType ? 'Show' : 'Hide'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="custom-control common-checkbox checkbox custom-checkbox">
                            <input type="checkbox"
                                className="custom-control-input"
                                id="agree"
                                name="example1"
                                checked={formData?.agree}
                                onChange={(e) => {
                                    setFormData({ ...formData, agree: e.target.checked })
                                }}
                            />
                            <label className="custom-control-label" htmlFor="agree">
                                <span className="remember_me">I Agree with</span></label>
                            <span>
                                <Link className="forgot_pass px-1" to={PRIVACYPOLICY}>privacy</Link>
                                and
                                <Link className="forgot_pass px-1" to={PRIVACYPOLICY} >policy</Link>
                            </span>
                        </div>
                    </div>

                    <div className="form-group pt-3">
                        <span className="forgot_pass" style={{ "lineHeight": "1", "fontSize": "12px" }}>{error}</span>
                        <button type="button" className="btn btn-block" onClick={submit}>Join</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Premium;