import { MEMBERSHIP_LIST_REQUEST, MEMBERSHIP_CANCEL_REQUEST } from "../types";

export const getMembershipList = (data: any) => {
    return {
        type: MEMBERSHIP_LIST_REQUEST,
        data
    };
};

export const cancelMembership = (data: any) => {
    return {
        type: MEMBERSHIP_CANCEL_REQUEST,
        data
    };
};
