import {  GET_TOOL_REQUEST, GET_MOOD_LIST_REQUEST, GET_TOOL_COUNT_REQUEST, GET_TOOL_RESULT_REQUEST } from "../types";

export const getToolRequest = () => {
    return {
        type: GET_TOOL_REQUEST
    };
};

export const getMoodList = () => {
    return {
        type: GET_MOOD_LIST_REQUEST
    };
};

export const getToolCountRequest = (fromYear:string,toYear:string) => {
    return {
        type: GET_TOOL_COUNT_REQUEST,
        fromYear,
        toYear
    };
};

export const getToolResultRequest = (fromYear:string,toYear:string,mood:string,mood_name:string) => {
    return {
        type: GET_TOOL_RESULT_REQUEST,
        fromYear,
        toYear,
        mood,
        mood_name
    };
};

