import { GET_SUGGESTED_VIDEOS_REQUEST, SUGGESTED_VIDEOS_EMPTY, SUGGESTED_VIDEOS_SUCCESS, SUGGESTED_VIDEOS_FAIL } from "../types";

interface IinitialState {
  error: any,
  suggestedVideos: any,
  listCount: any
}
const initialState: IinitialState = {
  error: null,
  suggestedVideos: [],
  listCount: 0
}

export default function suggestedVideoReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_SUGGESTED_VIDEOS_REQUEST:
      return {
        ...state
      }
    case SUGGESTED_VIDEOS_FAIL:
      return {
        ...state,
        error: action.error
      }
    case SUGGESTED_VIDEOS_SUCCESS:
      return {
        ...state,
        suggestedVideos: [...state.suggestedVideos, ...action.suggestedVideos.data],
        listCount: action.suggestedVideos.count
      }
    case SUGGESTED_VIDEOS_EMPTY:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}