import { AD_REQUEST, AD_SUCCESS, AD_FAILURE } from "../types";

interface IinitialState {
  adData: any
}
const initialState: IinitialState = {
  adData: null
}

export default function adReducer(state = initialState, action: any) {
  switch (action.type) {
    case AD_REQUEST:
      return {
        ...state
      }
    case AD_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case AD_SUCCESS:
      return {
        ...state,
        adData: action.data
      }
    default:
      return state
  }
}