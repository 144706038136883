import { useState } from "react";
import Avtar from '../../assets/img/add-avatar.png';

const AddProfilePopUp = ({addCloneUser,setProfilePopupBool}:any) =>{
    const [name,setName]=useState('')
    return(<div className="share-popup addprofile_popup">
    <div className="modal fade" id="addprofile-popup" tabIndex={-1} role="dialog" aria-labelledby="addprofile-popupLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" onClick={()=>{setProfilePopupBool(false);document.body.classList.remove('modal-open'); window.$('.modal-backdrop').remove();}}>×</span>
                </button>
                <div className="modal-body">
                    <div className="share-cont addnewprofile_conts">
                        <div className="shareimgtitle_group">
                            <div className="addnewuser_imgpanel">
                                <div className="commonicon-uploadbtn">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="adduserCustomFile" required={true}
                                         onChange={(e:any)=>setName(e.target.value)}/>
                                        <label className="custom-file-label border-2" htmlFor="adduserCustomFile">
                                            <span><img src={Avtar} id="addnew_user"
                                                    alt="add-avatar" /></span>
                                        </label>
                                        <div className="invalid-feedback">Example invalid custom file feedback
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="addnewuser_form">
                                <div className="share_text">
                                    <strong className="sharemovi-name">Add Profile</strong>
                                </div>
                                <div className="copylink_form">
                                    <form>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input type="url" className="form-control"
                                                    placeholder="Add a profile for another person watching Darkroom"  onChange={(e:any)=>setName(e.target.value)}/>
                                                <div className="input-group-append">
                                                    <button type="button" onClick={()=>addCloneUser(name)} className="btn common-btn">Add User</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>)
}

export default AddProfilePopUp;