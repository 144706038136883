import { IAuth } from '../../model/authModel';
import { GET_AUTH_FAILURE, GET_AUTH_SUCCESS, GET_LOGIN_SUCCESS, GET_LOGIN_FAILURE, LOGOUT_SUCCESS, GET_FORGET_SUCCESS, TOGGLE_LOGOUT_STATUS, RESET_PASSWORD_SUCCESS, LOGIN_DATA_EMPTY } from '../types';
import { setItem } from "../../utils/storage";
import { KEYS } from "../../utils/constant";

import { fetchUserDetail } from "../../utils/utils";

let userDetails: any = fetchUserDetail();

interface IinitialState {
  loginData: any,
}
const initialState: IinitialState = {
  loginData: userDetails,
}

export default function authReducer(state = initialState, action: IAuth) {
  switch (action.type) {
    case GET_AUTH_SUCCESS:
      if (action.data?.bearer_token) {
        setItem(KEYS.USER_DETAILS, JSON.stringify(action.data));
      }
      return {
        ...state,
        loginData: action.data,
        logout: false
      }
    case GET_AUTH_FAILURE:
      return {
        ...state,
        loginData: null
      }
    case GET_LOGIN_SUCCESS:
      let error: any = '';
      if (action.data?.accessType === "signup" && typeof action.data?.message === 'object') {
        Object.keys(action.data?.message).map((item: any) => {
          error = error.concat(action.data.message[item])
        });
      } else if (action.data?.error) {
        error = action.data?.error || 'Unauthenticated';
      }
      if (action.data?.token && action.data?.accessType !== "signup") {
        setItem(KEYS.USER_DETAILS, JSON.stringify(action.data));
      }
      return {
        ...state,
        loginData: !action.data?.token && !action.data?.status_code ? { error, accessType: action.data?.accessType } : action.data,
        logout: !action.data?.token && !action.data?.status_code ? true : false,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action?.data?.message
      }
    case GET_LOGIN_FAILURE:
      return {
        ...state,
        loginFailure: true
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logout: action?.data?.status,
        loginData: null
      }
    case GET_FORGET_SUCCESS:
      return {
        ...state,
        forgetData: action.data
      }
    case TOGGLE_LOGOUT_STATUS:
      return {
        ...state,
        logout: false,
      }
    case LOGIN_DATA_EMPTY:
      return {
        ...state,
        loginData: null,
        logout: false,
        status_code: 0,
        message: ''
      }
    default:
      return state
  }
}