import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyCloneData, fetchCloneUser, loginCloneUser } from "../../redux/actions/profileAction";
import { IRootState } from "../../redux/reducer";
import { useNavigate } from "react-router";
import { ADDPROFILE } from "../../routes/routesConstant";
import avtarImg from '../../assets/img/add-avatar.png';

const UserList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef();
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { loginData: { user_id, token } } = useSelector<IRootState, any>((state) => state.authReducer);
    const { cloneUserList } = useSelector<IRootState, any>((state) => state.profileReducer);
    const usePrevious = (value: any) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    };
    const prevToken = usePrevious(token);

    useEffect(() => {
        userRef.current = user_id;
        if (dev_base_url && user_id)
            dispatch(fetchCloneUser(user_id))
        return () => {
            dispatch(emptyCloneData())
        }
    }, [dev_base_url]);

    useEffect(() => {
        if (prevToken && prevToken !== token) {
            navigate(`/`)
        }
    }, [token]);

    return (<div className="addeduser_page">
        <main className="addeduser_main">
            <div className="container">
                <div className="title justify-content-center">
                    <span>Who's Watching?</span>
                </div>
                <div className="addeduser_grid">
                    {cloneUserList?.length && cloneUserList.map((item: any) => <div className="addeduser_group" style={{ "marginBottom": "20px" }} key={item.user_id}>
                        <div className="addeduser_panel">
                            <div className="addeduser-img" onClick={() => dispatch(loginCloneUser(item))}>
                                <div className="addeduser-imgthumb">
                                    <img src={item.profile_image || avtarImg} id="added-user" alt="added-user" style={{ border: "0" }} />
                                </div>
                                <div className="addeduser_content">
                                    <h3>{item.name}</h3>
                                </div>
                            </div>
                        </div>
                    </div>)}
                    {cloneUserList?.length < 4 ? (
                        <div className="addeduser_group">
                            <div className="addeduser_panel">
                                <div className="addeduser-img" onClick={() => navigate(`${ADDPROFILE}`)}>
                                    <div className="addnewuser-panel">
                                        <i className="fa fa-plus-circle"></i>
                                    </div>
                                    <div className="addeduser_content">
                                        <h3>Add Profile</h3>
                                    </div>
                                </div>
                            </div>
                        </div>) : null}
                </div>
            </div>
        </main>
    </div>)
}

export default UserList;