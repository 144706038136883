import { PLAYBACK_DATA_REQUEST, PLAYBACK_DATA_FAILURE, PLAYBACK_DATA_SUCCESS, PLAYBACK_DATA_EMPTY } from "../types";

interface IinitialState {
  error: any
  total_time: number,
  play_time: number,
  left_time: number,
  play_url: string,
  trailer_url: string,
  video_type: number,
  video_id: any,
  seasion_id: string,
  episode_id: string,
  video_title: string,
  episode_title: string,
  allow_kids: number,
  is_premium: number,
  short_description: string,
  video_subtitles: any,
  next_video: any,
  prev_video: any
}
const initialState: IinitialState = {
  error: null,
  total_time: 0,
  play_time: 0,
  left_time: 0,
  play_url: "",
  trailer_url: "",
  video_type: 0,
  video_id: 0,
  seasion_id: "",
  episode_id: "",
  video_title: "",
  episode_title: "",
  allow_kids: 0,
  is_premium: 0,
  short_description: "",
  video_subtitles: [],
  next_video: null,
  prev_video: null
}

export default function videoReducer(state = initialState, action: any) {
  switch (action.type) {
    case PLAYBACK_DATA_REQUEST:
      return {
        ...state
      }
    case PLAYBACK_DATA_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case PLAYBACK_DATA_SUCCESS:
      return {
        ...state,
        ...action.playbackData
      }
    case PLAYBACK_DATA_EMPTY:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}