import React, { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { createVideoListRequest, openRemoveWishlistRequest, openWishlistRequest, removeVideoListRequest, toggleToastBool } from '../../redux/actions/createListAction';
import { IRootState } from '../../redux/reducer';
import { ICreateListState } from '../../model/createList';
import WishListPopup from '../../Component/Popup/wishlistPopup';
import RemoveWishlistPopup from '../../Component/Popup/removeWishlistPopup';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';
import Loader from '../../Component/Loader';
import { CONTENT_DETAIL, PLAYER, SIGN_IN } from '../../routes/routesConstant';
import dardroomSelected from "../../assets/img/darkroom-select.png";
import mostViewed from "../../assets/img/most-viewed.png";
import mostShared from "../../assets/img/most-shared.png";
import trendingImg from "../../assets/img/linked-img.png";
import LoginAlertPopup from '../../Component/Popup/loginAlertPopup';
import { MESSAGE } from '../../utils/constant';
import { filmMattersRequest, mostSharedRequest, mostViewedRequest, remasteredVideoRequest, trendingRequest } from '../../redux/actions/homeAction';

const StillLookingContent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageData, setPageData] = useState([])
    const [count, setCount] = React.useState(0);
    const category_name: any = searchParams.get("category");
    const { isAddWishlistOpen, isRemoveWishlistOpen, video_id } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const [loginAlertBool, setLoginAlertBool] = React.useState('');

    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { loginData, logout } = useSelector<IRootState, any>((state) => state.authReducer);
    const { stillLookingListData = [], listCount } = useSelector<IRootState, any>((state) => state.homeReducer);
    // const { genreList, listCount } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const { watchListVideos, createVideoListBool, message } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const { subscriptionData } = useSelector<IRootState, any>((state) => state.profileReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (createVideoListBool) {
            toast(message);
            fetchCall();
            setCount(0);
        } else if (message) {
            toast(message);
        }
    }, [createVideoListBool, message]);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (left_active) {
            dispatch(setLeftSideMenuActive(false))
        }
        return () => {
            dispatch(toggleToastBool());
        };

    }, []);
    useEffect(() => {
        if (left_active) {
            dispatch(setLeftSideMenuActive(false))
        }
        setCount(0)
    }, [category_name]);
    useEffect(() => {
        // setPageData([]);
        if ((dev_base_url || live_base_url) && category_name) {
            fetchCall();
        }
    }, [loginData, dev_base_url, live_base_url, category_name, logout, count]);
    // useEffect(() => {
    //     if (dev_base_url || live_base_url) {
    //         fetchCall();
    //     }
    // }, [count]);
    const fetchCall = () => {
        let category = category_name?.toLowerCase()?.replaceAll(/ /g, "");
        console.log("fetchCall category======= ", category, count)
        switch (category) {
            case "trending":
                dispatch(trendingRequest(loginData?.user_id, count));
                return
            case "mostviewed":
                dispatch(mostViewedRequest(loginData?.user_id, count));
                return
            case "mostshared":
                dispatch(mostSharedRequest(loginData?.user_id, count));
                return
            case "filmsthatmatter":
                dispatch(filmMattersRequest(loginData?.user_id, count));
                return
            case "remasteredclassics":
                dispatch(remasteredVideoRequest(loginData?.user_id, count));
                return
        }
    };
    const getPaginationData = () => {
        setCount(count + 1);
    }
    const toggleRemoveWishlist = (val: boolean, video_id: number) => {
        dispatch(openRemoveWishlistRequest(val, video_id));
    }
    const toggleWishlist = (val: boolean, image: string | undefined, video_id: number) => {
        dispatch(openWishlistRequest(val, image, video_id));
        dispatch(toggleToastBool());
    }
    const saveVideoList = (watchListId: string) => {
        dispatch(createVideoListRequest(video_id, watchListId))
    }
    const removeVideoList = (video_id: number) => {
        dispatch(removeVideoListRequest(video_id));
    }


    useEffect(() => {
        if (watchListVideos?.length) {
            setPageData(watchListVideos)
        } else {
            setPageData([])
        }
    }, [watchListVideos])

    const optionChange = (e: any) => {
        const category_id = e.target.value?.split(' ')[0];
        const category_name = e.target.value?.split(' ')[1];
        navigate(`/category?category_id=${category_id}&category_name=${category_name}&type=genre&genre_type=0`);
    };
    console.log("stillLookingListData===== ", stillLookingListData, stillLookingListData?.length, listCount)

    return (<>
        {loginAlertBool ? <LoginAlertPopup setLoginAlertBool={setLoginAlertBool} loginAlertBool={loginAlertBool} message={loginAlertBool} /> : <></>}
        {isAddWishlistOpen ? <WishListPopup toggleWishlist={toggleWishlist} saveVideoList={saveVideoList} /> : <></>}
        {isRemoveWishlistOpen ? <RemoveWishlistPopup toggleRemoveWishlist={toggleRemoveWishlist} removeVideoList={removeVideoList} /> : <></>}
        <Header />
        <div className="category_page">
            <div className="category_main">
                <div className="categorysearch_main">
                    <div className="col-md-6 col-lg-7">
                        <div className="selectedrslts">
                            <strong>{category_name}</strong>
                        </div>
                    </div>
                </div>
                <section className="category_section">
                    <div className="container-fluid">
                        <div className="category_area">
                            <div className="category_grid">
                                <InfiniteScroll
                                    style={{ 'height': 'inherit', overflow: 'hidden' }}
                                    className='category-infinite'
                                    dataLength={stillLookingListData?.length}
                                    next={() => {
                                        if (stillLookingListData?.length < listCount) {
                                            getPaginationData();
                                        }
                                    }}
                                    hasMore={true}
                                    loader={stillLookingListData?.length < listCount ? <Loader type="portrate" /> : <></>}
                                >
                                    <div className="row">
                                        {stillLookingListData?.length && stillLookingListData.map((item: any) => <div className="col-6 col-sm-3 col-lg-2" key={item.id}>
                                            <div className="movie-panel category_imgpanel">
                                                <div className="movi-poster">
                                                    <Link to={`${CONTENT_DETAIL}?video_id=${item.video_id}&video_type=${item.video_type}`} className="movieBox">
                                                        <img src={item['portrait_art_2000x3000']} alt="" />
                                                    </Link>
                                                    <div className="movishortinfo_panel">
                                                        <span onClick={() => {
                                                            if (!loginData?.token) {
                                                                navigate(`${SIGN_IN}`);
                                                                return;
                                                            }
                                                            if (subscriptionData?.subscription_status != "Active" && subscriptionData?.subscription_status != "Cancelled" && item.is_premium == '1') {
                                                                navigate(`${PLAYER}`);
                                                                return;
                                                            }
                                                        }}>
                                                            <i className="fa fa fa-play" data-toggle="tooltip" data-placement="bottom" title="Play"></i>
                                                        </span>
                                                        {item.is_my_list ? (<span onClick={() => { toggleRemoveWishlist(true, item.video_id) }}>
                                                            <i className="fa fa-check" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Remove from watchlist"></i>
                                                        </span>) : (<span onClick={() => {
                                                            if (!loginData?.token) {
                                                                setLoginAlertBool(MESSAGE.LOGIN_TO_ADD_TO_WISHLIST)
                                                                return;
                                                            } toggleWishlist(true, item.portrait_art_2000x3000, item.video_id)
                                                        }}>
                                                            <i className="fa fa-plus" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Add to watchlist"></i>
                                                        </span>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </div>
        <div className="whiteOverlay2" onClick={() => dispatch(setLeftSideMenuActive(false))} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>

        <Footer />
    </>)
}

export default StillLookingContent;