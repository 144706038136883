import { UPDATE_USER_HISTORY, GET_USER_HISTORY_REQUEST, USER_HISTORY_EMPTY } from "../types";

export const getUserHistoryRequest = (data: any) => {
    return {
        type: GET_USER_HISTORY_REQUEST,
        data
    };
};

export const updateUserHistory = (data: any) => {
    return {
        type: UPDATE_USER_HISTORY,
        data
    };
};

export const emptyUserHistory = () => {
    return {
        type: USER_HISTORY_EMPTY
    };
};
