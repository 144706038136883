import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IRootState } from '../../redux/reducer';
import Header from '../../Component/Header';
import { post } from "../../api";
import Footer from '../../Component/Footer';
import { SIGN_IN } from '../../routes/routesConstant';
import { getMembershipList } from '../../redux/actions/membershipAction';
const ActivateTV = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { loginData, logout } = useSelector<IRootState, any>((state) => state.authReducer);
    const [inputValues, setInputValues] = useState({
        first: '',
        second: '',
        third: '',
        fourth: '',
    });
    const [enableButton, setEnableButton] = useState(false);
    const [activationResult, setActivationResult] = useState({ status_code: 0, message: "" });
    const { membershipList } = useSelector<IRootState, any>((state) => state.membershipReducer);
    useEffect(() => {
        if (!loginData?.user_id) {
            navigate(`${SIGN_IN}`);
        }
    }, [])
    useEffect(() => {
        if (dev_base_url && loginData?.user_id && !membershipList?.length) {
            dispatch(getMembershipList({ user_id: loginData?.user_id }));
        }
    }, [loginData?.user_id, dev_base_url]);

    const activateTV = async (e: any) => {
        if (!enableButton) {
            return;
        }
        if (!inputValues['first'] || !inputValues['second'] || !inputValues['third'] || !inputValues['fourth']) {
            return;
        }
        const code = `${inputValues['first']}${inputValues['second']}${inputValues['third']}${inputValues['fourth']}`;
        let url = `/insert-tv-code?tv_code=${code}&user_id=${loginData?.user_id}`;
        let response: any = post(url);
        response = await response;
        if (response?.status_code == 1) {
            setActivationResult(response);
            setEnableButton(false);
        } else {
            setActivationResult(response);
        }
    };

    const goToHome = async (e: any) => {
        navigate('/');
    };

    const keyDownHandler = (e: any) => {
        e.stopPropagation();
        const key = e.keyCode;
        switch (key) {
            case 8:
                if (e.target.value) {
                    e.target.value = '';
                } else if (e.target.previousElementSibling) {
                    e.target.previousElementSibling.select();
                }
                break;
            case 37:
                if (e.target.previousElementSibling) {
                    e.target.previousElementSibling.focus();
                }
                break;
            case 39:
                if (e.target.nextElementSibling) {
                    e.target.nextElementSibling.focus();
                }
                break;
            case 13:
                break;
        }
    };

    const onChangeHandler = (e: any) => {
        let val = e.target.value || '';
        if (val && val.length > 0) {
            val = val.charAt(val.length - 1);
        }
        setInputValues({ ...inputValues, [e.target.id]: val });
        if (e.target.value && e.target.nextElementSibling) {
            e.target.nextElementSibling.focus();
        }
        setActivationResult({ status_code: 0, message: "" });
    };

    useEffect(() => {
        if (inputValues['first'] && inputValues['second'] && inputValues['third'] && inputValues['fourth']) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
    }, [inputValues]);

    return (<>
        <Header />
        <div className="activate_page">
            <div className="activate_main">
                <div className="container-fluid h-100">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-sm-6">
                            {activationResult?.status_code == 1 ? (
                                <div className="activate_grid">
                                    <div className="activate_cont">
                                        <h3>{activationResult?.message}</h3>
                                    </div>
                                    <div className="form-group pt-3">
                                        <button type="button" className="btn btn-block activetv_btn" onClick={goToHome}>Go to Home</button>
                                    </div>
                                </div>) : (<div className="activate_grid">
                                    <div className="activate_cont">
                                        <h2>Activate TV</h2>
                                        <h3>Enter the code shown on your TV screen</h3>
                                    </div>
                                    <div className="activate_form">
                                        <form className="otp-form" name="otp-form">

                                            <div className="title">
                                                <h3>OTP VERIFICATION</h3>
                                            </div>
                                            <div className="otp-input-fields">
                                                <input type="number" className="otp__digit otp__field__1" id="first" tabIndex={0} onKeyDown={keyDownHandler} onChange={onChangeHandler} value={inputValues['first']} autoComplete="off" />
                                                <input type="number" className="otp__digit otp__field__2" id="second" tabIndex={0} onKeyDown={keyDownHandler} onChange={onChangeHandler} value={inputValues['second']} autoComplete="off" />
                                                <input type="number" className="otp__digit otp__field__3" id="third" tabIndex={0} onKeyDown={keyDownHandler} onChange={onChangeHandler} value={inputValues['third']} autoComplete="off" />
                                                <input type="number" className="otp__digit otp__field__4" id="fourth" tabIndex={0} onKeyDown={keyDownHandler} onChange={onChangeHandler} value={inputValues['fourth']} autoComplete="off" />
                                            </div>
                                            <div className="form-group">
                                                <h4 style={{ marginTop: '20px', color: "#ff0000" }}>{activationResult?.message}</h4>
                                                <p>You are currently loggin into account linked to</p>
                                                <h4>{loginData?.email}</h4>
                                                {membershipList?.[0]?.subscription_status == 'Active' ? (<button type="button" className="btn activesub_btn">Premium Member</button>) : null}
                                                {(membershipList?.[0]?.subscription_status == 'Inactive' || !membershipList?.length) ? (<button type="button" className="btn activesub_btn">Free Account</button>) : null}
                                                {membershipList?.[0]?.subscription_status == 'Cancelled' ? (<button type="button" className="btn activesub_btn">Membership Cancelled</button>) : null}

                                            </div>
                                            <div className="form-group pt-3">
                                                <button type="button" className={`btn btn-block activetv_btn ${!enableButton ? 'disabled' : ''}`} onClick={activateTV}>Activate TV</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>)}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
    </>)
}

export default ActivateTV;