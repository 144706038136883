import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, Link } from 'react-router-dom';
import { IRootState } from '../../redux/reducer';
import logo from '../../assets/img/logo.png';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { LeftMenu, RightMenu } from '../Menu';
import { getItem } from '../../utils/storage';
import { KEYS } from '../../utils/constant';
import { loginDataEmptyAction } from '../../redux/actions/authAction';

const Header = React.forwardRef<HTMLDivElement>((props, ref) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logoutClick, setLogoutclick] = React.useState(false);
    const { loginData, logout } = useSelector<IRootState, any>((state) => state.authReducer);
    const userData: any = getItem(KEYS.USER_DETAILS);
    const userDetail = userData && JSON.parse(userData)
    useEffect(() => {
        if (logout) {
            navigate('/');
            dispatch(loginDataEmptyAction());
        }
    }, [logout]);
    return (<>
        <header className="header-part">
            <div className="container-fluid">
                <nav className="header_main">
                    <div className="togglesidebarsearch_panel" onClick={() => dispatch(setLeftSideMenuActive(true))}>
                        <span className="header_search">
                            <i className="fa fa-search"></i>
                        </span>
                        <span className="toggle_mainpanel">
                            <div className="toggle_panel">
                                <span></span>
                            </div>
                        </span>
                    </div>
                    <div className="logo_panel">
                        <a className="navbar-brand" href="" onClick={(event) => {
                            event.preventDefault();
                            navigate("/");
                        }}>
                            <img src={logo} alt="logo" />
                        </a>
                    </div>
                    {(!loginData && !userDetail?.token) || logout ? (<div className="user_list before-login">
                        <ul className="loginregister-list">
                            <li>
                                <Link to="/signin">
                                    Sign In  <span className="lgnrgstr_line">|</span>
                                </Link>
                            </li>
                            <li><Link to="/signin">Sign Up</Link></li>
                        </ul>
                    </div>) :
                        (<div className="user_list">
                            <ul className="loginregister-list">
                                <li>
                                    <span className="usertoggle_main" onClick={() => dispatch(setRightSideMenuActive(true))}>
                                        <div className="user_toggle">
                                            <span></span>
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span className="username_text">{loginData?.username || userDetail?.username}</span>
                                </li>
                            </ul>
                        </div>)}
                </nav>
            </div>
        </header>

        <LeftMenu ref={ref} />
        {(loginData || userDetail) && !logout ? <RightMenu setLogoutclick={setLogoutclick} /> : null}
    </>)
})

export default Header;