export const GET_MENU_REQUEST = "GET_MENU_REQUEST";
export const GET_MENU_FAILURE = "GET_MENU_FAILURE";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_INITIAL_API_REQUEST = "GET_INITIAL_API_REQUEST";
export const GET_INITIAL_API_FAILURE = "GET_INITIAL_API_FAILURE";
export const GET_INITIAL_API_SUCCESS = "GET_INITIAL_API_SUCCESS";
export const STRIPE_CONFIG_REQUEST = "STRIPE_CONFIG_REQUEST";
export const STRIPE_CONFIG_FAILURE = "STRIPE_CONFIG_FAILURE";
export const STRIPE_CONFIG_SUCCESS = "STRIPE_CONFIG_SUCCESS";
export const GET_BANNER_REQUEST = "GET_BANNER_REQUESTED";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAILURE = "GET_BANNER_FAILED";
export const GET_HOME_REQUEST = "GET_HOME_REQUEST";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const GET_HOME_FAILURE = "GET_HOME_FAILURE";
export const GET_HOME_EMPTY = "GET_HOME_EMPTY";
export const GET_HOME_EMPTY_DATA = "GET_HOME_EMPTY_DATA";
export const GET_MOVIE_DETAIL_EMPTY = "GET_MOVIE_DETAIL_EMPTY";
export const GET_SERIES_DETAIL_EMPTY = "GET_SERIES_DETAIL_EMPTY";
export const GET_MOVIE_DETAIL_REQUEST = "GET_MOVIE_DETAIL_REQUESTED";
export const GET_MOVIE_DETAIL_SUCCESS = "GET_MOVIE_DETAIL_SUCCESS";
export const GET_MOVIE_DETAIL_FAILURE = "GET_MOVIE_DETAIL_FAILED";
export const GET_STILL_LOOKING_REQUEST = "GET_STILL_LOOKING_REQUEST";
export const GET_STILL_LOOKING_SUCCESS = "GET_STILL_LOOKING_SUCCESS";
export const GET_STILL_LOOKING_FAILURE = "GET_STILL_LOOKING_FAILURE";
export const GET_SERIES_DETAIL_REQUEST = "GET_SERIES_DETAIL_REQUEST";
export const GET_SERIES_DETAIL_SUCCESS = "GET_SERIES_DETAIL_SUCCESS";
export const GET_SERIES_DETAIL_FAILURE = "GET_SERIES_DETAIL_FAILURE";
export const GET_SERIES_EMPTY_DATA = "GET_SERIES_EMPTY_DATA";
export const GET_EPISODE_DETAIL_REQUEST = "GET_EPISODE_DETAIL_REQUEST";
export const GET_EPISODE_DETAIL_SUCCESS = "GET_EPISODE_DETAIL_SUCCESS";
export const GET_EPISODE_DETAIL_FAILURE = "GET_EPISODE_DETAIL_FAILURE";
export const GET_EPISODE_EMPTY_DATA = "GET_EPISODE_EMPTY_DATA";
export const LEFT_SIDE_MENU_ACTIVE = "LEFT_SIDE_MENU_ACTIVE";
export const RIGHT_SIDE_MENU_ACTIVE = "RIGHT_SIDE_MENU_ACTIVE";
export const GET_AUTH_REQUEST = "GET_AUTH_REQUEST";
export const GET_AUTH_FAILURE = "GET_AUTH_FAILURE";
export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS";
export const GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_FORGET_REQUEST = "GET_FORGET_REQUEST";
export const GET_FORGET_FAILURE = "GET_FORGET_FAILURE";
export const GET_FORGET_SUCCESS = "GET_FORGET_SUCCESS";
export const GET_SIGNUP_REQUEST = "GET_SIGNUP_REQUEST";
export const TOGGLE_LOGOUT_STATUS = "TOGGLE_LOGOUT_STATUS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CREATE_LIST_FAILURE = "CREATE_LIST_FAILURE";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const CREATE_LIST_REQUEST = "CREATE_LIST_REQUEST";
export const FETCH_LIST_REQUEST = "FETCH_LIST_REQUEST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";

export const FETCH_FRND_WATCHLIST_REQUEST = "FETCH_FRND_WATCHLIST_REQUEST";
export const FETCH_FRND_WATCHLIST_SUCCESS = "FETCH_FRND_WATCHLIST_SUCCESS";
export const FETCH_FRND_WATCHLIST_FAILURE = "FETCH_FRND_WATCHLIST_FAILURE";

export const DELETE_LIST_FAILURE = "DELETE_LIST_FAILURE";
export const DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS";
export const DELETE_LIST_REQUEST = "DELETE_LIST_REQUEST";
export const UPDATE_LIST_REQUEST = "UPDATE_LIST_REQUEST";
export const CREATE_VIDEO_LIST_REQUEST = "CREATE_VIDEO_LIST_REQUEST";
export const CREATE_VIDEO_LIST_SUCCESS = "CREATE_VIDEO_LIST_SUCCESS";
export const CREATE_VIDEO_LIST_FAILURE = "CREATE_VIDEO_LIST_FAILURE";
export const CREATE_VIDEO_LIST_TOGGLE = "CREATE_VIDEO_LIST_TOGGLE";
export const CREATE_VIDEO_LIST_TOGGLE_FALSE = "CREATE_VIDEO_LIST_TOGGLE_FALSE";
export const REMOVE_VIDEO_LIST_REQUEST = "REMOVE_VIDEO_LIST_REQUEST";
export const REMOVE_VIDEO_LIST_SUCCESS = "REMOVE_VIDEO_LIST_SUCCESS";
export const REMOVE_VIDEO_LIST_FAILURE = "REMOVE_VIDEO_LIST_FAILURE";
export const REMOVE_VIDEO_LIST_TOGGLE = "REMOVE_VIDEO_LIST_TOGGLE";

export const OPEN_WISHLIST_REQUEST = "OPEN_WISHLIST_REQUEST";
export const OPEN_WISHLIST_SUCCESS = "OPEN_WISHLIST_SUCCESS";
export const OPEN_REMOVE_WISHLIST_REQUEST = "OPEN_REMOVE_WISHLIST_REQUEST";
export const OPEN_REMOVE_WISHLIST_SUCCESS = "OPEN_REMOVE_WISHLIST_SUCCESS";

export const FETCH_VIDEO_LIST_REQUEST = "FETCH_VIDEO_LIST_REQUEST";
export const FETCH_VIDEO_LIST_SUCCESS = "FETCH_VIDEO_LIST_SUCCESS";
export const FETCH_VIDEO_LIST_FAILURE = "FETCH_VIDEO_LIST_FAILURE";

export const GENRE_LIST_REQUEST = "GENRE_LIST_REQUEST";
export const GENRE_LIST_SUCCESS = "GENRE_LIST_SUCCESS";
export const GENRE_LIST_FAILURE = "GENRE_LIST_FAILURE";
export const GENRE_LIST_EMPTY = "GENRE_LIST_EMPTY";
export const GENRE_LIST_EMPTY_DATA = "GENRE_LIST_EMPTY_DATA";

export const MAIN_MENU_LIST_REQUEST = "MAIN_MENU_LIST_REQUEST";
export const MAIN_MENU_LIST_SUCCESS = "MAIN_MENU_LIST_SUCCESS";
export const MAIN_MENU_LIST_FAILURE = "MAIN_MENU_LIST_FAILURE";

export const SEARCH_LIST_REQUEST = "SEARCH_LIST_REQUEST";
export const SEARCH_LIST_FAILURE = "SEARCH_LIST_FAILURE";
export const SEARCH_LIST_SUCCESS = "SEARCH_LIST_SUCCESS";
export const SEARCH_LIST_EMPTY = "SEARCH_LIST_EMPTY";

export const SEARCH_QUERY_SAVE_REQUEST = "SEARCH_QUERY_SAVE_REQUEST";
export const SEARCH_QUERY_SAVE_FAILURE = "SEARCH_QUERY_SAVE_FAILURE";
export const SEARCH_QUERY_SAVE_SUCCESS = "SEARCH_QUERY_SAVE_SUCCESS";
export const SEARCH_QUERY_SAVE_EMPTY = "SEARCH_QUERY_SAVE_EMPTY";

export const FETCH_SEARCH_LIST_REQUEST = "FETCH_SEARCH_LIST_REQUEST";
export const FETCH_SEARCH_LIST_SUCCESS = "FETCH_SEARCH_LIST_SUCCESS";
export const FETCH_SEARCH_LIST_FAILURE = "FETCH_SEARCH_LIST_FAILURE";
export const FETCH_SEARCH_LIST_EMPTY = "FETCH_SEARCH_LIST_EMPTY";

export const DELETE_LIST_ITEM_REQUEST = "DELET_LIST_ITEM_REQUEST";
export const DELETE_LIST_ITEM_FAILURE = "DELETE_LIST_ITEM_FAILURE";
export const DELETE_LIST_ITEM_SUCCESS = "DELETE_LIST_ITEM_SUCCESS";
export const DELETE_LIST_ITEM_EMPTY = "DELETE_LIST_ITEM_EMPTY";

export const FETCH_LIST_ITEM_REQUEST = "FETCH_LIST_ITEM_REQUEST";
export const FETCH_LIST_ITEM_FAILURE = "FETCH_LIST_ITEM_FAILURE";
export const FETCH_LIST_ITEM_SUCCESS = "FETCH_LIST_ITEM_SUCCESS";
export const FETCH_LIST_ITEM_EMPTY = "FETCH_LIST_ITEM_EMPTY";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GET_CONTINUE_WATCHING_REQUEST = "GET_CONTINUE_WATCHING_REQUEST";
export const GET_CONTINUE_WATCHING_SUCCESS = "GET_CONTINUE_WATCHING_SUCCESS";
export const GET_CONTINUE_WATCHING_FAILURE = "GET_CONTINUE_WATCHING_FAILURE";
export const GET_CONTINUE_WATCHING_EMPTY = "GET_CONTINUE_WATCHING_EMPTY";
export const GET_CONTINUE_WATCHING_EMPTY_DATA =
  "GET_CONTINUE_WATCHING_EMPTY_DATA";

export const GET_USER_WATCHING_LIST_REQUEST = "GET_USER_WATCHING_LIST_REQUEST";
export const GET_USER_WATCHING_LIST_SUCCESS = "GET_USER_WATCHING_LIST_SUCCESS";
export const GET_USER_WATCHING_LIST_FAILURE = "GET_USER_WATCHING_LIST_FAILURE";
export const GET_USER_WATCHING_LIST_EMPTY = "GET_USER_WATCHING_LIST_EMPTY";
export const GET_USER_WATCHING_LIST_EMPTY_DATA =
  "GET_USER_WATCHING_LIST_EMPTY_DATA";

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_FAILURE = "PROFILE_FAILURE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_EMPTY = "PROFILE_EMPTY";

export const AVATAR_LIST_REQUEST = "AVATAR_LIST_REQUEST";
export const AVATAR_LIST_FAILURE = "AVATAR_LIST_FAILURE";
export const AVATAR_LIST_SUCCESS = "AVATAR_LIST_SUCCESS";
export const AVATAR_LIST_EMPTY = "AVATAR_LIST_EMPTY";

export const FETCH_CLONES_REQUEST = "FETCH_CLONES_REQUEST";
export const FETCH_CLONES_FAILURE = "FETCH_CLONES_FAILURE";
export const FETCH_CLONES_SUCCESS = "FETCH_CLONES_SUCCESS";
export const FETCH_CLONES_EMPTY = "FETCH_CLONES_EMPTY";
export const FETCH_CLONES_EMPTY_DATA = "FETCH_CLONES_EMPTY_DATA";

export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_FAILURE = "UPDATE_AVATAR_FAILURE";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_EMPTY = "UPDATE_AVATAR_EMPTY";

export const GET_TOOL_REQUEST = "GET_TOOL_REQUEST";
export const GET_TOOL_FAILURE = "GET_TOOL_FAILURE";
export const GET_TOOL_SUCCESS = "GET_TOOL_SUCCESS";
export const GET_TOOL_EMPTY = "GET_TOOL_EMPTY";

export const GET_MOOD_LIST_REQUEST = "GET_MOOD_LIST_REQUEST";
export const GET_MOOD_LIST_FAILURE = "GET_MOOD_LIST_FAILURE";
export const GET_MOOD_LIST_SUCCESS = "GET_MOOD_LIST_SUCCESS";
export const GET_MOOD_LIST_EMPTY = "GET_MOOD_LIST_EMPTY";

export const GET_TOOL_COUNT_REQUEST = "GET_TOOL_COUNT_REQUEST";
export const GET_TOOL_COUNT_FAILURE = "GET_TOOL_COUNT_FAILURE";
export const GET_TOOL_COUNT_SUCCESS = "GET_TOOL_COUNT_SUCCESS";
export const GET_TOOL_COUNT_EMPTY = "GET_TOOL_COUNT_EMPTY";

export const GET_TOOL_RESULT_REQUEST = "GET_TOOL_RESULT_REQUEST";
export const GET_TOOL_RESULT_FAILURE = "GET_TOOL_RESULT_FAILURE";
export const GET_TOOL_RESULT_SUCCESS = "GET_TOOL_RESULT_SUCCESS";
export const GET_TOOL_RESULT_EMPTY = "GET_TOOL_RESULT_EMPTY";

export const LOGIN_CLONE_REQUEST = "LOGIN_CLONE_REQUEST";
export const LOGIN_CLONE_FAILURE = "LOGIN_CLONE_FAILURE";
export const LOGIN_CLONE_SUCCESS = "LOGIN_CLONE_SUCCESS";
export const LOGIN_CLONE_EMPTY = "LOGIN_CLONE_EMPTY";

export const SET_AUTH_DATA = "SET_AUTH_DATA";

export const GET_TRENDING_REQUEST = "GET_TRENDING_REQUEST";
export const GET_TRENDING_SUCCESS = "GET_TRENDING_SUCCESS";
export const GET_TRENDING_FAILURE = "GET_TRENDING_FAILURE";
export const GET_TRENDING_EMPTY = "GET_TRENDING_EMPTY";

export const MOST_VIEWED_REQUEST = "MOST_VIEWED_REQUEST";
export const MOST_VIEWED_SUCCESS = "MOST_VIEWED_SUCCESS";
export const MOST_VIEWED_FAILURE = "MOST_VIEWED_FAILURE";

export const MOST_SHARED_REQUEST = "MOST_SHARED_REQUEST";
export const MOST_SHARED_SUCCESS = "MOST_SHARED_SUCCESS";
export const MOST_SHARED_FAILURE = "MOST_SHARED_FAILURE";

export const FILM_MATTERS_REQUEST = "FILM_MATTERS_REQUEST";
export const FILM_MATTERS_SUCCESS = "FILM_MATTERS_SUCCESS";
export const FILM_MATTERS_FAILURE = "FILM_MATTERS_FAILURE";

export const REMASTERED_VIDEO_REQUEST = "REMASTERED_VIDEO_REQUEST";
export const REMASTERED_VIDEO_SUCCESS = "REMASTERED_VIDEO_SUCCESS";
export const REMASTERED_VIDEO_FAILURE = "REMASTERED_VIDEO_FAILURE";

export const GET_PLAN_REQUEST = "GET_PLAN_REQUEST";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_FAILURE = "GET_PLAN_FAILURE";

export const COMING_SOON_SLIDER_REQUEST = "COMING_SOON_SLIDER_REQUEST";
export const COMING_SOON_SLIDER_SUCCESS = "COMING_SOON_SLIDER_SUCCESS";
export const COMING_SOON_SLIDER_FAILURE = "COMING_SOON_SLIDER_FAILURE";

export const COMING_SOON_LIST_REQUEST = "COMING_SOON_LIST_REQUEST";
export const COMING_SOON_LIST_SUCCESS = "COMING_SOON_LIST_SUCCESS";
export const COMING_SOON_LIST_FAILURE = "COMING_SOON_LIST_FAILURE";

export const PLAYBACK_DATA_REQUEST = "PLAYBACK_DATA_REQUEST";
export const PLAYBACK_DATA_SUCCESS = "PLAYBACK_DATA_SUCCESS";
export const PLAYBACK_DATA_FAILURE = "PLAYBACK_DATA_FAILURE";
export const PLAYBACK_DATA_EMPTY = "PLAYBACK_DATA_EMPTY";

export const LOGIN_DATA_EMPTY = "LOGIN_DATA_EMPTY";
export const LOGIN_EMPTY = "LOGIN_EMPTY";

export const REPORT_ERROR = "REPORT_ERROR";
export const REPORT_HEART_BEAT = "REPORT_HEART_BEAT";

export const UPDATE_USER_HISTORY = "UPDATE_USER_HISTORY";
export const GET_USER_HISTORY_REQUEST = "GET_USER_HISTORY_REQUEST";
export const GET_USER_HISTORY_SUCCESS = "GET_USER_HISTORY_SUCCESS";
export const GET_USER_HISTORY_FAIL = "GET_USER_HISTORY_FAIL";
export const USER_HISTORY_EMPTY = "USER_HISTORY_EMPTY";

export const GET_SUGGESTED_VIDEOS_REQUEST = "GET_SUGGESTED_VIDEOS_REQUEST";
export const SUGGESTED_VIDEOS_SUCCESS = "SUGGESTED_VIDEOS_SUCCESS";
export const SUGGESTED_VIDEOS_FAIL = "SUGGESTED_VIDEOS_FAIL";
export const SUGGESTED_VIDEOS_EMPTY = "SUGGESTED_VIDEOS_EMPTY";

export const GET_DEVICE_LIST_REQUEST = "GET_DEVICE_LIST_REQUEST";
export const DEVICE_LIST_SUCCESS = "DEVICE_LIST_SUCCESS";
export const DEVICE_LIST_FAIL = "DEVICE_LIST_FAIL";
export const DEVICE_LIST_EMPTY = "DEVICE_LIST_EMPTY";

export const MEMBERSHIP_LIST_REQUEST = "MEMBERSHIP_LIST_REQUEST";
export const MEMBERSHIP_LIST_SUCCESS = "MEMBERSHIP_LIST_SUCCESS";
export const MEMBERSHIP_LIST_FAIL = "MEMBERSHIP_LIST_FAIL";

export const MEMBERSHIP_CANCEL_REQUEST = "MEMBERSHIP_CANCEL_REQUEST";
export const MEMBERSHIP_CANCEL_SUCCESS = "MEMBERSHIP_CANCEL_SUCCESS";
export const MEMBERSHIP_CANCEL_FAIL = "MEMBERSHIP_CANCEL_FAIL";

export const GET_QUESTION_REQUEST = "GET_QUESTION_REQUEST";
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";

export const REPORT_ERROR_REQUEST = "REPORT_ERROR_REQUEST";
export const REPORT_ERROR_FAILURE = "REPORT_ERROR_FAILURE";
export const REPORT_ERROR_SUCCESS = "REPORT_ERROR_SUCCESS";

export const GET_SUBSCRIPTION_INFO_REQUEST = "GET_SUBSCRIPTION_INFO_REQUEST";
export const GET_SUBSCRIPTION_INFO_FAILURE = "GET_SUBSCRIPTION_INFO_FAILURE";
export const GET_SUBSCRIPTION_INFO_SUCCESS = "GET_SUBSCRIPTION_INFO_SUCCESS";

export const AD_REQUEST = "AD_REQUEST";
export const AD_FAILURE = "AD_FAILURE";
export const AD_SUCCESS = "AD_SUCCESS";
