import { AVATAR_LIST_SUCCESS, FETCH_CLONES_EMPTY_DATA, FETCH_CLONES_SUCCESS, GET_PLAN_SUCCESS, LOGIN_CLONE_SUCCESS, PROFILE_SUCCESS, GET_SUBSCRIPTION_INFO_SUCCESS } from "../types";

interface IinitialState {
  subscriptionData: any,
  error: any
}
const initialState: IinitialState = {
  subscriptionData: null,
  error: null
}

export default function profileReducer(state = initialState, action: any) {
  switch (action.type) {
    case PROFILE_SUCCESS:
      return {
        ...state
      }
    case AVATAR_LIST_SUCCESS:
      return {
        ...state,
        avatarList: action.avatarList
      }
    case FETCH_CLONES_SUCCESS:
      return {
        ...state,
        cloneUserList: action.closeUserData
      }
    case LOGIN_CLONE_SUCCESS:
      return {
        ...state,
        cloneUserLoginData: action.cloneUserLoginData
      }
    case FETCH_CLONES_EMPTY_DATA:
      return {
        ...state,
        cloneUserLoginData: null
      }
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        planData: action.planData
      }
    case GET_SUBSCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        subscriptionData: Object.keys(action?.data).length > 0 ? action.data : null
      }
    default:
      return state
  }
}