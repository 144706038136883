import { all } from "redux-saga/effects";
import homeSaga from "./homeSaga";
import movieDetailSaga from "./movieDetailSaga";
import initialApiSaga from "./initialApiSaga";
import menuSaga from "./menuSaga";
import authSaga from "./authSaga";
import createListSaga from "./createListSaga";
import searchSaga from "./searchSaga";
import profileSaga from "./profileSaga";
import toolDetailSaga from "./toolSaga";
import videoSaga from "./videoSaga";
import userHistorySaga from "./userHistorySaga";
import suggestedVideoSaga from "./suggestedVideoSaga";
import deviceSaga from "./deviceSaga";
import membershipSaga from "./membershipSaga";
import reportIssueSaga from "./reportIssueSaga";
import adSaga from "./adSaga";

export default function* rootSaga() {
  yield all([
    homeSaga(),
    movieDetailSaga(),
    initialApiSaga(),
    menuSaga(),
    authSaga(),
    createListSaga(),
    searchSaga(),
    profileSaga(),
    toolDetailSaga(),
    videoSaga(),
    userHistorySaga(),
    suggestedVideoSaga(),
    deviceSaga(),
    membershipSaga(),
    reportIssueSaga(),
    adSaga(),
  ]);
}
