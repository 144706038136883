export function setItem(key, value) {
  if (window.localStorage) {
    if (value) {
      localStorage.setItem(key, value);
    }
  } else {
    console.log("localStorage not suppoted");
  }
}

export function getItem(key) {
  if (window.localStorage) {
    var value = localStorage.getItem(key);
    if (value) {
      return value;
    } else {
      return "";
    }
  } else {
    console.log("localStorage not suppoted");
  }
}

export function clearAll() {
  localStorage.clear();
}

export function removeItem(key) {
  localStorage.removeItem(key);
}
