import { IBanner } from '../../model/bannerModel';
import * as type from '../types';

interface IinitialState {
  continueWatchingDataArr: any;
  watchListDataArr: any;
  watchListHomeData: any;
  watchListData: any;
  continueWatchingData: any;
  bannerData: any,
  homeData: any,
  stillLookingData: any,
  stillLookingListData: any,
  comingSoonListData: any,
  listCount: any,
  progress: any
}
const initialState: IinitialState = {
  bannerData: [],
  homeData: [],
  stillLookingData: [],
  continueWatchingData: [{ listCount: 1 }],
  watchListData: undefined,
  watchListHomeData: undefined,
  continueWatchingDataArr: [],
  watchListDataArr: undefined,
  stillLookingListData: [],
  comingSoonListData: {},
  listCount: 0,
  progress: false
}

export default function bannerReducer(state = initialState, action: IBanner) {
  switch (action.type) {
    case type.GET_STILL_LOOKING_SUCCESS:
      state.homeData.splice(state.homeData.length - 1, 0, action.stillLookingData);
      // = action.stillLookingData;
      return {
        ...state,
        stillLookingData: action.stillLookingData,
        homeData: [...state.homeData]
      }
    case type.GET_BANNER_SUCCESS:
      return {
        ...state,
        bannerData: action.bannerData
      }
    case type.GET_BANNER_FAILURE:
      return {
        ...state,
        bannerSuccess: false
      }
    case type.GET_HOME_SUCCESS:
      if ((state.watchListHomeData?.length || state.continueWatchingData?.length) && !state.homeData?.length) {
        state.homeData = [...state.continueWatchingData, ...state.watchListHomeData, ...action.homeData?.homeListVideo];
      } else if (action.isReplace) {
        state.homeData = [...state.continueWatchingDataArr, ...state.watchListDataArr, ...action.homeData?.homeListVideo]
      }
      return {
        ...state,
        homeData: action.isReplace || state.watchListHomeData?.length || state.continueWatchingData?.length ? state.homeData : [...state.homeData, ...action.homeData?.homeListVideo],
        number_of_rows: action.homeData?.number_of_rows,
        watchListHomeData: [],
        continueWatchingData: []
      }
    case type.GET_HOME_FAILURE:
      return {
        ...state,
        homeSuccess: false
      }
    case type.GET_HOME_EMPTY_DATA:
      return {
        ...state,
        bannerData: [],
        homeData: [],
        stillLookingData: []
      }
    case type.GET_CONTINUE_WATCHING_EMPTY_DATA:
      return {
        continueWatchingData: [],
        continueWatchingDataArr: []
      }
    case type.GET_CONTINUE_WATCHING_SUCCESS:
      return {
        ...state,
        continueWatchingData: action.continueWatchingData,
        continueWatchingDataArr: action.continueWatchingData
      }
    case type.GET_USER_WATCHING_LIST_EMPTY_DATA:
      return {
        ...state,
        watchListHomeData: [],
        watchListDataArr: []
      }
    case type.GET_USER_WATCHING_LIST_SUCCESS:
      return {
        ...state,
        // homeData: state.homeData,
        watchListHomeData: action.watchListData,
        watchListDataArr: action.watchListData
      }
    case type.GET_USER_WATCHING_LIST_REQUEST:
      return {
        ...state,
        process: true
      }
    case type.COMING_SOON_SLIDER_SUCCESS:
      return {
        ...state,
        comingSoonSliderData: action.comingSoonSliderData
      }
    case type.COMING_SOON_LIST_SUCCESS:
      return {
        ...state,
        comingSoonListData: action.comingSoonListData
      }
    case type.GET_TRENDING_SUCCESS:
    case type.REMASTERED_VIDEO_SUCCESS:
    case type.MOST_SHARED_SUCCESS:
    case type.MOST_VIEWED_SUCCESS:
    case type.FILM_MATTERS_SUCCESS:
      return {
        ...state,
        stillLookingListData: state.stillLookingListData?.length > 0 ? [...state.stillLookingListData, ...action?.stillLookingData?.data] : action?.stillLookingData?.data,
        listCount: action.stillLookingData?.count
      }
    case type.GET_BANNER_REQUEST:
    default:
      return state
  }
}