export default class VideoJSPlayer {
  constructor(options = {}) {
    this.defaultOptions = {
      autoplay: false,
      controls: false,
    };
    this.playerDom = null;
    this.player = null;
    this.options = {
      ...this.defaultOptions,
      ...options,
    };
    this.eventList = [
      "load",
      "ready",
      "abort",
      "error",
      "firstplay",
      "loadstart",
      "stalled",
      "suspend",
      "resolutionchange",
      "retryplaylist",
    ];

    this.disableVideoJSDomOption = {
      controlBar: false,
      errorDisplay: false,
      textTrackSettings: false,
      bigPlayButton: false,
      loadingSpinner: false,
      textTrackDisplay: false,
      posterImage: false,
    };
    this.prevTime = 0;
  }

  supportsHLS() {
    let video = document.createElement("video");
    return Boolean(
      video.canPlayType("application/vnd.apple.mpegURL") ||
        video.canPlayType("audio/mpegurl")
    );
  }

  mimeType(url) {
    if (url?.includes(".m3u8")) {
      if (this.supportsHLS()) {
        return "application/vnd.apple.mpegURL";
      }
      return "application/x-mpegURL";
    }
    if (url?.includes(".mpd")) {
      return "application/dash+xml";
    }
    if (url?.includes(".mp4")) {
      return "video/mp4";
    }
  }

  init() {
    this.createVideoDom();
    this.createVideojsPlayer();
  }

  createVideoDom() {
    const { preload, muted, videoId, autoplay } = this.options;
    this.playerDom = document.createElement("video");
    this.playerDom.setAttribute("id", videoId);
    this.playerDom.setAttribute("class", "video-player");
    this.playerDom.setAttribute("controls", false);
    this.playerDom.setAttribute("playsinline", "playsinline");
    this.playerDom.muted = !!muted;
    this.playerDom.autoplay = autoplay || false;
    this.playerDom.preload = preload || "auto";
    this.playerDom.style.position = "absolute";
    this.playerDom.style.left = 0;
    this.playerDom.style.top = 0;
    this.playerDom.style.width = "100%";
    this.playerDom.style.height = "100%";
    this.playerDom.style.zIndex = 1;
    this.playerDom.style.backgroundColor = "#000000";
    this.playerDom.crossOrigin = "anonymous";
    this.playerWrapper = document.getElementsByClassName("player-page")[0];
    this.playerWrapper.appendChild(this.playerDom);
  }

  createVideojsPlayer = () => {
    const { autoplay, muted, isEncrypted } = this.options;
    let playerOptions = {};
    playerOptions = this._getPlayerOptions();
    playerOptions.controls = false;
    playerOptions.autoplay = autoplay || false;
    playerOptions.muted = muted || false;
    playerOptions = {
      ...playerOptions,
      ...this.disableVideoJSDomOption,
    };
    this.player = window.videojs(this.playerDom, playerOptions, () => {
      if (this.options?.continueWatchTime) {
        this.playerDom.currentTime = this.options.continueWatchTime;
        this.playerDom.classList.add("normal");
      }
      this.setPlayerDom();
      this._bindPlayerEvents();
      this._subscribeVideoJsEvents();
    });
    if (isEncrypted) {
      this.player.eme();
    }
    this._setCustomHeaders(this.options);
    this.player.src(this._getPlayerSourceConfig());
  };

  setPlayerDom(player) {
    let playerDom = this.playerDom;
    if (player) {
      this.playerDomWrapper = player.el_;
      const _playerDom = this.playerDomWrapper?.firstElementChild;
      if (_playerDom && playerDom && playerDom.id == _playerDom.id) {
        this.playerDom = _playerDom;
      } else {
        // console.error("this.playerDomWrapper",  this.playerDomWrapper)
      }
    }
  }

  setBufferSize = () => {
    if (this.td_server_hints) {
      const { isLive } = this.dataModel;
      if (isLive) {
        this._setLiveBufferSize();
      } else {
        this._setVodBufferSize();
      }
    }
  };

  _setLiveBufferSize() {
    let { pip_live_initial_buffer } = this.td_server_hints;
    this._setInitialBitrate();
    if (
      pip_live_initial_buffer &&
      typeof pip_live_initial_buffer === "number" &&
      pip_live_initial_buffer > 0
    ) {
      if (window.videojs.Vhs) {
        window.videojs.Vhs.GOAL_BUFFER_LENGTH = pip_live_initial_buffer;
      } else if (window.videojs.Hls) {
        window.videojs.Hls.GOAL_BUFFER_LENGTH = pip_live_initial_buffer;
      }
    }
    // }
  }

  _setVodBufferSize() {
    let { pip_vod_initial_buffer_sec, pip_vod_max_buffer_sec } =
      this.td_server_hints;

    this._setInitialBitrate();
    if (
      pip_vod_initial_buffer_sec &&
      typeof pip_vod_initial_buffer_sec === "number" &&
      pip_vod_initial_buffer_sec > 0
    ) {
      if (window.videojs.Vhs) {
        window.videojs.Vhs.GOAL_BUFFER_LENGTH = pip_vod_initial_buffer_sec;
      } else if (window.videojs.Hls) {
        window.videojs.Hls.GOAL_BUFFER_LENGTH = pip_vod_initial_buffer_sec;
      }
    }

    if (
      pip_vod_max_buffer_sec &&
      typeof pip_vod_max_buffer_sec === "number" &&
      pip_vod_max_buffer_sec > 0
    ) {
      if (window.videojs.Vhs) {
        window.videojs.Vhs.MAX_GOAL_BUFFER_LENGTH = pip_vod_max_buffer_sec;
      } else if (window.videojs.Hls) {
        window.videojs.Hls.MAX_GOAL_BUFFER_LENGTH = pip_vod_max_buffer_sec;
      }
    }
  }

  _subscribeVideoJsEvents() {
    if (this.player) {
      for (const eventName of this.eventList) {
        if (eventName === "retryplaylist") {
          this.player.tech_.on(
            eventName,
            this._videoJsEventCallback.bind(this, eventName)
          );
        } else {
          this.player.on(
            eventName,
            this._videoJsEventCallback.bind(this, eventName)
          );
        }
      }
      this.player.textTracks().on("addtrack", this._onAddtrack);
      this.player.audioTracks().on("change", this._onAudioChange);
    }
  }

  _videoJsEventCallback(eventName, eventData) {}

  _getPlayerOptions() {
    let basePlayerOptions = this._getVhsBasePlayerOptions();
    let playerOptions = this._getVhsPlayerOptions(basePlayerOptions);
    return playerOptions;
  }

  _getVhsBasePlayerOptions() {
    let basePlayerOptions = {
      html5: {
        nativeTextTracks: false,
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        vhs: {
          overrideNative: true,
          fastQualityChange: false,
          // enableLowInitialPlaylist: true, // TODO: enable for 2G mobile
          limitRenditionByPlayerDimensions: false,
          // experimentalBufferBasedABR: true,
          experimentalLLHLS: true,
        },
      },
    };
    return basePlayerOptions;
  }

  _getVhsPlayerOptions(basePlayerOptions) {
    let playerOptions = {};
    playerOptions = {
      ...basePlayerOptions,
    };
    playerOptions.html5.hlsjsConfig = {
      enableWorker: true,
      liveBackBufferLength: 10,
      backBufferLength: 10,
      liveMaxBackBufferLength: 10,
      maxBufferSize: 0,
      maxBufferLength: 10,
      liveSyncDurationCount: 1,
    };
    return playerOptions;
  }

  _getDashPlayerOptions(basePlayerOptions) {
    let playerOptions = {
      ...basePlayerOptions,
    };
    if (window.VIDEOJS_DASH_TV) {
      playerOptions.html5.nativeCaptions = false;
    }
    return playerOptions;
  }

  _getPlayerSourceConfig() {
    const { url, isEncrypted } = this.options;
    const sourceConfig = {};
    sourceConfig.src = url;
    sourceConfig.type = this.mimeType(url);
    // sourceConfig.withCredentials = true;
    if (isEncrypted) {
      sourceConfig.keySystems = this._setDRMConfig();
    }
    return sourceConfig;
  }

  _setDRMConfig = () => {
    let { licenseUrl, licenseToken, drmName } = this.options;
    let keySystems = {};
    // switch (drmName) {
    //   case DRM_NAME.FAIRPLAY:
    //     keySystems = {
    //       [KEY_SYSTEMS.FAIRPLAY]: {
    //         certificateUri:
    //           "https://expressplay-test.s3.amazonaws.com/testplayer/fairplay-sony.cer",
    //         licenseUri: licenseUrl,
    //       },
    //     };
    //     break;
    //   case DRM_NAME.PLAYREADY:
    //     licenseUrl = `${licenseUrl}?ExpressPlayToken=${licenseToken}`;
    //     keySystems = { [KEY_SYSTEMS.PLAYREADY]: licenseUrl };
    //     break;
    //   case DRM_NAME.WIDEVINE:
    //     if (window.VIDEOJS_DASH_TV) {
    //       keySystems = [
    //         {
    //           name: KEY_SYSTEMS.WIDEVINE,
    //           options: {
    //             serverURL: licenseUrl,
    //           },
    //         },
    //       ];
    //     } else {
    //       keySystems = { [KEY_SYSTEMS.WIDEVINE]: licenseUrl };
    //     }
    //     break;
    // }
    return keySystems;
  };

  _setCustomHeaders = (opt) => {
    if (!opt.headers) {
      opt.headers = {};
    }
    // if (this.options.customHeaders) {
    //   for (let key in this.options.customHeaders) {
    //     opt.headers[key] = this.options.customHeaders[key];
    //   }
    // }
    return opt;
  };

  _bindPlayerEvents() {
    if (this.playerDom) {
      this.playerDom.onloadedmetadata = this._onLoadedMetaData;
      this.playerDom.onloadeddata = this._onLoadedData;
      this.playerDom.onplay = this._onPlay;
      this.playerDom.onpause = this._onPause;
      this.playerDom.ontimeupdate = this._onTimeUpdate;
      this.playerDom.onwaiting = this._onWaiting;
      this.playerDom.onseeking = this._onSeeking;
      this.playerDom.onseeked = this._onSeeked;
      this.playerDom.onplaying = this._onPlaying;
      this.playerDom.onended = this._onEnded;
      this.playerDom.onerror = this._onPlaybackError;
      this.playerDom.onloadstart = this._onLoadStart;
      this.playerDom.onprogress = this._onProgress;
      this.playerDom.onsuspend = this._onSuspend;
      this.playerDom.ondurationchange = this._onDurationChange;
      this.playerDom.onvolumechange = this._onVolumeChange;
      this.playerDom.onratechange = this._onRateChange;
      this.playerDom.onabort = this._onAbort;
      this.playerDom.onresize = this._onResize;
      this.playerDom.onstalled = this._onStalled;
    }
  }

  _onAddtrack = (e) => {};

  _unbindPlayerEvents = () => {
    if (this.playerDom) {
      this.playerDom.onloadedmetadata = null;
      this.playerDom.onloadeddata = null;
      this.playerDom.onplay = null;
      this.playerDom.onpause = null;
      this.playerDom.ontimeupdate = null;
      this.playerDom.onwaiting = null;
      this.playerDom.onseeking = null;
      this.playerDom.onseeked = null;
      this.playerDom.onplaying = null;
      this.playerDom.onended = null;
      this.playerDom.onerror = null;
      this.playerDom.onloadstart = null;
      this.playerDom.onprogress = null;
      this.playerDom.onsuspend = null;
      this.playerDom.ondurationchange = null;
      this.playerDom.onvolumechange = null;
      this.playerDom.onratechange = null;
      this.playerDom.onabort = null;
      this.playerDom.onresize = null;
      this.playerDom.onstalled = null;
      this.playerDom.oncuechange = null;
      this.playerDom = null;
    }
  };

  _onLoadedMetaData = (e) => {
    this.prevTime = 0;
    if (this.options?.callback) {
      this.options.callback("LOADEDMETADATA", e);
    }
  };

  _onLoadedData = (e) => {
    if (this.options?.callback) {
      this.options.callback(
        "LOADEDDATA",
        this.playerDom.currentTime,
        this.playerDom.duration
      );
    }
  };

  _handleStartupQuality = () => {
    let qualityLevels = this.getQualityLevels();
    if (!qualityLevels || qualityLevels.length === 0) {
      return;
    }
    if (!window.FAST_STARTUP) {
      return;
    }
    for (const element of qualityLevels) {
      if (element.height >= 360) {
        this.setQualityCapping(element.bitrate);
        break;
      }
    }
  };

  _onQualityChanged = (_event) => {
    try {
      let selectedQuality = this.getSelectedQualityLevel();
    } catch (e) {
      console.error("error qualityChanged", e);
    }
  };

  isPaused = () => {
    return this.player.paused();
  };

  isMuted = () => {
    return this.player.muted();
  };

  muted = (flag) => {
    return this.player.muted(flag);
  };

  _onPlay = (e) => {
    if (this.options?.callback) {
      this.options.callback("PLAY");
    }
  };

  _onPause = (e) => {
    if (this.options?.callback) {
      this.options.callback("PAUSE");
    }
  };

  _onTimeUpdate = (e) => {
    if (this.options?.callback) {
      if (
        parseInt(this.prevTime, 10) != parseInt(this.playerDom.currentTime, 10)
      ) {
        this.prevTime = parseInt(this.playerDom.currentTime, 10);
        this.options.callback(
          "TIMEUPDATE",
          this.playerDom.currentTime,
          this.playerDom.duration
        );
      }
    }
  };

  _onWaiting = (e) => {
    if (this.options?.callback) {
      this.options.callback("WAITING");
    }
  };

  _onSeeking = (e) => {
    if (this.options?.callback) {
      this.options.callback("SEEKING");
    }
  };

  _onSeeked = (e) => {
    if (this.options?.callback) {
      this.options.callback("SEEKED");
    }
  };

  _onPlaying = (e) => {
    if (this.options?.callback) {
      this.options.callback("PLAYING");
    }
  };

  _onEnded = (e) => {
    if (this.options?.callback) {
      this.options.callback("ENDED");
    }
  };

  _onPlaybackError = (e) => {};

  _onLoadStart = (e) => {
    if (this.options?.callback) {
      this.options.callback("LOADSTART");
    }
  };

  _onProgress = (e) => {
    if (this.options?.callback) {
      this.options.callback("PROGRESS");
    }
  };

  _onSuspend = (e) => {
    if (this.options?.callback) {
      this.options.callback("SUSPEND");
    }
  };

  _onDurationChange = (e) => {
    if (this.options?.callback) {
      this.options.callback("DURATIONCHANGE");
    }
  };

  _onVolumeChange = (e) => {
    if (this.options?.callback) {
      this.options.callback("VOLUMECHANGE");
    }
  };

  _onRateChange = (e) => {
    if (this.options?.callback) {
      this.options.callback("RATECHANGE");
    }
  };

  _onAbort = (e) => {
    if (this.options?.callback) {
      this.options.callback("ABORT");
    }
  };

  _onResize = (e) => {
    if (this.options?.callback) {
      this.options.callback("RESIZE");
    }
  };

  _onStalled = (e) => {
    if (this.options?.callback) {
      this.options.callback("STALLED");
    }
  };

  _onCuechange = () => {
    if (this.options?.callback) {
      this.options.callback("CUE_CHANGE");
    }
  };

  _onAudioChange = () => {
    if (this.options?.callback) {
      this.options.callback("AUDIOCHANGE");
    }
  };

  play() {
    if (this.playerDom) {
      let playPromise = this.playerDom.play();
      if (playPromise != undefined) {
        playPromise.then().catch((e) => console.error(e));
        return playPromise;
      }
    }
  }

  pause() {
    if (this.playerDom) {
      this.playerDom.pause();
    }
  }

  setVolume(vol) {
    if (this.playerDom) {
      this.playerDom.volume = vol;
    }
  }

  forward(time) {
    if (this.playerDom && time != undefined) {
      this.playerDom.currentTime = this.playerDom.currentTime + time;
    }
  }

  rewind(time) {
    if (this.playerDom && time != undefined) {
      this.userInitiatedSeek = true;
      this.playerDom.currentTime = this.playerDom.currentTime - time;
    }
  }

  seek(time, isScrubbing) {
    // this.setBufferCheck(this.isFirstPlayReceive);
    // this.seekStartTime = this.currentTime();
    // if (isScrubbing) this.userInitiatedSeek = true;
    if (this.playerDom && time != undefined) {
      this.playerDom.currentTime = time;
    }
  }

  stop() {
    this.destroy();
  }

  destroy() {
    if (this.playerDom) {
      this.playerDom.pause();
      this.playerDom.removeAttribute("src");
      let source = this.playerDom.querySelector("source");
      if (source) {
        source.removeAttribute("src");
      }
      this.playerDom.load();
      this.playerDom.remove();
    }
    this._unbindPlayerEvents();
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
  }

  currentTime() {
    if (this.playerDom) {
      return this.playerDom.currentTime;
    }
  }

  timeToLoadPlayer() {
    if (this.TTLP) {
      return this.TTLP;
    }
  }

  timeTakeToLoadVideo() {
    if (this.TTLV) {
      return this.TTLV;
    }
  }

  duration() {
    if (this.playerDom) {
      return this.playerDom.duration;
    }
  }

  playerDomRef() {
    return this.playerDom;
  }

  playerRef() {
    return this.player;
  }

  playerOptions() {
    return this.options;
  }

  getAudioTracks() {
    if (this.player) {
      let audioTracks = this.player?.audioTracks()?.tracks_;
      return audioTracks;
    }
    return [];
  }

  getActiveAudioTrack() {
    let audioTracks = this.getAudioTracks();
    if (this.player) {
      if (audioTracks?.tracks_?.length <= 1) {
        return this.dataModel?.languageItems?.defaultLanguage.toLowerCase();
      } else if (audioTracks && audioTracks?.tracks_?.length) {
        for (const element of audioTracks.tracks_) {
          if (element.enabled) {
            return element.language.toLowerCase();
          }
        }
        return null;
      }
    } else if (this.playerDom) {
      if (audioTracks?.length <= 1) {
        return this.dataModel?.languageItems?.defaultLanguage.toLowerCase();
      } else if (audioTracks && audioTracks?.length > 1) {
        for (let i = 0; i < audioTracks.length; i++) {
          if (audioTracks[i].enabled) {
            return audioTracks[i].language.toLowerCase();
          }
        }
        return null;
      }
    }
  }

  changeAudioLanguage(audioTrack) {
    audioTrack.enabled = true;
  }

  setMaxVideoQuality(selectedBitrate) {
    let qualityLevels = this.getQualityLevels();
    if (qualityLevels && qualityLevels.length) {
      for (let i = 0; i < qualityLevels.length; i++) {
        let bitrate = qualityLevels[i].bitrate / 1000;
        if (bitrate <= selectedBitrate) {
          qualityLevels[i].enabled = true;
        } else {
          qualityLevels[i].enabled = false;
        }
      }
    }
  }

  getTextTracks() {
    if (this.player) {
      return this.player.textTracks();
    }
    if (this.playerDom) {
      return this.playerDom.textTracks;
    }
  }

  setTextTrack(_trackid) {
    /* TODO document why this method 'setSubtitleTrack' is empty */
  }

  addRemoteSubtitles() {
    //vjsPlayer.addRemoteTextTrack(Object options)
  }

  setDefaultVttSubtitle(subtitles, selectedSubtitle) {
    if (selectedSubtitle) {
      const subtitle = subtitles.filter((item) => {
        return item.language_short_name == selectedSubtitle;
      });
      if (subtitle && subtitle.length > 0) {
        if (document.querySelector("track")) {
          document.querySelector("track").remove();
        }
        let track = document.createElement("track");
        track.setAttribute("label", subtitle[0].language_name);
        track.setAttribute("kind", "subtitles");
        track.setAttribute("class", "custom-text-track");
        window.WebVTTConverter(subtitle[0].subtitle_path);
        window.WebVTTConverter.getURL().then(
          (bloburl) => {
            track.setAttribute("src", bloburl);
          },
          (err) => {}
        );
        track.setAttribute("srclang", subtitle[0].language_short_name);
        track.setAttribute("default", true);
        // track.addEventListener("cuechange", (event) => {
        //   console.log(track.activeCues);
        //   for (const cue of track.activeCues) {
        //     console.log(cue.value);
        //     const metadata = {};
        //     if (cue.value.key) {
        //       metadata[cue.value.key] = cue.value.data;
        //       console.log(metadata);
        //     }
        //   }
        // });
        this.playerDom.appendChild(track);
      }
    } else {
      if (document?.querySelector("track")) {
        document.querySelector("track").remove();
      }
    }
  }

  paused() {
    if (this.playerDom) {
      return this.playerDom.paused;
    }
  }

  buffered() {
    if (this.playerDom) {
      return this.playerDom.buffered;
    }
  }

  muted(value) {
    if (this.playerDom) {
      if (typeof value === "boolean") return (this.playerDom.muted = value);
      return this.playerDom.muted;
    }
  }

  setVolume(volume, volumeSource) {
    if (this.playerDom) {
      this.volumeSource = volumeSource;
      return (this.playerDom.volume = volume);
    }
  }

  getVolume() {
    if (this.playerDom) {
      return this.playerDom.volume;
    }
  }

  getQualityLevels() {
    if (this.player) {
      let levels = this.player.qualityLevels().levels_ || [];
      if (levels.length > 0) {
        levels = levels.sort((level1, level2) => {
          return level1.height < level2.height;
        });
        levels = levels.filter((level) => {
          if (this.maxResolution && this.maxResolution <= level.height) {
            level.enabled = false;
          } else if (level.bitrate <= this.maxBitrate) {
            return level;
          } else {
            level.enabled = false;
          }
        });
      }
      return levels;
    }
  }

  getSelectedQualityLevel() {
    try {
      if (this.player) {
        let selectedIndex = this.player.qualityLevels().selectedIndex;
        let allLevels = this.player.qualityLevels().levels_;
        allLevels.sort(function (a, b) {
          return a.bitrate - b.bitrate;
        });
        let selectedLevel = allLevels[selectedIndex];
        return selectedLevel;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  getBuffered() {
    if (this.player) {
      return this.player.buffered(0).end(0) - this.player.buffered(0).start(0);
    }
    return null;
  }

  getBufferEnd() {
    if (this.player) {
      return this.player.buffered(0).end(0);
    }
    return null;
  }

  getBufferHealth = () => {
    try {
      const maxBuffer = 200;
      const remainingBuffer = (
        this.getBufferEnd() - this.currentTime()
      ).toFixed(2);
      if (remainingBuffer >= 0) {
        if (remainingBuffer > maxBuffer) {
          return maxBuffer;
        }
        return remainingBuffer;
      }
      return 0;
    } catch (error) {
      return 0;
    }
  };

  getVideoPlaybackQuality() {
    if (this.player) {
      return this.player.getVideoPlaybackQuality();
    }
    return null;
  }

  setQualityLevel(selectedIndex, isABREnabled = false) {
    if (typeof selectedIndex == "number") {
      let qualityLabels = this.player.qualityLevels();
      for (var index = 0; index < qualityLabels.length; index++) {
        if (selectedIndex == index) {
          qualityLabels[index].enabled = true;
        } else {
          qualityLabels[index].enabled = isABREnabled;
        }
      }
      let selectedQuality = qualityLabels[selectedIndex];
      return selectedQuality;
    }
  }

  getBandwidth() {
    if (!this.player) {
      return null;
    }
    return this.player.tech_?.sourceHandler_?.bandwidth || null;
  }

  getMediaBytesTransferred() {
    if (!this.player) {
      return null;
    }
    if (this.player.tech_) {
      if (this.player.tech_.vhs) {
        return (
          this.player.tech_.vhs?.masterPlaylistController_?.mediaBytesTransferred_() ||
          null
        );
      }
      return (
        this.player.tech_.hls?.masterPlaylistController_?.mediaBytesTransferred_() ||
        null
      );
    }
  }

  getMediaTransferDuration() {
    if (!this.player) {
      return null;
    }
    if (this.player.tech_) {
      if (this.player.tech_.vhs) {
        return (
          this.player.tech_.vhs?.masterPlaylistController_?.mediaTransferDuration_() ||
          null
        );
      }
      return (
        this.player.tech_.hls?.masterPlaylistController_?.mediaTransferDuration_() ||
        null
      );
    }
  }

  seekToLiveEdge() {
    if (this.player?.liveTracker) {
      this.player.liveTracker.seekToLiveEdge();
    }
  }

  reportDomAndVideojsError(eventName, errorObj) {
    this.errorTimeoutFunc && clearTimeout(this.errorTimeoutFunc);
    let errorMessage = "";
    if (this.errorObj?.errData) {
      errorMessage += this.errorObj.errData + " ";
    }
    if (errorObj?.errData) {
      errorMessage += errorObj.errData + " ";
    }
    if (errorObj) {
      this.errorObj = {
        ...(this.errorObj && this.errorObj),
        ...errorObj,
      };
    }
    if (errorMessage && this.errorObj?.code != 8) {
      this.errorObj.errData = errorMessage;
    }
  }

  checkForManifestError() {
    if (this.player?.tech_?.vhs?.masterPlaylistController_?.error?.message) {
      return this.player.tech_.vhs.masterPlaylistController_?.error;
    }
    return false;
  }
}
