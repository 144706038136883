import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { createListRequest, createListFail, deleteListRequest, fetchListRequest } from "../../redux/actions/createListAction";
import { IRootState } from "../../redux/reducer";
import { ICreateListState } from "../../model/createList";

interface ICreateListPopUp {
    setCreateList: (arg: boolean) => void
}

const CreateListPopUp = (props: ICreateListPopUp) => {
    const [name, setName] = useState('');
    const dispatch = useDispatch();

    const createList = (e: any) => {
        e.stopPropagation();
        dispatch(createListFail(null));
        dispatch(createListRequest(name));
        props.setCreateList(false);
        window.$('.modal-backdrop').remove();
        document.body.classList.remove('modal-open');
    }

    return (<div className="share-popup createlist_popup">
        <div className="modal show" id="createlist-popup" tabIndex={-1} role="dialog" aria-labelledby="createlist-popupLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.setCreateList(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="modal-body">
                        <div className="share-cont">
                            <div className="share_text">
                                <strong className="sharemovi-name">Create Your List</strong>
                            </div>
                            <div className="copylink_form">
                                <form>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="url" className="form-control text-center"
                                                placeholder="Add a list for your friend’s" onChange={(e) => setName(e.target.value)} />
                                            <div className="input-group-append">
                                                <button type="button" className="btn common-btn" onClick={(e) =>
                                                    createList(e)
                                                }>Save Your List</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default CreateListPopUp;