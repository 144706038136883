import { useDispatch, useSelector } from 'react-redux';
import share from '../../assets/img/share/share-1.jpg';
import { IRootState } from '../../redux/reducer';
import { ICreateListState, IWatchListData } from '../../model/createList';
import { useEffect, useState } from 'react';
import CreateListPopUp from './createListPopup';
import { toggleToastBool } from '../../redux/actions/createListAction';

const SearchSavePopup = (props: any) => {
    const [searchName, setSearchName] = useState('');
    return (<>

        <div className="share-popup wishlist_popup">
            <div className="modal show" id="wishlist-popup" tabIndex={-1} role="dialog" aria-labelledby="wishlist-popupLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ "minHeight": "150px" }}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setSearchName(''); document.body.classList.remove('modal-open'); props.setSavePopup(false) }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <div className="share-cont" style={{
                                "textAlign": "center",
                                "display": "flex",
                                "flexDirection": "row",
                                "alignItems": "center",
                                "justifyContent": "center"
                            }}>
                                <div className='savedsearch_form'>
                                    <div className="copylink_form">
                                        <form>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input type="url" style={{ "textAlign": "left" }} value={searchName} className="form-control text-center"
                                                        placeholder="Enter Search List Name" onChange={(e) => setSearchName(e.target.value)} />
                                                    <button type="button" className="btn common-btn" onClick={() => {

                                                        props.savePopUp(searchName); setSearchName(''); document.body.classList.remove('modal-open');
                                                    }}>Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* <div className="share-popup wishlist_popup">
            <div className="modal show" id="wishlist-popup" tabIndex={-1} role="dialog" aria-labelledby="wishlist-popupLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setSearchName(''); document.body.classList.remove('modal-open'); props.setSavePopup(false) }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <div className="share-cont">
                                <div className="shareimgtitle_group">
                                
                                    <div className="savedsearch_form">
                                        <div className="share_text slctwishlist_text">
                                            <strong className="sharemovi-name">Add to saved searches</strong>
                                        </div>
                                        <div className="copylink_form">
                                            <form>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input type="url" style={{ "textAlign": "left" }} value={searchName} className="form-control text-center"
                                                            placeholder="Enter Search List Name" onChange={(e) => setSearchName(e.target.value)} />
                                                    </div>
                                                    <div className="mt-3">
                                                        <button type="button" className="btn common-btn" onClick={() => {

                                                            props.savePopUp(searchName); setSearchName(''); document.body.classList.remove('modal-open');
                                                        }}>Save</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

    </>)
}

export default SearchSavePopup;