import { combineReducers } from 'redux';
import headerReducer from './headerReducer';
import bannerReducer from './homeReducer';
import movieDetailReducer from './movieDetailReducer';
import initialApiReducer from './initialApiReducer';
import menuReducer from './menuReducer';
import authReducer from './authReducer';
import createListReducer from './createListReducer';
import searchReducer from './searchReducer';
import profileReducer from './profileReducer';
import toolReducer from './toolReducer';
import videoReducer from './videoReducer';
import userActivityReducer from "./userActivityReducer";
import suggestedVideoReducer from "./suggestedVideoReducer";
import deviceReducer from "./deviceReducer";
import membershipReducer from "./membershipReducer";
import reportIssueReducer from "./reportIssueReducer";
import adReducer from './adReducer';


const rootReducer = combineReducers({
    homeReducer: bannerReducer,
    movieDetailReducer,
    headerReducer,
    initialApiReducer,
    menuReducer,
    authReducer,
    createListReducer,
    searchReducer,
    profileReducer,
    toolReducer,
    videoReducer,
    userActivityReducer,
    suggestedVideoReducer,
    deviceReducer,
    membershipReducer,
    reportIssueReducer,
    adReducer
});

export default rootReducer;

export type IRootState = ReturnType<typeof rootReducer>;