import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { IRootState } from "../../redux/reducer";
import { IHeaderinitialState } from "../../redux/reducer/headerReducer";
import { setRightSideMenuActive } from '../../redux/actions/headerAction';
import { removeItem } from "../../utils/storage";
import { KEYS } from "../../utils/constant";
import { logoutAction, logoutSuccess } from "../../redux/actions/authAction";
import CreateListPopUp from "../Popup/createListPopup";
import { deleteListRequest, fetchListRequest, updateListRequest, fetchFriendsWatchlistRequest } from "../../redux/actions/createListAction";
import { ICreateListState, IWatchListData } from "../../model/createList";
import { fetchUserDetail } from "../../utils/utils";
import { ACTIVATETV, CATEGORY, PROFILE, WATCH_HISTORY, DEVICES, MEMBERSHIP, SUGGESTED_VIEW, PLAN_PAGE } from '../../routes/routesConstant';
import { getPlan, getUserSubscription } from "../../redux/actions/profileAction";
import FriendsSharePopup from "../Popup/friendsSharePopup";

export const RightMenu = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [createList, setCreateList] = React.useState(false);
    const [shareFriendPopup, setShareFriendPopup] = React.useState(false);
    const [selectedList, setSelectedList] = useState("");
    const [list, setList] = React.useState<IWatchListData[]>([]);
    const { right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { watchListData, friendWatchlistData, message } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { planData, subscriptionData } = useSelector<IRootState, any>((state) => state.profileReducer);
    const deleteList = (id: number) => {
        dispatch(deleteListRequest(id))
    }
    const handleToggleComplete = (data: any) => {
        const newList: IWatchListData[] = list.map((item: any) => {
            if (item.id === data.id) {
                const updatedItem = {
                    ...item,
                    isEdit: !item.isEdit,
                    title: item.list_name
                };
                return updatedItem;
            }
            item.isEdit = false;
            return item;
        });
        setList(newList)
    }

    useEffect(() => {
        setList(watchListData)
    }, [watchListData]);

    // useEffect(() => {
    //     if (message) {
    //         toast(message);
    //     }
    // }, [message]);


    useEffect(() => {
        if (dev_base_url || live_base_url) {
            dispatch(fetchListRequest());
            dispatch(fetchFriendsWatchlistRequest());
            dispatch(getPlan());
            dispatch(getUserSubscription({ user_id: loginData.user_id }));
        }
    }, [dev_base_url, live_base_url])

    const updateName = (item: IWatchListData) => {
        dispatch(updateListRequest(item.id, item.title))
    }
    return (<>
        <nav id="userbar" className={`userbar ${right_active ? 'fixed_userbar' : ''}`}>
            <div className="sidebarnav-main">
                <div className="username_topgroup d-flex">
                    <span className="userbar_colse" onClick={() => dispatch(setRightSideMenuActive(false))}>X</span>
                    <span className="username_toptext">{loginData?.username || fetchUserDetail()?.username?.split(' ')?.[0]}</span>
                    {(loginData?.rootUserDetail?.user_id == loginData?.user_id) ? (<span className="common-btn ml-auto" onClick={() => { dispatch(setRightSideMenuActive(false)); navigate(`${ACTIVATETV}`) }}>
                        Activate Device
                    </span>) : null}
                </div>
                {subscriptionData?.subscription_status == "Active" ? (<div className="freeaccsubs_group">
                    <div className="freeaccsubs_panel">
                        <strong className="freeaccsubs_hdng">Premium Member</strong>
                    </div>
                </div>) : null}
                {(subscriptionData?.subscription_status == "Inactive" || !subscriptionData) ? (<div className="freeaccsubs_group">
                    <div className="freeaccsubs_panel">
                        <strong className="freeaccsubs_hdng">Free Account</strong>
                        <button className="common-btn ml-auto" onClick={() => { navigate(`${PLAN_PAGE}`) }}>Subscribe</button>
                    </div>
                    <span dangerouslySetInnerHTML={{ __html: (planData?.[0]?.plan_text || '') }}></span>
                </div>) : null}
                {subscriptionData?.subscription_status == "Cancelled" ? (<div className="freeaccsubs_group">
                    <div className="freeaccsubs_panel">
                        <strong className="freeaccsubs_hdng">Membership Cancelled</strong>
                    </div>
                </div>) : null}
                <div className="yourlistfrnd_listgrid">
                    <div className="yourlistfrnd_group">
                        <div className="createlist_panel">
                            <strong className="userbar_comnhdng">Your Lists</strong>
                            <span data-toggle="modal" data-target="#createlist-popup" data-whatever="@createlist-popup" className="createlist_btn" onClick={() => setCreateList(true)}>Create List<i className="fa fa-plus"></i></span>
                        </div>
                        <div className="yourlistfrnd">
                            <ul id="frd-box">
                                {list?.length ? list.map((item: any, index: number) => <li key={item.id}>
                                    <span className="yourlst_text">
                                        {!item.isEdit ?
                                            <Link to={`${CATEGORY}?category_id=${item.id}&category_name=${item.list_name}&type=watch_list`} className="yourlst_title">{item.list_name}</Link> :
                                            <div className="editwishlist_form">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input type="url" className="form-control text-center" placeholder="Edit your List" value={item.title} onChange={(e: any) => {
                                                            const newState: any = [...list];
                                                            newState[index]['title'] = e.target.value
                                                            setList(newState)
                                                        }} />
                                                        <div className="input-group-append">
                                                            <button type="button" className="btn common-btn" onClick={() => { updateName(item) }}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </span>
                                    <span className="createlist_icongroup" >
                                        <span className="createlist_icon editfrnd_icon" onClick={() => handleToggleComplete(item)}>
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                                                    <path d="M4090 5091 c-124 -40 -156 -64 -390 -297 l-225 -224 547 -547 548
                                        -548 211 210 c225 224 277 290 310 394 45 146 35 280 -33 421 -38 80 -52 97
                                        -257 301 -230 230 -270 259 -401 294 -77 21 -241 19 -310 -4z"></path>
                                                    <path d="M1743 2837 l-1422 -1422 -162 -650 c-119 -477 -161 -658 -155 -678 9
                                        -36 46 -74 81 -83 27 -7 1276 296 1325 322 14 7 661 649 1438 1426 l1412 1413
                                        -548 548 -547 547 -1422 -1423z"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <span className="createlist_icon sharefrnd_icon" data-toggle="modal" data-target="#sharefrndlist-popup" data-whatever="@sharefrndlist-popup" onClick={() => { setShareFriendPopup(true); setSelectedList(item); }}>
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                                                    <path d="M3897 5105 c-276 -53 -519 -237 -642 -490 -70 -142 -88 -224 -88
                                        -395 0 -129 3 -155 27 -234 21 -71 24 -91 14 -97 -7 -5 -341 -206 -743 -448
                                        l-729 -439 -65 60 c-278 260 -667 320 -1006 153 -230 -112 -408 -329 -476
                                        -580 -32 -115 -32 -335 0 -450 149 -548 747 -826 1257 -584 103 48 184 110
                                        276 208 l73 77 700 -358 c384 -197 701 -361 703 -363 3 -2 -4 -37 -14 -77 -27
                                        -105 -25 -304 5 -413 84 -311 334 -561 646 -646 115 -32 335 -32 450 0 251 68
                                        468 246 580 476 70 142 88 223 88 395 0 172 -18 253 -88 395 -86 175 -235 325
                                        -410 410 -357 175 -774 98 -1047 -193 l-73 -79 -700 359 c-385 198 -703 362
                                        -706 366 -4 4 2 48 13 97 24 112 19 263 -13 375 -11 41 -22 86 -23 99 -1 21
                                        76 70 734 466 l735 443 69 -65 c281 -265 669 -326 1011 -158 230 112 408 329
                                        476 580 18 65 22 107 22 225 0 171 -18 253 -88 395 -112 230 -333 411 -580
                                        475 -106 28 -283 35 -388 15z"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <span className="createlist_icon remove_youlist removeyourlst_icon" onClick={() => deleteList(item.id)}>
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                                                    <path d="M3630 3845 c-22 -10 -235 -216 -555 -535 l-520 -520 -515 516 c-283
                                        283 -527 522 -542 530 -42 22 -119 18 -158 -8 -63 -44 -87 -129 -56 -201 8
                                        -18 237 -254 530 -547 l516 -515 -519 -520 c-286 -286 -525 -533 -530 -548
                                        -15 -40 -14 -103 4 -136 35 -70 119 -104 192 -81 30 10 149 123 561 533 l522
                                        522 528 -527 c567 -567 549 -551 634 -534 92 18 157 140 117 219 -6 12 -246
                                        257 -532 544 l-522 523 522 522 c287 288 527 536 532 551 49 129 -87 266 -209
                                        212z"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </li>) : <></>}
                            </ul>
                        </div>
                    </div>
                    {friendWatchlistData.length ? <div className="yourlistfrnd_group">
                        <strong className="userbar_comnhdng">Friend’s List</strong>
                        <div className="yourlistfrnd">
                            <ul>
                                {friendWatchlistData?.map((item: any) => {
                                    return (
                                        <li>
                                            <span className="frndlst_text">
                                                <Link to={`${CATEGORY}?category_id=${item.id}&category_name=${item.list_name}&type=watch_list&list_type=2`} className="yourlst_title">{item.list_name}</Link>
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div> : null}
                </div>
                <div className="userbarnav_conts">
                    {/* <div className="contiwatchview_panel">
                        <strong className="userbar_comnhdng">Shared List</strong>
                        <ul className="userbarnav_group">

                        </ul>
                    </div> */}
                    <div className="contiwatchview_panel">
                        <ul className="userbarnav_group">
                            <li>
                                <Link to={`${CATEGORY}?category_id=1&category_name=Continue Watching&type=genre&category_type=continuewatch`}>Continue Watching</Link>
                            </li>
                            <li>
                                <Link to={`${SUGGESTED_VIEW}`}>Suggested Viewing</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="userbarnav-grid">
                        <strong className="userbar_comnhdng">Setting</strong>
                        <ul className="userbarnav_group">
                            <li>
                                <Link to={PROFILE} >Profile</Link>
                            </li>
                            <li> <Link to={WATCH_HISTORY}>User History</Link></li>
                            <li> <Link to={DEVICES}>Devices</Link></li>
                            <li> <Link to={MEMBERSHIP}>Membership</Link></li>
                            {/* <li> <a href="language.php">Language</a></li> */}
                        </ul>
                        <ul className="userbarnav_group">
                            <li> <span onClick={() => {
                                if (loginData?.guest_id) {
                                    dispatch(logoutSuccess())
                                } else
                                    dispatch(logoutAction(true))
                                removeItem(KEYS.USER_DETAILS)
                                props.setLogoutclick(true);
                                dispatch(setRightSideMenuActive(false));
                                navigate('/');
                            }}>Sign Out</span></li>
                        </ul>
                    </div>
                </div>
            </div></nav>
        {createList ? <CreateListPopUp setCreateList={setCreateList} /> : <></>}
        {shareFriendPopup ? <FriendsSharePopup setShareFriendPopup={setShareFriendPopup} listData={selectedList} /> : <></>}
    </>)
}


