import { v4 as uuidv4 } from 'uuid';
import { getItem, setItem } from "./storage";
import { KEYS } from './constant';

export function secondsToHms(d: number) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);
    let hDisplay = h > 0 ? h + (h === 1 ? "h " : "h, ") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? "m " : "m, ") : "";
    let sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
}

export function minutessToHm(d: number) {
    d = Number(d);
    let h = Math.floor(d / 60);
    let m = Math.floor(d % 60);
    let hDisplay = h > 0 ? h + (h === 1 ? "h " : "h, ") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? "m" : "m") : "";
    return hDisplay + mDisplay;
}

export function getDeviceId() {
    let UUID = getItem(KEYS.UUID);
    if (UUID) {
        return UUID;
    } else {
        UUID = uuidv4();
        setItem(KEYS.UUID, UUID);
        return UUID;
    }
}

export function fetchUserDetail() {
    const userData = getItem(KEYS.USER_DETAILS);
    const userDetail = userData && JSON.parse(userData);
    return userDetail;
}

export function casheBuster() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}