import { call, put, takeEvery } from "redux-saga/effects";
import { post } from "../../api";
import {
  MEMBERSHIP_LIST_FAIL,
  MEMBERSHIP_LIST_REQUEST,
  MEMBERSHIP_LIST_SUCCESS,
  MEMBERSHIP_CANCEL_REQUEST,
  MEMBERSHIP_CANCEL_SUCCESS,
  MEMBERSHIP_CANCEL_FAIL,
} from "../types";

async function fetchMembershipListApi(data) {
  const { user_id = "" } = data;
  try {
    let url = `/user-membership-plan-list?user_id=${user_id}`;
    let response = await post(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function cancelMembershipApi(data) {
  const { user_id = "" } = data;
  try {
    let url = `/cancel-subscription?user_id=${user_id}`;
    let response = await post(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchMembershipList(action) {
  try {
    const data = yield call(fetchMembershipListApi, action.data);
    yield put({
      type: MEMBERSHIP_LIST_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({ type: MEMBERSHIP_LIST_FAIL, message: e.message });
  }
}

function* cancelMembershipRequest(action) {
  try {
    const data = yield call(cancelMembershipApi, action.data);
    yield put({
      type: MEMBERSHIP_CANCEL_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({ type: MEMBERSHIP_CANCEL_FAIL, message: e.message });
  }
}

export function* membershipSaga() {
  yield takeEvery(MEMBERSHIP_LIST_REQUEST, fetchMembershipList);
  yield takeEvery(MEMBERSHIP_CANCEL_REQUEST, cancelMembershipRequest);
}

export default membershipSaga;
