import {
    GET_INITIAL_API_REQUEST, GET_INITIAL_API_FAILURE, GET_INITIAL_API_SUCCESS, STRIPE_CONFIG_REQUEST,
    STRIPE_CONFIG_SUCCESS,
    STRIPE_CONFIG_FAILURE,
} from "../types";

export const getInitialApiRequest = () => {
    return {
        type: GET_INITIAL_API_REQUEST
    };
};

export const getInitialApiFailure = (error: any) => {
    return {
        type: GET_INITIAL_API_FAILURE,
        error
    };
};

export const getInitialApiResponse = (response: any) => {
    return {
        type: GET_INITIAL_API_SUCCESS,
        response
    };
};

export const stripeConfigRequest = () => {
    return {
        type: STRIPE_CONFIG_REQUEST,
    };
};

