import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { IRootState } from "../../redux/reducer";
import { get, post } from "../../api";
import { PRIVACYPOLICY, TERMS, ABOUT, HELP, FAQ } from "../../routes/routesConstant";


const Footer = () => {
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const [footerData, setFooterData] = useState<any>(null);
    const [applicationData, setApplicationData] = useState<any>(null);
    useEffect(() => {
        if (dev_base_url || live_base_url) {
            getDevicedata();
            getApplicationVersion();
        }
    }, [dev_base_url, live_base_url]);

    const getDevicedata = async () => {
        const response = await get('/device-master');
        setFooterData(response?.data);
    };

    const getApplicationVersion = async () => {
        const response = await post('/get-version?platform=web');
        setApplicationData(response?.data);
    };

    return (<footer className="text-center">
        <div className="container">
            <div className="app_grid">
                <p> Darkroom is also available on the following TV Devices and Mobile Apps.</p>
                <div className="app_list">
                    {footerData?.device_list?.map((item: any) => {
                        return (<a
                            key={item.platform}
                            href={`${item.platform_url}`}
                            target="_blank"
                            rel="noreferrer"
                        ><img src={item.platform_image} alt={item.platform} /></a>)
                    })}

                </div>
            </div>
            <div className="foot_social">
                <span>Follow us!</span>
                {footerData?.social_media_list?.map((item: any) => {
                    return (<a
                        key={item.social_media_name}
                        href={`${item.platform_url}`}
                        target="_blank"
                        rel="noreferrer"
                    ><img src={item.social_media_icon} alt={item.social_media_name} /></a>)
                })}
            </div>
            <div className="footer_bottom">
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="footLink_list">
                            <li><Link to={`${ABOUT}`}>About </Link></li>
                            <li><Link to={`${TERMS}`}>Terms </Link></li>
                            <li><Link to={`${PRIVACYPOLICY}`}>Privacy</Link></li>
                            <li><Link to={`${HELP}`}>Help Center</Link></li>
                            <li><Link to={`${FAQ}`}>FAQ's</Link></li>
                            <li>© 2024 Darkroom a division of Synergetic. All Rights Reserved. </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="col-lg-12">
                    <span className='app-version'> Application Version : {applicationData?.current_version}</span>
                </div>
            </div>
        </div>
        <span className="scroll-to-top" id="scroll" title="Scroll to Top" onClick={(event) => { event.preventDefault(); window.$("html, body").animate({ scrollTop: 0 }, 600); }}>

        </span>
        {/* <a href="" id="scroll" title="Scroll to Top" onClick={(event)=>{event.preventDefault();window.$("html, body").animate({ scrollTop: 0 }, 600);}}>
            <span>↑</span>
        </a> */}
    </footer>)
}

export default Footer;