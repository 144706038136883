import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { newarrivalslider } from "../../Container/Home/theme";
import { CONTENT_DETAIL, PLAYER, SIGN_IN } from '../../routes/routesConstant';
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../redux/reducer";
import { openWishlistRequest, openRemoveWishlistRequest, toggleToastBool } from '../../redux/actions/createListAction';

const ComingSoonList = ({ comingSoonListData }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    useEffect(() => {
        if (comingSoonListData?.length) {
            newarrivalslider()
        }
    }, [comingSoonListData])
    const getImage = (className: any, video: any) => {
        switch (className) {
            case "portraitlarge_slider":
            case "portraitsmall_slider":
                return video['portrait_art_2000x3000'];
            case "landscapelarge_slider":
            case "landscapesmall_slider":
            case "importantitem_slider":
                return video['landscape_art_1280x720'];
            case "heroimage_slider":
                return video['portrait_ftm_art_1920x800'];
            default:
                return video['portrait_art_2000x3000'];
        }
    }

    const toggleWishlist = (val: boolean, image: string | undefined, video_id: number) => {
        dispatch(openWishlistRequest(val, image, video_id));
        dispatch(toggleToastBool());
    }

    const toggleRemoveWishlist = (val: boolean, video_id: number, watch_list_id: number) => {
        dispatch(openRemoveWishlistRequest(val, video_id));
    }

    return (<div className="home_main">
        {comingSoonListData?.length && comingSoonListData?.map((item: any) => <section className="movie-section portraitsmall_slider" key={item.id}>
            <div className="container-fluid">
                <div className="movie_main">
                    <div className="title">
                        <span className="movititle-text">{item.name}</span>
                    </div>
                    <div className="portraitsmall_slider owl-carousel">
                        {item.videos.map((video: any) => <div className="movie-panel" key={video.video_id}>
                            <div className="movi-poster" key={`detail-${video.video_id}`}>
                                <Link to={`${CONTENT_DETAIL}?video_id=${video.video_id}&video_type=${video.video_type}`} className="movieBox">
                                    <img src={getImage(item?.genre_class, video)} alt="top-picks-1" />
                                </Link>
                                {video?.play_time ? <div className="seekbar_panel">
                                    <div className="seekbar_fill" style={{ "width": `${video ? (video?.play_time / video?.total_time) * 100 : 0}%` }}></div>
                                </div> : null}
                                <div className="movishortinfo_panel">
                                    <span>
                                        {video.video_type == 1 ? (<i className="fa fa fa-play" data-toggle="tooltip" data-placement="bottom" title="Play" onClick={() => {
                                            if (!loginData?.token) {
                                                navigate(`${SIGN_IN}`);
                                            } else {
                                                navigate(`${PLAYER}?video_id=${video.video_id}&video_type=${video.video_type}`);
                                            }
                                        }}></i>) : null}
                                    </span>
                                    <span>
                                        {video.is_my_list || item.list_name ?
                                            (<i className="fa fa-check" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Remove from watchlist"
                                                onClick={() => {
                                                    toggleRemoveWishlist(true, video.video_id, video.watch_list_id)
                                                }}></i>)
                                            :
                                            (<i className="fa fa-plus" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Add to watchlist"
                                                onClick={() => {
                                                    if (!loginData?.token) {
                                                        navigate(`${SIGN_IN}`);
                                                        return;
                                                    }
                                                    toggleWishlist(true, getImage(item?.genre_class, video), video.video_id)
                                                }}></i>)}
                                    </span>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </section>)}
    </div>)
}

export default ComingSoonList;