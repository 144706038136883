import {  LEFT_SIDE_MENU_ACTIVE,RIGHT_SIDE_MENU_ACTIVE } from "../types";

export const setLeftSideMenuActive = (active: boolean) => {
    return {
        type: LEFT_SIDE_MENU_ACTIVE,
        active: active
    };
};
export const setRightSideMenuActive = (active: boolean) => {
    return {
        type: RIGHT_SIDE_MENU_ACTIVE,
        active: active
    };
};

