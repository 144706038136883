import { useSelector } from "react-redux";
import { useEffect, useState } from "react";


const EditProfilePopup = ({ editUserProfile, setEditProfilePopupbool, selectedProfile }: any) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: ""
    });

    useEffect(() => {
        if (selectedProfile) {
            setFormData({
                email: selectedProfile?.email || '',
                password: "",
                confirm_password: "",
                first_name: selectedProfile?.name || '',
                last_name: ""
            });
        }
    }, [])

    const changeHandler = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const cancel = () => {
        setEditProfilePopupbool(false);
        document.body.classList.remove('modal-open');
        window.$('.modal-backdrop').remove();
    }
    const submit = () => {
        editUserProfile(formData)
    }
    return (<div className="share-popup updateprofile_popup">
        <div className="modal fade" id="updateprofile-popup" tabIndex={0} role="dialog" aria-labelledby="updateprofile-popupLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { cancel() }}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="modal-body">
                        <div className="editprofile_conts">
                            <div className="editprofile_text">
                                <strong className="sharemovi-name">Change Profile Details</strong>
                            </div>
                            <form className="editprofile_form">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first_name"
                                        placeholder="Enter first name"
                                        name="first_name"
                                        value={formData?.first_name}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="last_name"
                                        placeholder="Enter last name"
                                        name="last_name"
                                        value={formData?.last_name}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Enter Your New Email"
                                        name="email"
                                        value={formData?.email}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="current-password"
                                        placeholder="Password"
                                        name="password"
                                        value={formData?.password}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="enternew-password"
                                        placeholder="Confirm Password"
                                        name="confirm_password"
                                        value={formData?.confirm_password}
                                        onChange={changeHandler}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label>Select Language</label>
                                    <select id="change-language" className="form-control">
                                        <option selected>Select Language</option>
                                        <option>Arabic</option>
                                        <option>Bulgarian</option>
                                        <option>English</option>
                                        <option>French</option>
                                        <option>German</option>
                                        <option>Hebrew</option>
                                        <option>Italian</option>
                                        <option>Japanese</option>
                                    </select>
                                </div> */}
                                <div className="btn_parent">
                                    <button type="button" className="btn add-btn" onClick={() => { cancel() }}>
                                        <span>Cancel</span>
                                    </button>
                                    <button type="button" className="btn add-btn" onClick={() => { submit() }}>
                                        <span>Save</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default EditProfilePopup;