import { SEARCH_LIST_REQUEST, SEARCH_LIST_SUCCESS, SEARCH_LIST_FAILURE, SEARCH_QUERY_SAVE_SUCCESS, SEARCH_QUERY_SAVE_REQUEST, FETCH_SEARCH_LIST_REQUEST, SEARCH_QUERY_SAVE_EMPTY, DELETE_LIST_ITEM_REQUEST, FETCH_LIST_ITEM_REQUEST, FETCH_SEARCH_LIST_EMPTY, SEARCH_LIST_EMPTY } from "../types";

export const getSearchListRequest = (data: any) => {
    return {
        type: SEARCH_LIST_REQUEST,
        data
    };
};

export const getSearchListFailure = (error: any) => {
    return {
        type: SEARCH_LIST_FAILURE,
        error
    };
};

export const getSearchListResponse = (response: any) => {
    return {
        type: SEARCH_LIST_SUCCESS,
        response
    };
};

export const saveSearchQueryRequest = (data: any) => {
    return {
        type: SEARCH_QUERY_SAVE_REQUEST,
        data
    };
};
export const resetSaveSearchRequestMessage = () => {
    return {
        type: SEARCH_QUERY_SAVE_EMPTY
    };
};

export const getSearchQueryRequest = (userId: any) => {
    return {
        type: FETCH_SEARCH_LIST_REQUEST,
        userId
    };
};

export const deleteSearchItemRequest = (userId: any, searchId: number) => {
    return {
        type: DELETE_LIST_ITEM_REQUEST,
        userId,
        id: searchId
    };
};

export const fetchSearchItemRequest = (userId: any, searchId: string, range: number) => {
    return {
        type: FETCH_LIST_ITEM_REQUEST,
        userId,
        id: searchId,
        range
    };
};

export const searchListEmpty = () => {
    return {
        type: SEARCH_LIST_EMPTY
    };
};