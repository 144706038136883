import {
  GET_INITIAL_API_REQUEST, GET_INITIAL_API_FAILURE, GET_INITIAL_API_SUCCESS, STRIPE_CONFIG_FAILURE,
  STRIPE_CONFIG_SUCCESS,
} from "../types";

interface IinitialState {
  dev_base_url: string,
  live_base_url: string,
  ip_address: string,
  c_code: string,
  c_name: string,
  c_state: string,
  c_city: string,
  zip_code: string,
  iso_code: string,
  postal_code: string,
  isd_code: string,
  latitude: string,
  longitude: string,
  area_code: string,
  time_zone: string,
  stripe_sk: string,
  stripe_pk: string
}
const initialState: IinitialState = {
  dev_base_url: "",
  live_base_url: "",
  ip_address: "",
  c_code: "",
  c_name: "",
  c_state: "",
  c_city: "",
  zip_code: "",
  iso_code: "",
  postal_code: "",
  isd_code: "",
  latitude: "",
  longitude: "",
  area_code: "",
  time_zone: "",
  stripe_sk: "",
  stripe_pk: "",
}

export default function initialApiReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_INITIAL_API_REQUEST:
      return {
        ...state
      }
    case GET_INITIAL_API_FAILURE:
      return {
        ...state,
        dev_base_url: "",
        live_base_url: ""
      }
    case GET_INITIAL_API_SUCCESS:
      return {
        ...state,
        ...action.response
      }
    case STRIPE_CONFIG_FAILURE:
      return {
        ...state,
        stripe_sk: "",
        stripe_pk: "",
      };
    case STRIPE_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.response,
      };
    default:
      return state
  }
}