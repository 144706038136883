import { IMovieDetailData } from '../../model/movieDetail';
import * as type from '../types';

const initialState = {
    movieDetailData: null,
    seriesDetailData: [],
    episodeDetailData: []
}

export default function movieDetailReducer(state = initialState, action: IMovieDetailData) {
    switch (action.type) {
        case type.GET_MOVIE_DETAIL_SUCCESS:
            return {
                ...state,
                movieDetailData: action.movieDetailData
            }
        case type.GET_MOVIE_DETAIL_FAILURE:
            return {
                ...state,
                movieDetailStatus: false
            }
        case type.GET_SERIES_DETAIL_SUCCESS:
            return {
                ...state,
                seriesDetailData: action.seriesDetailData
            }
        case type.GET_SERIES_DETAIL_FAILURE:
            return {
                ...state,
                seriesDetailStatus: false
            }
        case type.GET_EPISODE_DETAIL_SUCCESS:
            if (action.episodeEmptyData) {
                return {
                    ...state,
                    episodeDetailData: [],
                }
            }
            return {
                ...state,
                episodeDetailData: [...state.episodeDetailData, ...action.episodeDetailData],
                episodeExist: action.episodeDetailData
            }

        case type.GET_EPISODE_DETAIL_FAILURE:
            return {
                ...state,
                episodeDetailStatus: false
            }
        case type.GET_MOVIE_DETAIL_REQUEST:
        case type.GET_SERIES_DETAIL_REQUEST:
        default:
            return state
    }
}