import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMoodList, getToolCountRequest, getToolRequest, getToolResultRequest } from "../../redux/actions/toolAction";
import { setLeftSideMenuActive } from '../../redux/actions/headerAction';
import { IRootState } from "../../redux/reducer";
import { StepTwo } from "../../Component/Tool/stepTwo";
import { StepThree } from "../../Component/Tool/stepThree";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { IHeaderinitialState } from "../../redux/reducer/headerReducer";

declare global {
    interface Window {
        $: any;
        rSlider: any;
    }
}
const Tool = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState<number>(1);
    const [sliderData, setSliderData] = useState<any>();
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const { toolData } = useSelector<IRootState, any>((state) => state.toolReducer);
    const [moodList, setMoodList] = useState<string>('');
    const [moodListId, setMoodListId] = useState<string[]>([])
    const [selectedYear, setSelectedYear] = useState<string>('1991,2015');
    const [moodName, setMoodName] = useState<string[]>([]);
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const $ = window.$;

    useEffect(() => {
        dispatch(setLeftSideMenuActive(false));
    }, []);
    useEffect(() => {
        if (left_active) {
            dispatch(setLeftSideMenuActive(false))
        }
        if (dev_base_url) {
            dispatch(getToolRequest());
            const selectedYearArr = selectedYear?.split(',')
            const generateArrayOfYears = () => {
                var max = new Date().getFullYear()
                var min = 1900
                var years = []

                for (var i = max; i >= min; i--) {
                    years.unshift(i)
                }
                return years
            }

            const sliderObj = new window.rSlider({
                target: '#slider',
                values: generateArrayOfYears(),
                range: true,
                set: [selectedYearArr?.[0], selectedYearArr?.[1]],
                scale: false,
                disabled: false, // is disabled?
                onChange: function (vals: any) {
                    setSelectedYear(vals);

                }
            });
            setSliderData(sliderObj);
            $("html, body").animate({ scrollTop: 0 }, 600);
        }
    }, [dev_base_url])

    const firstStepSubmit = () => {
        const selectedYearArr = selectedYear?.split(',')
        dispatch(getToolCountRequest(selectedYearArr?.[0], selectedYearArr?.[1]));
        dispatch(getMoodList());
        setActiveStep(2);
    }

    const firstStepUpdate = () => {
        setActiveStep(1);
    }

    const secondStepUpdate = () => {
        setActiveStep(2);
    }

    const secondStepSubmit = () => {
        const selectedYearArr = selectedYear?.split(',')
        dispatch(getToolResultRequest(selectedYearArr?.[0], selectedYearArr?.[1], moodListId.join(','), moodName.join(',')));
        setMoodList(moodName.join(','));
        setActiveStep(3);
    }

    return (<>
        <Header />
        <div className="oracle_page" style={{ "display": `${activeStep === 1 ? 'block' : "none"}` }}>
            <section className="banner-slider">
                <div className="banner_main">
                    <div className="container-fluid">
                        <div className="banner_slide">
                            <div className="inline-panel" style={{ "marginTop": "50px" }}>
                                {/* <img src={toolData?.oracle_banner} alt="oracle-banner" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <div className="oracle_main">
                <div className="container-fluid">
                    <div className="oracle_bodytext">
                        <div className="oracle_textwriter"><div className="oracle_textwriter"><h4><i>{toolData?.subtitle}</i></h4></div></div>
                        <p>
                            {toolData?.description}
                        </p>
                        <h2>TOTAL NUMBER OF TITLES: {toolData?.total_number_of_titles}</h2>
                        <h3>GOAL: 1 </h3>
                        <span className="goalsave_later">( maybe two. You can always save one for later )</span>
                    </div>
                    <section className="choosestep_section">
                        <div className="choosesteptext_grid">
                            <h4>Step One: Choose a time range</h4>
                            <p>Drag the sliders below to the time period of movies you feel like watching</p>
                        </div>
                        <div className="choosestepform_area">
                            <div className="choosestepform_form">
                                <div className="choosetimerange_area">
                                    <form>
                                        <div className="slider-container">
                                            <input type="text" id="slider" className="slider choosetimerange_panel" />
                                        </div>
                                        <div className="choosestepsubmit_panel">
                                            <button type="button" className="btn common-btn" onClick={firstStepSubmit} >SUBMIT</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div > {activeStep === 2 ? <StepTwo firstStepUpdate={firstStepUpdate} secondStepSubmit={secondStepSubmit} setMoodListId={setMoodListId} moodListId={moodListId} moodName={moodName} setMoodName={setMoodName} /> : activeStep === 3 ? <StepThree moodList={moodList} firstStepUpdate={firstStepUpdate} secondStepUpdate={secondStepUpdate} /> : <></>}
        <Footer />
    </>)

}

export default Tool;