import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IRootState } from "../../redux/reducer";
import { PLAYER, CONTENT_DETAIL } from "../../routes/routesConstant";

export const StepThree = (props: any) => {
    const { moodData, totalcountResp, toolData, totalResultResp, formatedData } = useSelector<IRootState, any>((state) => state.toolReducer);
    return (
        <div className="oracle2_page pt-5">
            <div className="oracle_main">
                <div className="container-fluid">
                    <div className="oracle_bodytext">
                        <h2>{toolData?.title}</h2>
                        <h3>RESULTS:{totalResultResp?.total_number_of_titles}</h3>
                    </div>
                    <section className="editprestep_section">
                        <div className="container-fluid">
                            <div className="editprestep_grid">
                                <div className="editprestep_group">
                                    <div className="editprestep_panel media ">
                                        <p><strong>Step One: Timeline</strong> ({totalcountResp?.from_year} - {totalcountResp?.to_year})</p>
                                        <span className="media-body"><span className="common-btn" onClick={props.firstStepUpdate} style={{ "cursor": "pointer" }}>UPDATE</span></span>
                                    </div>
                                </div>
                                <div className="editprestep_group">
                                    <div className="editprestep_panel media ">
                                        <p><strong>Step Two: Mood </strong>(<small>{props.moodList}</small>)</p>
                                        <span className="media-body" ><span onClick={props.secondStepUpdate} style={{ "cursor": "pointer" }} className="common-btn">UPDATE</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="choosestep_section">
                        <div className="choosesteptext_grid">
                            <h4>Step Three: Choose Wisely!</h4>
                            <p>Click on any of the cards below and decide your fate...</p>
                        </div>
                        <div className="chooseyourfate_area">
                            <div className="chooseyourfate_grid">
                                {totalResultResp?.formatedData?.length ? totalResultResp?.formatedData.map((item: any) => <div className="chooseyourfate_box" key={item?.genre_id}>
                                    <div className="chooseyourfate_card">
                                        <div className="chooseyourfate_front">
                                            <div className="chooseyourfate_cover">
                                                <img src={item?.genre_oracle_image} alt="oraclestep1" />
                                                {/* <h3 className="chooseyourfatefront_heading">{item.genre_name}</h3> */}
                                            </div>
                                        </div>
                                        <div className="chooseyourfate_back">
                                            <img src={item?.portrait_art_2000x3000} alt="oraclestep1" />
                                            <div className="chooseyourfateback_content">
                                                <h3>{item?.video_title}</h3>
                                                <div className="chooseyourfatebtn_group">
                                                    <span><Link to={`${PLAYER}?video_id=${item?.video_id}&video_type=${item?.video_type}`}>Play</Link></span>
                                                    <span><Link to={`${PLAYER}?video_id=${item?.video_id}&video_type=${item?.video_type}&content_type=trailer`}>Trailer</Link></span>
                                                    <span><Link to={`${CONTENT_DETAIL}?video_id=${item?.video_id}&video_type=${item?.video_type}`}>Details</Link></span>
                                                    {/* <span><Link to="">Skip</Link></span> */}
                                                </div>
                                            </div>
                                            {/* <h3>{item.genre_name}</h3> */}
                                            {/* <p>{item.genre_name}</p>
                                            <a href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>) : <></>}
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </div >)
}
