import {
  call,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { get, post } from "../../api";
import {
  SEARCH_LIST_REQUEST,
  SEARCH_LIST_FAILURE,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_EMPTY,
  SEARCH_QUERY_SAVE_REQUEST,
  SEARCH_QUERY_SAVE_SUCCESS,
  SEARCH_QUERY_SAVE_FAILURE,
  FETCH_SEARCH_LIST_REQUEST,
  FETCH_SEARCH_LIST_SUCCESS,
  FETCH_VIDEO_LIST_FAILURE,
  FETCH_SEARCH_LIST_FAILURE,
  SEARCH_QUERY_SAVE_EMPTY,
  DELETE_LIST_ITEM_REQUEST,
  FETCH_LIST_ITEM_REQUEST,
  DELETE_LIST_ITEM_SUCCESS,
  FETCH_LIST_ITEM_SUCCESS,
  FETCH_LIST_ITEM_FAILURE,
  DELETE_LIST_ITEM_FAILURE,
  FETCH_SEARCH_LIST_EMPTY,
} from "../types";

async function getSearchDataApi(data) {
  const {
    range,
    user_id = "",
    view_type,
    era,
    genre,
    region,
    showText,
    query,
  } = data;
  try {
    let response = null;
    if (showText === "1") {
      response = await post(
        `/input-search?user_id=${user_id}&search_key=${query}&start_range=${range}`
      );
    } else {
      response = await post(
        `/advance-search?user_id=${user_id}&genre=${genre}&era=${era}&region=${region}&view_type=${view_type}&start_range=${range}`
      );
    }
    return response;
  } catch (error) {
    throw error;
  }
}
async function getSearchLisApi(userId = "") {
  try {
    let response = null;
    response = await post(`/user-save-search-list?user_id=${userId}`);

    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function deleteSearchItemApi(userId = "", id) {
  try {
    let response = null;
    response = await post(
      `/delete-save-search?user_id=${userId}&save_search_id=${id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function fetchSearchItemApi(userId = "", id, range) {
  try {
    let response = null;
    response = await post(
      `/user-save-search-videos?user_id=${userId}&save_search_id=${id}&start_range=${range}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function saveSearchQueryApi(data) {
  const { user_id = "", view_type, era, genre, region_id, name } = data;
  try {
    let response = null;
    response = await post(
      `/save-search-list?user_id=${user_id}&search_list_name=${name}&genre_id=${genre}&region_id=${region_id}&view_type=${view_type}&era_id=${era}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

function* fetchSearchData(action) {
  try {
    const searchData = yield call(getSearchDataApi, action.data);
    searchData.showText = action?.data?.showText || "";
    yield put({
      type: SEARCH_LIST_SUCCESS,
      searchData,
    });
  } catch (e) {
    yield put({ type: SEARCH_LIST_FAILURE, message: e.message });
  }
}

function* fetchSearchQueryData(action) {
  try {
    const searchData = yield call(getSearchLisApi, action.userId);
    yield put({
      type: FETCH_SEARCH_LIST_SUCCESS,
      searchData,
    });
  } catch (e) {
    yield put({ type: FETCH_SEARCH_LIST_FAILURE, message: e.message });
  }
}

function* saveSearchQuery(action) {
  try {
    const searchData = yield call(saveSearchQueryApi, action.data);
    if (searchData?.status_code) {
      yield put({
        type: SEARCH_QUERY_SAVE_SUCCESS,
        message: searchData?.message,
      });
    } else {
      yield put({
        type: SEARCH_QUERY_SAVE_FAILURE,
        message: "Error while saving to searches",
      });
    }
  } catch (e) {
    yield put({
      type: SEARCH_QUERY_SAVE_FAILURE,
      message: "Error while saving to searches",
    });
  }
}

function* deleteSearchItem(action) {
  try {
    const searchData = yield call(
      deleteSearchItemApi,
      action.userId,
      action.id
    );
    if (searchData?.status_code) {
      yield put({
        type: DELETE_LIST_ITEM_SUCCESS,
        message: searchData?.message,
      });
      yield call(fetchSearchQueryData, action);
    } else {
      yield put({
        type: DELETE_LIST_ITEM_FAILURE,
        message: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_LIST_ITEM_FAILURE,
      message: "Something went wrong",
    });
  }
}

function* fetchSearchItem(action) {
  try {
    const searchData = yield call(
      fetchSearchItemApi,
      action.userId,
      action.id,
      action.range
    );
    yield put({
      type: FETCH_LIST_ITEM_SUCCESS,
      searchData,
    });
  } catch (e) {
    yield put({ type: FETCH_LIST_ITEM_FAILURE, message: e.message });
  }
}

export function* searchSaga() {
  yield takeEvery(SEARCH_LIST_REQUEST, fetchSearchData);
  yield takeEvery(SEARCH_QUERY_SAVE_REQUEST, saveSearchQuery);
  yield takeEvery(FETCH_SEARCH_LIST_REQUEST, fetchSearchQueryData);
  yield takeEvery(SEARCH_QUERY_SAVE_EMPTY, () =>
    put({
      type: SEARCH_QUERY_SAVE_EMPTY,
    })
  );
  yield takeEvery(SEARCH_LIST_EMPTY, () =>
    put({
      type: SEARCH_LIST_EMPTY,
    })
  );
  yield takeEvery(DELETE_LIST_ITEM_REQUEST, deleteSearchItem);
  yield takeEvery(FETCH_LIST_ITEM_REQUEST, fetchSearchItem);
}

export default searchSaga;
