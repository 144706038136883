import { GET_MENU_REQUEST, GET_MENU_FAILURE, GET_MENU_SUCCESS } from "../types";

interface IinitialState {
  main_menu: any,
  genre: any,
  save_searches:any,
  era:any,
  region:any,
  view_result:any
}
const initialState: IinitialState = {
  main_menu: [],
  genre: [],
  save_searches:[],
  era:[],
  region:[],
  view_result:[]
}

export default function menuReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_MENU_REQUEST:
      return {
        ...state
      }
    case GET_MENU_FAILURE:
      return {
        ...state,
        error:action.error
      }
    case GET_MENU_SUCCESS:
      return {
        ...state,
        ...action.response
      }
        default:
      return state
  }
}