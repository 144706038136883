import {
  GET_BANNER_REQUEST,
  GET_BANNER_FAILURE,
  GET_BANNER_SUCCESS,
  GET_HOME_REQUEST,
  GET_HOME_SUCCESS,
  GET_HOME_FAILURE,
  GET_STILL_LOOKING_FAILURE,
  GET_STILL_LOOKING_REQUEST,
  GET_STILL_LOOKING_SUCCESS,
  GET_HOME_EMPTY,
  GET_CONTINUE_WATCHING_REQUEST,
  GET_USER_WATCHING_LIST_REQUEST,
  GET_CONTINUE_WATCHING_EMPTY,
  GET_USER_WATCHING_LIST_EMPTY,
  GET_TRENDING_REQUEST,
  MOST_VIEWED_REQUEST,
  MOST_SHARED_REQUEST,
  FILM_MATTERS_REQUEST,
  REMASTERED_VIDEO_REQUEST,
  COMING_SOON_SLIDER_REQUEST,
  COMING_SOON_LIST_REQUEST,
} from "../types";

export const getBanner = (userId) => {
  return {
    type: GET_BANNER_REQUEST,
    userId
  };
};

export const getBannerSuccess = (bannerData) => {
  return {
    type: GET_BANNER_SUCCESS,
    payload: bannerData,
  };
};

export const getBannerFail = (error) => {
  return {
    type: GET_BANNER_FAILURE,
    payload: error,
  };
};

export const getHomeData = (data, isReplace) => {
  return {
    type: GET_HOME_REQUEST,
    data,
    isReplace,
  };
};

export const getHomeSuccess = (homeData) => {
  return {
    type: GET_HOME_SUCCESS,
    payload: homeData,
  };
};

export const getHomeFail = (error) => {
  return {
    type: GET_HOME_FAILURE,
    payload: error,
  };
};

export const getStillLookingData = (range) => {
  return {
    type: GET_STILL_LOOKING_REQUEST,
    range,
  };
};

export const getContinueWatchingData = (user_id) => {
  return {
    type: GET_CONTINUE_WATCHING_REQUEST,
    user_id,
  };
};

export const getWatchListData = (user_id) => {
  return {
    type: GET_USER_WATCHING_LIST_REQUEST,
    user_id,
  };
};

export const getStillLookingSuccess = (stillLookingData) => {
  return {
    type: GET_STILL_LOOKING_SUCCESS,
    payload: stillLookingData,
  };
};

export const getStillLookingFail = (error) => {
  return {
    type: GET_STILL_LOOKING_FAILURE,
    payload: error,
  };
};

export const continueWatchingEmpty = () => {
  return {
    type: GET_CONTINUE_WATCHING_EMPTY,
  };
};

export const watchListEmpty = () => {
  return {
    type: GET_USER_WATCHING_LIST_EMPTY,
  };
};

export const trendingRequest = (userId,range) => {
  return {
    type: GET_TRENDING_REQUEST,
    userId,
    range
  };
};

export const mostViewedRequest = (userId,range) => {
  return {
    type: MOST_VIEWED_REQUEST,
    userId,
    range
  };
};

export const mostSharedRequest = (userId,range) => {
  return {
    type: MOST_SHARED_REQUEST,
    userId,
    range
  };
};

export const filmMattersRequest = (userId,range) => {
  return {
    type: FILM_MATTERS_REQUEST,
    userId,
    range
  };
};

export const remasteredVideoRequest = (userId,range) => {
  return {
    type: REMASTERED_VIDEO_REQUEST,
    userId,
    range
  };
};

export const comingSoonSliderRequest = (userId,range) => {
  return {
    type: COMING_SOON_SLIDER_REQUEST,
    userId
  };
};

export const comingSoonListRequest = (userId,range) => {
  return {
    type: COMING_SOON_LIST_REQUEST,
    userId
  };
};

export const makeHomeEmpty = () => {
  return {
    type: GET_HOME_EMPTY,
  };
};

