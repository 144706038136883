import logo from '../../assets/img/logo.png';
import Guest from './Guest';
import Premium from './Premium';
import SignIn from './SignIn';
import SignUp from './SignUp';
const Auth = () => {
    return (<>
        <div className="loginrgstr-page">
            <div className="login_main">
                <div className="container-fluid h-100">
                    <div className="logo text-center pb-3">
                        <a href="/"> <img src={logo} alt="" /></a>
                    </div>
                    <form>
                        <div className="loginrgstr_row">
                            <Premium />
                            <SignUp />
                            <SignIn />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default Auth;