import { AD_REQUEST } from "../types";


export const getAdData = (data: any) => {
    return {
        type: AD_REQUEST,
        data
    };
};

