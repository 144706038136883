import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IRootState } from '../../redux/reducer';
import { CONTENT_DETAIL, PLAN_PAGE, PLAYER } from '../../routes/routesConstant';
import { MESSAGE } from '../../utils/constant';
import premiumIcon from "../../assets/img/premium.png";
const Banner = (props: any) => {
    const { bannerData } = useSelector<IRootState, any>((state) => state.homeReducer);
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { subscriptionData } = useSelector<IRootState, any>((state) => state.profileReducer);
    const navigate = useNavigate();

    return (
        <section className="banner-slider heroimage_section">
            <div className="banner_main">
                <div className="container-fluid">
                    <div className="banner_slide heroimage_slider">
                        <div className="inline-panel">
                            <img src={bannerData[0]?.['slider_image_ott_web']} alt="slide-1" />
                        </div>
                        <div className="homebanner-text">
                            <div className="container-fluid">
                                <div className="homebanner_content">
                                    <div className="bannercrntheading_top">
                                        <img src={bannerData[0]?.['title_image']} alt="homeimg-sign" />
                                    </div>
                                    <div className="homebanner_btngroup">
                                        {bannerData[0]?.video_type == 1 ? <span className="common-btn play_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Play" data-bs-original-title="Play" aria-label="Play" onClick={() => {
                                            if (!loginData?.token) {
                                                props.setLoginAlertBool(MESSAGE.CREATE_ACCOUNT_TO_PLAY_CONTENT)
                                                return;
                                            }
                                            if (subscriptionData?.subscription_status != "Active" && subscriptionData?.subscription_status != "Cancelled" && bannerData[0]?.is_premium == '1') {
                                                navigate(`${PLAN_PAGE}`);
                                                return;
                                            }
                                            navigate(`${PLAYER}?video_id=${bannerData[0]?.video_id}&video_type=${bannerData[0]?.video_type}`);
                                        }}><i className="fa fa-play"></i></span>
                                            : null}
                                        {/* <span className="common-btn play_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Play" data-bs-original-title="Play" aria-label="Play" onClick={() => {
                                            if (!loginData?.token) {
                                                props.setLoginAlertBool(MESSAGE.LOGIN_TO_ADD_TO_WISHLIST)
                                                return;
                                            }
                                            navigate(`${PLAYER}?video_id=${bannerData[0]?.video_id}&video_type=${bannerData[0]?.video_type}`);
                                        }}><i className="fa fa-play"></i></span> */}

                                        {bannerData[0]?.is_my_list ?
                                            (<span onClick={() => props.toggleRemoveWishlist(true, bannerData[0]?.video_id, bannerData[0]?.watch_list_id)} className="common-btn addwishlist_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to watchlist" data-bs-original-title="Add to watchlist" aria-label="Add to watchlist"><i className="fa fa-check" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Remove from watchlist"
                                            ></i></span>)
                                            :
                                            (<span onClick={() => {
                                                if (!loginData?.token) {
                                                    props.setLoginAlertBool(MESSAGE.LOGIN_TO_ADD_TO_WISHLIST)
                                                    return;
                                                } props.toggleWishlist(true, bannerData[0]?.['slider_image_android'], bannerData[0]?.video_id)
                                            }} className="common-btn addwishlist_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to watchlist" data-bs-original-title="Add to watchlist" aria-label="Add to watchlist"><i className="fa fa-plus" data-toggle="modal" data-target="#wishlist-popup" data-whatever="@wishlist-popup" data-placement="bottom" title="Add to watchlist"
                                            ></i>
                                            </span>)}
                                        <Link to={`${CONTENT_DETAIL}?video_id=${bannerData[0]?.video_id}&video_type=${bannerData[0]?.video_type}`} className="common-btn watchtrailer_btn">
                                            DETAILS
                                        </Link>
                                        {bannerData[0]?.is_premium ? <div className="posted_timetype" style={{ height: '40px', width: 'auto' }}>
                                            <img src={bannerData[0]?.premium_content_icon} alt='premiumIcon' />
                                        </div> : null}
                                    </div>
                                    <div className="homebanner_para">
                                        <p>{bannerData[0]?.['slider_description']} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default React.memo(Banner);